import * as React from "react"
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group"
import { cn } from "../../lib/utils"

const ToggleGroup = React.forwardRef(({ className, value, onValueChange, ...props }, ref) => {
  // Add handler to prevent deselection
  const handleValueChange = (newValue) => {
    if (newValue) { // Only update if there's a new value
      onValueChange(newValue)
    }
    // If newValue is null (trying to deselect), do nothing
  }

  return (
    <ToggleGroupPrimitive.Root
      ref={ref}
      value={value}
      onValueChange={handleValueChange}
      className={cn(
        "inline-flex h-10 items-center justify-center rounded-md bg-gray-200 p-1 text-gray-700 dark:bg-muted dark:text-muted-foreground",
        className
      )}
      {...props}
    />
  )
})
ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName

const ToggleGroupItem = React.forwardRef(({ className, children, ...props }, ref) => (
  <ToggleGroupPrimitive.Item
    ref={ref}
    className={cn(
      "inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=on]:bg-white data-[state=on]:text-gray-900 data-[state=on]:shadow-sm dark:data-[state=on]:bg-background dark:data-[state=on]:text-foreground",
      className
    )}
    {...props}
  >
    {children}
  </ToggleGroupPrimitive.Item>
))
ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName

export { ToggleGroup, ToggleGroupItem }
