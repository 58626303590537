import React, { createContext, useState, useContext, useCallback, useMemo, useRef, useEffect } from 'react';
import { usePrivy } from '@privy-io/react-auth';
import isEqual from 'lodash/isEqual';

const ProfileContext = createContext();

// Sophisticated cache configuration
const CACHE_CONFIG = {
  DEFAULT_TTL: 60000,        // Default Time To Live: 1 minute
  STALE_WHILE_REVALIDATE: 300000, // Allow stale data for 5 minutes while revalidating
  MAX_AGE: 3600000,         // Maximum cache age: 1 hour
  ERROR_RETRY_COUNT: 3,     // Number of retries on error
  ERROR_RETRY_DELAY: 1000,  // Delay between retries
};

class CacheManager {
  constructor() {
    this.cache = new Map();
    this.pendingRequests = new Map();
  }

  get(key) {
    const entry = this.cache.get(key);
    if (!entry) return null;

    const now = Date.now();
    const age = now - entry.timestamp;

    if (age < CACHE_CONFIG.DEFAULT_TTL) {
      return { data: entry.data, status: 'fresh' };
    }

    if (age < CACHE_CONFIG.STALE_WHILE_REVALIDATE) {
      return { data: entry.data, status: 'stale' };
    }

    this.cache.delete(key);
    return null;
  }

  set(key, data) {
    this.cache.set(key, {
      data,
      timestamp: Date.now()
    });
  }

  async dedupRequest(key, requestFn) {
    const pending = this.pendingRequests.get(key);
    if (pending) {
      return pending;
    }

    try {
      const request = requestFn();
      this.pendingRequests.set(key, request);
      const result = await request;
      return result;
    } finally {
      this.pendingRequests.delete(key);
    }
  }

  clear() {
    this.cache.clear();
    this.pendingRequests.clear();
  }
}

export const ProfileProvider = ({ children }) => {
  const { user } = usePrivy();
  const [profileData, setProfileData] = useState({});
  const [profileAnalyses, setProfileAnalyses] = useState({});
  const [analysisLoadingStates, setAnalysisLoadingStates] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogs, setOpenDialogs] = useState(new Set());
  
  const cacheManager = useRef(new CacheManager());

  const fetchProfileData = useCallback(async (userId, force = false) => {
    if (!userId) return;

    const cacheKey = `profiles-${userId}`;
    
    if (cacheManager.current.pendingRequests.has(cacheKey)) {
      return cacheManager.current.pendingRequests.get(cacheKey);
    }
    
    if (!force) {
      const cached = cacheManager.current.get(cacheKey);
      if (cached?.status === 'fresh') {
        return cached.data;
      }
    }

    const request = (async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/profile-cards?userId=${userId}`
        );
        
        if (!response.ok) throw new Error('Failed to fetch profiles');
        const data = await response.json();

        cacheManager.current.set(cacheKey, data);
        setProfileData(data);
        return data;
      } catch (error) {
        console.error('Error fetching profiles:', error);
        throw error;
      } finally {
        setIsLoading(false);
        cacheManager.current.pendingRequests.delete(cacheKey);
      }
    })();

    cacheManager.current.pendingRequests.set(cacheKey, request);
    return request;
  }, []);

  // Clear cache on unmount
  useEffect(() => {
    return () => {
      cacheManager.current.clear();
    };
  }, []);

  const generateAnalysis = useCallback(async (profileId) => {
    if (!user?.id || analysisLoadingStates[profileId]?.isGenerating) return;

    setAnalysisLoadingStates(prev => ({
      ...prev,
      [profileId]: { isGenerating: true }
    }));
    
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/quick-start-persona/${profileId}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: user.id })
        }
      );

      if (!response.ok) throw new Error('Failed to generate persona');
      const { data } = await response.json();

      setProfileData(prev => ({
        ...prev,
        [profileId]: {
          ...prev[profileId],
          ...data.profile,
          analysis: data.analysis,
          updatedAt: new Date().toISOString()
        }
      }));

      setProfileAnalyses(prev => ({
        ...prev,
        [profileId]: data.analysis
      }));

      return data;
    } catch (error) {
      console.error('Error generating analysis:', error);
      throw error;
    } finally {
      setAnalysisLoadingStates(prev => ({
        ...prev,
        [profileId]: { isGenerating: false }
      }));
    }
  }, [user?.id]);

  const regenerateAnalysis = useCallback(async (profileId) => {
    if (!user?.id || analysisLoadingStates[profileId]?.isRegenerating) return;

    setAnalysisLoadingStates(prev => ({
      ...prev,
      [profileId]: { isRegenerating: true }
    }));
    
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/regenerate-persona/${profileId}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: user.id })
        }
      );

      if (!response.ok) throw new Error('Failed to regenerate persona');
      const { data } = await response.json();

      setProfileData(prev => ({
        ...prev,
        [profileId]: {
          ...prev[profileId],
          ...data.profile,
          analysis: data.analysis,
          updatedAt: new Date().toISOString()
        }
      }));

      setProfileAnalyses(prev => ({
        ...prev,
        [profileId]: data.analysis
      }));

      return data;
    } catch (error) {
      console.error('Error regenerating analysis:', error);
      throw error;
    } finally {
      setAnalysisLoadingStates(prev => ({
        ...prev,
        [profileId]: { isRegenerating: false }
      }));
    }
  }, [user?.id]);

  const openDialog = useCallback((profileId) => {
    setOpenDialogs(prev => new Set(prev).add(profileId));
  }, []);

  const closeDialog = useCallback((profileId) => {
    setOpenDialogs(prev => {
      const next = new Set(prev);
      next.delete(profileId);
      return next;
    });
  }, []);

  const isDialogOpen = useCallback((profileId) => {
    return openDialogs.has(profileId);
  }, [openDialogs]);

  const addInsightToProfile = useCallback((profileId, insight) => {
    if (!profileId || !insight) return;

    setProfileData(prev => {
      const profile = prev[profileId];
      if (!profile) return prev;

      return {
        ...prev,
        [profileId]: {
          ...profile,
          insights: [insight, ...(profile.insights || [])],
          updatedAt: new Date().toISOString()
        }
      };
    });

    setProfileAnalyses(prev => {
      const analysis = prev[profileId];
      if (!analysis) return prev;

      // Update the analysis with the new insight
      return {
        ...prev,
        [profileId]: {
          ...analysis,
          insights: [insight, ...(analysis.insights || [])]
        }
      };
    });
  }, []);

  const addTweetsToProfile = useCallback((profileId, newTweets) => {
    if (!newTweets || newTweets.length === 0 || !profileId) return;

    setProfileData(prev => {
      const profile = prev[profileId];
      if (!profile) return prev;

      // Create a map of existing tweets to avoid duplicates
      const existingTweetsMap = new Map(
        profile.tweets?.map(tweet => [tweet.tweetId || tweet.id, tweet]) || []
      );

      // Filter out duplicates and standardize tweet format
      const uniqueNewTweets = newTweets
        .filter(tweet => !existingTweetsMap.has(tweet.tweetId || tweet.id))
        .map(tweet => ({
          ...tweet,
          tweetId: tweet.tweetId || tweet.id,
          content: tweet.content || tweet.text,
          createdAt: tweet.createdAt || tweet.created_at
        }));

      if (uniqueNewTweets.length === 0) return prev;

      return {
        ...prev,
        [profileId]: {
          ...profile,
          tweets: [...uniqueNewTweets, ...(profile.tweets || [])].slice(0, 300), // Keep last 300 tweets
          updatedAt: new Date().toISOString()
        }
      };
    });
  }, []);

  const removeProfile = useCallback((profileId) => {
    setProfileData(prev => {
      const next = { ...prev };
      delete next[profileId];
      return next;
    });

    setProfileAnalyses(prev => {
      const next = { ...prev };
      delete next[profileId];
      return next;
    });

    setAnalysisLoadingStates(prev => {
      const next = { ...prev };
      delete next[profileId];
      return next;
    });

    // Close any open dialogs for this profile
    setOpenDialogs(prev => {
      const next = new Set(prev);
      next.delete(profileId);
      return next;
    });
  }, []);

  const resetProfileData = useCallback(() => {
    setProfileData({});
  }, []);

  const value = useMemo(() => ({
    profileData,
    profileAnalyses,
    analysisLoadingStates,
    isLoading,
    generateAnalysis,
    regenerateAnalysis,
    fetchProfileData,
    openDialog,
    closeDialog,
    isDialogOpen,
    addInsightToProfile,
    addTweetsToProfile,
    removeProfile,
    resetProfileData,
    userId: user?.id
  }), [
    profileData,
    profileAnalyses,
    analysisLoadingStates,
    isLoading,
    generateAnalysis,
    regenerateAnalysis,
    fetchProfileData,
    openDialog,
    closeDialog,
    isDialogOpen,
    addInsightToProfile,
    addTweetsToProfile,
    removeProfile,
    resetProfileData,
    user?.id
  ]);

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);
