import { memo, useMemo } from 'react'
import { ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, Radar, Tooltip } from 'recharts'
import { CustomTooltip } from './shared/CustomTooltip'

function AlignmentTooltip({ active, payload }) {
  if (!active || !payload || !payload.length) return null

  const data = payload[0].payload
  return (
    <CustomTooltip>
      <p className="font-medium">{data.fullSubject}</p>
      <p className="text-sm text-muted-foreground">Score: {data.value}%</p>
      {data.explanation && (
        <p className="text-sm text-muted-foreground mt-2">{data.explanation}</p>
      )}
    </CustomTooltip>
  )
}

function AlignmentChart({ 
  metrics,
  className = '',
  height = 300
}) {
  const isMobile = window.innerWidth < 640

  const data = useMemo(() => {
    if (!metrics) return []

    return Object.entries(metrics).map(([key, value]) => {
      const score = typeof value === 'object' ? value.score : value
      const explanation = typeof value === 'object' ? value.explanation : null

      // Clean up and shorten the key for mobile
      const [first, second] = key
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
        .split('vs')
        .map(s => s.trim())
      
      // Shorter labels on mobile
      const subject = isMobile 
        ? `${first.slice(0, 3)} vs ${second.slice(0, 3)}` 
        : `${first} vs ${second}`

      return {
        subject,
        fullSubject: `${first} vs ${second}`, // Keep full text for tooltip
        value: score,
        explanation
      }
    })
  }, [metrics, isMobile])

  // If no valid data, show empty state
  if (!data.length) {
    return (
      <div className={`w-full flex items-center justify-center ${className}`} style={{ height }}>
        <p className="text-muted-foreground">No alignment data available</p>
      </div>
    )
  }

  return (
    <div className={`w-full ${className}`} style={{ height }}>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart 
          data={data} 
          margin={{ 
            top: 20, 
            right: isMobile ? 30 : 20, 
            bottom: 20, 
            left: isMobile ? 30 : 20 
          }}
        >
          <PolarGrid gridType="circle" strokeDasharray="3 3" />
          <PolarAngleAxis 
            dataKey="subject"
            tick={{ 
              fill: 'currentColor', 
              fontSize: isMobile ? 10 : 12,
              fontWeight: 500
            }}
            tickLine={false}
          />
          <Tooltip content={<AlignmentTooltip />} />
          <Radar
            name="Alignment"
            dataKey="value"
            stroke="hsl(var(--primary))"
            fill="hsl(var(--primary))"
            fillOpacity={0.2}
            animationBegin={200}
            animationDuration={1000}
            strokeWidth={2}
          />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default memo(AlignmentChart)
