import { memo, useMemo } from 'react'
import { ResponsiveContainer, Treemap, Tooltip } from 'recharts'
import { TopicTooltip } from './shared/CustomTooltip'
import { cn } from '../../lib/utils'

function TopicsChart({ topics = [], className = '', height = 300 }) {
  if (!Array.isArray(topics) || topics.length === 0) {
    return <div>No topics available</div>;
  }

  // Add debug logging
  console.log('Topics data:', topics);

  // Calculate max weight for relative sizing
  const maxWeight = Math.max(...topics.map(t => t.weight));

  return (
    <div className="space-y-8 mb-12">
      <div className="grid grid-cols-2 gap-4">
        {topics.map((topic, index) => {
          // Calculate relative size and color based on sentiment
          const size = (topic.weight / maxWeight) * 100;
          const hue = ((topic.sentiment + 1) * 60);
          const saturation = 70;
          const lightness = 50;

          return (
            <div 
              key={index}
              className={cn(
                "relative overflow-hidden rounded-lg transition-all duration-200",
                "hover:shadow-lg hover:scale-[1.02]"
              )}
              style={{
                minHeight: '150px', // Increased height further to ensure space for description
                background: `linear-gradient(135deg, 
                  hsla(${hue}, ${saturation}%, ${lightness}%, 0.1),
                  hsla(${hue}, ${saturation}%, ${lightness}%, 0.2)
                )`,
                borderLeft: `4px solid hsla(${hue}, ${saturation}%, ${lightness}%, 0.5)`
              }}
            >
              <div className="absolute inset-0 p-3 flex flex-col justify-between">
                <div className="space-y-2"> {/* Added space-y-2 for better spacing */}
                  <h4 className="font-medium text-sm">{topic.name}</h4>
                  <div className="flex items-center gap-2">
                    <div className="text-sm text-muted-foreground">
                      Weight: {topic.weight}%
                    </div>
                    <div 
                      className="h-1.5 w-1.5 rounded-full"
                      style={{
                        backgroundColor: `hsl(${hue}, ${saturation}%, ${lightness}%)`
                      }}
                    />
                  </div>
                  {/* Make description more prominent */}
                  {topic.description && (
                    <p className="text-sm text-muted-foreground mt-2 line-clamp-3">
                      {topic.description}
                    </p>
                  )}
                </div>
                {topic.evolution && (
                  <div className="text-sm text-muted-foreground/80 mt-2">
                    {topic.evolution}
                  </div>
                )}
              </div>
              <div 
                className="absolute bottom-0 left-0 h-1 bg-gradient-to-r from-primary/20 to-primary/10"
                style={{ 
                  width: `${size}%`,
                  transition: 'width 1s cubic-bezier(0.4, 0, 0.2, 1)'
                }}
              />
            </div>
          );
        })}
      </div>

      {/* Legend */}
      <div className="flex items-center justify-center gap-6 text-xs text-muted-foreground">
        <div className="flex items-center gap-1.5">
          <div className="h-2 w-2 rounded-full bg-green-500/50" />
          Positive Vibe
        </div>
        <div className="flex items-center gap-1.5">
          <div className="h-2 w-2 rounded-full bg-yellow-500/50" />
          Neutral
        </div>
        <div className="flex items-center gap-1.5">
          <div className="h-2 w-2 rounded-full bg-red-500/50" />
          Negative Vibe
        </div>
      </div>
    </div>
  );
}

export default memo(TopicsChart)
