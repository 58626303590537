import React from 'react'
import { HelpCircle } from 'lucide-react'
import { Button } from './button'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogTrigger } from './dialog'

function TrackersInfo() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button 
          variant="ghost"
          size="icon"
          className="h-5 w-5 p-0 hover:bg-transparent"
        >
          <HelpCircle className="h-5 w-5 text-muted-foreground/60" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>About Trackers</DialogTitle>
          <DialogDescription>
            Trackers help you monitor Twitter users for specific content and generate AI insights.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4">
          <div className="space-y-4">
            <h4 className="font-medium">How Trackers Work:</h4>
            <ul className="list-disc pl-4 space-y-2 text-sm text-muted-foreground">
              <li>Set up trackers to monitor specific topics or patterns</li>
              <li>Get notified when matching content is found (Truffles)</li>
              <li>Generate AI insights based on the collected data</li>
              <li>Track multiple profiles with a single tracker</li>
            </ul>
          </div>
          <div className="space-y-4">
            <h4 className="font-medium">Features:</h4>
            <ul className="list-disc pl-4 space-y-2 text-sm text-muted-foreground">
              <li>Automatic checking based on your chosen frequency</li>
              <li>Smart AI analysis of found content</li>
              <li>Customizable tracking criteria</li>
              <li>Historical data tracking</li>
            </ul>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default TrackersInfo 