import React from 'react'
import { Button } from "./ui/button"
import { CreditCard, Coins } from 'lucide-react'
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "./ui/tooltip"
import { cn } from "../lib/utils"

const formatNumber = (number) => {
  return new Intl.NumberFormat().format(number)
}

function CreditWidget({ 
  credits, 
  monthlySpend = 0,
  onBuyCredits, 
  isPendingTransaction,
  isLoading = false,
  isDarkMode 
}) {
  const showLoadingState = isLoading || typeof credits === 'undefined' || credits === null;
  const displayCredits = showLoadingState ? 0 : credits;

  // Add error handling for invalid credit values
  if (typeof credits !== 'undefined' && credits !== null && !Number.isFinite(credits)) {
    console.error('Invalid credits value:', credits);
  }

  return (
    <TooltipProvider delayDuration={0} skipDelayDuration={0} disableHoverableContent={false} touch>
      <div className={cn(
        "fixed z-50",
        "bottom-6 left-4 w-fit max-w-[calc(100%-5.5rem)]",
        "lg:bottom-4 lg:left-1/2 lg:-translate-x-1/2 lg:w-auto"
      )}>
        <div className={cn(
          "rounded-full h-14",
          "px-5 flex items-center",
          "bg-black/90 text-white",
          "dark:bg-white/90 dark:text-black",
          "backdrop-blur supports-[backdrop-filter]:bg-black/75",
          "dark:supports-[backdrop-filter]:bg-white/75",
          "shadow-lg"
        )}>
          <div className="flex items-center gap-6">
            {/* Credits */}
            <Tooltip>
              <TooltipTrigger asChild>
                <button className="flex items-center gap-2 focus:outline-none">
                  <span className={cn(
                    "text-lg sm:text-l font-medium",
                    showLoadingState && "animate-pulse opacity-50"
                  )}>
                    {formatNumber(displayCredits)}
                  </span>
                  <Coins className="h-4 w-4 sm:h-5 sm:w-5 opacity-50" />
                </button>
              </TooltipTrigger>
              <TooltipContent 
                className="bg-white/95 text-black border-0 shadow-lg dark:bg-black/95 dark:text-white"
              >
                Your Credits
              </TooltipContent>
            </Tooltip>

            {/* Monthly Spend */}
            <Tooltip>
              <TooltipTrigger asChild>
                <button className="flex items-center gap-2 focus:outline-none">
                  <span className="text-lg sm:text-l font-medium opacity-50">
                    -{formatNumber(monthlySpend)}
                  </span>
                </button>
              </TooltipTrigger>
              <TooltipContent 
                className="bg-white/95 text-black border-0 shadow-lg dark:bg-black/95 dark:text-white"
              >
                Credits you spend per month
              </TooltipContent>
            </Tooltip>

            {/* Get Credits Button */}
            <Button
              onClick={onBuyCredits}
              disabled={showLoadingState}
              variant="ghost"
              size="sm"
              className={cn(
                "h-9 px-3 min-w-[44px]",
                "rounded-full font-medium text-sm",
                "bg-white/20 text-white",
                "hover:bg-white/30 hover:text-white",
                "dark:bg-black/10 dark:text-black",
                "dark:hover:bg-black/20 dark:hover:text-black",
                "transition-colors duration-200",
                "disabled:opacity-50"
              )}
            >
              <span className="flex items-center gap-2 whitespace-nowrap">
                <CreditCard className="h-4 w-4" />
                <span className="hidden sm:inline">Get</span><span>Credits</span>
              </span>
            </Button>
          </div>
        </div>
      </div>
    </TooltipProvider>
  )
}

export default CreditWidget