import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table";
import { useToast } from "./ui/use-toast";
import { Loader2, ChevronLeft, ChevronRight } from 'lucide-react';
import { ethers } from 'ethers';

const ITEMS_PER_PAGE = 5;

async function ensureUPConnection() {
  if (!window.lukso) {
    throw new Error('LUKSO extension not found. Please install the UP Browser Extension.');
  }

  try {
    const accounts = await window.lukso.request({ 
      method: 'eth_requestAccounts' 
    });
    
    if (!accounts || accounts.length === 0) {
      throw new Error('No UP account connected. Please connect your Universal Profile.');
    }
    
    return accounts[0];
  } catch (error) {
    console.error('UP connection error:', error);
    if (error.code === 4001) {
      throw new Error('Please connect your Universal Profile to continue.');
    }
    throw new Error('Failed to connect UP account. Please try again.');
  }
}

export default function LYXManagement({ user }) {
  const [lyxInfo, setLyxInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { toast } = useToast();

  const fetchLYXInfo = async () => {
    try {
      const timestamp = Date.now();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/admin/lyx-info?userId=${encodeURIComponent(user.id)}&_=${timestamp}`
      );
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch LYX info');
      }
      
      const data = await response.json();
      console.log('Updated LYX info:', data);
      setLyxInfo(data);
    } catch (error) {
      console.error('Error fetching LYX info:', error);
      toast({
        title: "Error",
        description: "Failed to fetch LYX information",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLYXInfo();
    const interval = setInterval(fetchLYXInfo, 30000);
    return () => clearInterval(interval);
  }, [user.id]);

  const handleWithdraw = async () => {
    if (!window.lukso) {
      toast({
        title: "Universal Profile Required",
        description: "Please install the Universal Profile browser extension.",
        variant: "destructive"
      });
      return;
    }

    try {
      // First ensure UP is connected
      const account = await ensureUPConnection();
      
      const provider = new ethers.providers.Web3Provider(window.lukso);
      const signer = provider.getSigner();

      // Verify the connection
      const address = await signer.getAddress();
      if (!address) {
        throw new Error('No UP account connected');
      }

      // Hardcode the contract address to be absolutely sure
      const contractAddress = '0xe76e3E53FfEC2337d9317BCdA6245A75e3d92826';

      console.log('Withdraw setup:', {
        upAddress: address, // Your UP that will receive the LYX
        contractAddress,    // Payment contract that holds the LYX
        action: 'Withdrawing LYX from contract to UP'
      });

      // Create LSP0 interface for your UP
      const lsp0Interface = new ethers.utils.Interface([
        "function execute(uint256 operationType, address target, uint256 value, bytes memory data) external payable returns (bytes memory)"
      ]);

      // Create withdraw function selector
      const withdrawSelector = '0x3ccfd60b'; // withdraw()

      // Encode the execute call
      const executeData = lsp0Interface.encodeFunctionData("execute", [
        0, // OPERATION_CALL
        contractAddress, // The payment contract
        0, // No value needed
        withdrawSelector // Just the withdraw function selector
      ]);

      console.log('Execute data:', {
        to: address, // Your UP
        targetContract: contractAddress, // Payment contract
        action: 'withdraw()'
      });

      // Send transaction through UP
      const tx = await signer.sendTransaction({
        to: address, // Your UP
        data: executeData,
        gasLimit: 1000000
      });

      console.log('Withdraw transaction sent:', tx.hash);

      toast({
        title: "Transaction Sent",
        description: "Withdrawal transaction is processing...",
      });

      const receipt = await tx.wait();
      console.log('Withdraw receipt:', receipt);

      if (!receipt.status) {
        throw new Error('Transaction failed');
      }

      toast({
        title: "Success",
        description: "LYX withdrawn successfully to your Universal Profile",
      });

      // Refresh LYX info
      await fetchLYXInfo();

    } catch (error) {
      console.error('Error withdrawing LYX:', {
        message: error.message,
        code: error.code,
        data: error.data,
        reason: error.reason,
        method: error.method,
        transaction: error.transaction,
        receipt: error.receipt,
        stack: error.stack
      });
      
      toast({
        title: "Error",
        description: error.message || "Failed to withdraw LYX",
        variant: "destructive"
      });
    }
  };

  // Move InfoCard inside the component to access lyxInfo state
  const InfoCard = ({ title, value, subtitle, action }) => (
    <div className="rounded-lg border p-4">
      <h3 className="text-sm font-medium text-muted-foreground">{title}</h3>
      <p className="mt-2 text-2xl font-bold">{value}</p>
      {subtitle && (
        <p className="text-sm text-muted-foreground">{subtitle}</p>
      )}
      {action}
      {title === "Contract Balance" && lyxInfo?.contractAddress && (
        <div className="mt-2 text-xs text-muted-foreground break-all">
          <p>Contract: {lyxInfo.contractAddress}</p>
          <p>Owner: {lyxInfo.owner}</p>
        </div>
      )}
    </div>
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  // Calculate pagination
  const totalPages = Math.ceil((lyxInfo?.recentPayments?.length || 0) / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentPayments = lyxInfo?.recentPayments?.slice(startIndex, endIndex) || [];

  return (
    <Card>
      <CardHeader className="p-2">
        <CardTitle className="text-sm">LYX Management</CardTitle>
      </CardHeader>
      <CardContent className="p-2">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-2">
          <InfoCard
            title="Contract Balance"
            value={`${Number(lyxInfo?.balance).toFixed(2)} LYX`}
            subtitle={`$${Number(lyxInfo?.balanceUSD).toFixed(2)} USD`}
            action={
              <Button 
                onClick={handleWithdraw} 
                size="sm"
                variant="outline"
                className="mt-2"
              >
                Withdraw
              </Button>
            }
          />
          <InfoCard
            title="Current LYX Price"
            value={`$${Number(lyxInfo?.lyxPrice).toFixed(2)} USD`}
          />
          <InfoCard
            title="Total Payments"
            value={lyxInfo?.recentPayments.length || 0}
            subtitle="Last 10000 blocks"
          />
        </div>

        <div className="rounded-md border text-sm">
          <div className="h-[300px] overflow-auto">
            <Table>
              <TableHeader className="sticky top-0 bg-background z-10">
                <TableRow>
                  <TableHead>Time</TableHead>
                  <TableHead>Payer</TableHead>
                  <TableHead>Amount (LYX)</TableHead>
                  <TableHead>Amount (USD)</TableHead>
                  <TableHead>Transaction</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {currentPayments.map((payment, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {new Date(payment.timestamp).toLocaleString()}
                    </TableCell>
                    <TableCell className="font-mono">
                      {payment.payer.slice(0, 6)}...{payment.payer.slice(-4)}
                    </TableCell>
                    <TableCell>{Number(payment.lyxAmount).toFixed(4)}</TableCell>
                    <TableCell>${Number(payment.usdAmount).toFixed(2)}</TableCell>
                    <TableCell>
                      <a
                        href={`https://explorer.lukso.network/tx/${payment.txHash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        View
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
                {currentPayments.length < ITEMS_PER_PAGE && Array(ITEMS_PER_PAGE - currentPayments.length).fill(0).map((_, i) => (
                  <TableRow key={`empty-${i}`}>
                    <TableCell colSpan={5}>&nbsp;</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          <div className="flex items-center justify-between px-4 py-2 border-t">
            <div className="text-sm text-muted-foreground">
              Page {currentPage} of {totalPages}
            </div>
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                disabled={currentPage === 1}
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                disabled={currentPage === totalPages}
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
} 