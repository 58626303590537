import { Coins } from "lucide-react"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./tooltip"
import { Portal } from "@radix-ui/react-portal"
import PropTypes from "prop-types"
import { cn } from "../../lib/utils"

function CreditCostBadge({ cost, variant = "default" }) {
  const styles = {
    default: "bg-muted/50 dark:bg-muted/20 border-border/50 dark:border-border/30 text-muted-foreground dark:text-muted-foreground/70",
    primary: "bg-white/25 border-white/40 text-white shadow-sm",
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className={cn(
            "inline-flex items-center ml-2 px-1.5 py-0.5 rounded-full text-xs border font-medium",
            styles[variant]
          )}>
            <Coins className="h-3 w-3 mr-0.5" />
            {cost}
          </div>
        </TooltipTrigger>
        <Portal>
          <TooltipContent 
            className="bg-popover/95 backdrop-blur supports-[backdrop-filter]:bg-popover/85 
              border border-border text-popover-foreground z-[9999] shadow-md"
            sideOffset={5}
            side="top"
            align="center"
          >
            <p className="text-xs">Actions on Truffle cost credits</p>
          </TooltipContent>
        </Portal>
      </Tooltip>
    </TooltipProvider>
  )
}

CreditCostBadge.propTypes = {
  cost: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['default', 'primary'])
}

export default CreditCostBadge