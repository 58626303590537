import { format, parseISO } from 'date-fns'

// New shared component for consistent tooltips
export function CustomTooltip({ children }) {
  return (
    <div className="bg-background border rounded-lg shadow-lg p-3 backdrop-blur-sm min-w-[200px] max-w-[300px]">
      {children}
    </div>
  )
}

// Add specific tooltip implementations
export function TopicTooltip({ active, payload }) {
  if (!active || !payload || !payload.length) return null

  const data = payload[0].payload
  return (
    <CustomTooltip>
      <p className="font-medium whitespace-normal break-words">{data.name}</p>
      <p className="text-sm text-muted-foreground">Weight: {data.weight}</p>
      <p className="text-sm text-muted-foreground">
        Sentiment: {(data.sentiment * 100).toFixed(0)}%
      </p>
      {data.evolution && (
        <p className="text-sm text-muted-foreground mt-1 whitespace-normal">
          Evolution: {data.evolution}
        </p>
      )}
    </CustomTooltip>
  )
}

export function TimelineTooltip({ active, payload }) {
  if (!active || !payload || !payload.length) return null

  const data = payload[0].payload
  return (
    <CustomTooltip>
      <p className="font-medium">{format(parseISO(data.period), 'MMM d, yyyy')}</p>
      <p className="text-sm text-muted-foreground">Activity Level: {data.activity}%</p>
      <p className="text-sm text-muted-foreground whitespace-normal">
        Main Topics: {data.dominantTopics.join(', ')}
      </p>
      <p className="text-sm text-muted-foreground">
        Mood: {data.sentiment > 0.2 ? 'Positive' : data.sentiment < -0.2 ? 'Negative' : 'Neutral'} 
        ({Math.abs(data.sentiment * 100).toFixed(0)}%)
      </p>
      {data.contextualEvents && (
        <p className="text-sm text-muted-foreground mt-1 whitespace-normal">
          Context: {data.contextualEvents}
        </p>
      )}
    </CustomTooltip>
  )
}
