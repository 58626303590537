import { useQuery } from '@tanstack/react-query'
import { createContext, useContext, useState } from 'react'

// Create a context for rate limiting
const RateLimitContext = createContext(null)

export function RateLimitProvider({ children }) {
  const [rateLimit, setRateLimit] = useState({
    isLimited: false,
    resetTime: null
  })

  return (
    <RateLimitContext.Provider value={[rateLimit, setRateLimit]}>
      {children}
    </RateLimitContext.Provider>
  )
}

function extractTweetId(url) {
  if (!url) return null
  const match = url.match(/(?:twitter\.com|x\.com)\/\w+\/status\/(\d+)/)
  return match?.[1]
}

export function useTweetPreview(url, shouldFetch = false) {
  const [rateLimit, setRateLimit] = useContext(RateLimitContext)
  const tweetId = extractTweetId(url)
  
  return {
    ...useQuery({
      queryKey: ['tweet', tweetId],
      queryFn: async () => {
        if (!tweetId) return null
        
        // If we're rate limited and haven't reached reset time, don't try
        if (rateLimit.isLimited && rateLimit.resetTime > Date.now()) {
          throw new Error('Rate limited')
        }
        
        const baseUrl = process.env.NODE_ENV === 'development' 
          ? 'http://localhost:3001' 
          : process.env.REACT_APP_API_URL
        
        const response = await fetch(`${baseUrl}/api/tweets/${tweetId}/preview`)
        
        if (response.status === 429) {
          const data = await response.json()
          const resetTime = data.resetTime || (Date.now() + 60000)
          setRateLimit({ isLimited: true, resetTime })
          throw new Error('Rate limited')
        }
        
        if (!response.ok) {
          throw new Error('Failed to fetch tweet')
        }
        
        // Reset rate limit if successful
        if (rateLimit.isLimited) {
          setRateLimit({ isLimited: false, resetTime: null })
        }
        
        return response.json()
      },
      // Only enable the query when explicitly told to fetch
      enabled: !!tweetId && shouldFetch,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 30,
      retry: false
    }),
    rateLimit
  }
} 