import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "./ui/dialog";
import { Button } from "./ui/button";
import { Bell, Sparkles } from 'lucide-react';

const PushNotificationDialog = ({ 
  show, 
  onClose, 
  onEnablePush,
  isDarkMode 
}) => {
  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold flex items-center gap-2">
            <Bell className="h-5 w-5" />
            Enable Push Notifications
          </DialogTitle>
          <DialogDescription className="text-base">
            Get instant notifications when Truffle finds matches to your tracking criteria.
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-col gap-4 mt-4">
          <Button
            onClick={onEnablePush}
            size="lg"
            className="w-full bg-darkGreen-start hover:bg-darkGreen-end text-white transition-colors duration-200"
          >
            <Bell className="h-5 w-5 mr-2" />
            Enable Push Notifications
          </Button>

          {/* Installation banner - only shown on desktop */}
          <div className="hidden md:block">
            <div className={`
              relative overflow-hidden rounded-lg p-4 
              ${isDarkMode 
                ? 'bg-gradient-to-r from-purple-900/50 to-blue-900/50 border border-purple-500/20' 
                : 'bg-gradient-to-r from-purple-50 to-blue-50 border border-purple-200/50'}
            `}>
              {/* Sparkle decorations */}
              <div className="absolute top-2 right-2">
                <Sparkles className="h-4 w-4 text-yellow-400 animate-pulse" />
              </div>
              <div className="absolute bottom-2 left-2">
                <Sparkles className="h-4 w-4 text-yellow-400 animate-pulse delay-300" />
              </div>

              <h3 className={`text-lg font-semibold mb-2 
                ${isDarkMode ? 'text-purple-200' : 'text-purple-900'}`}>
                Take Truffle On The Go! 📱
              </h3>
              <p className={`text-sm 
                ${isDarkMode ? 'text-purple-200/90' : 'text-purple-900/90'}`}>
                Visit <span className="font-semibold">findtruffle.xyz</span> on your mobile device 
                and install the app for push notifications anywhere.
              </p>
              <div className="mt-2 text-xs text-purple-600 dark:text-purple-300/70">
                Works on iOS and Android
              </div>
            </div>
          </div>

          <div className="text-center text-sm text-muted-foreground">
            You can change this setting anytime in your preferences
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PushNotificationDialog; 