import React from 'react'
import { HelpCircle, Database, Lightbulb } from 'lucide-react'
import { Button } from './button'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogTrigger } from './dialog'

function PersonasInfo() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button 
          variant="ghost"
          size="icon"
          className="h-5 w-5 p-0 hover:bg-transparent"
        >
          <HelpCircle className="h-5 w-5 text-muted-foreground/60" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>About Personas</DialogTitle>
          <DialogDescription>
            Personas are AI-powered profiles that help you understand Twitter users better.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4">
          <div className="space-y-4">
            <h4 className="font-medium">Profile Stats:</h4>
            <ul className="list-none pl-0 space-y-3 text-sm text-muted-foreground">
              <li className="flex items-center gap-2">
                <Database className="h-4 w-4 flex-shrink-0" />
                Shows how many tweets we've collected and analyzed from this user
              </li>
              <li className="flex items-center gap-2">
                <Lightbulb className="h-4 w-4 flex-shrink-0" />
                Shows how many AI insights have been generated about this user
              </li>
            </ul>
          </div>
          <div className="space-y-4">
            <h4 className="font-medium">Leveling System:</h4>
            <ul className="list-disc pl-4 space-y-2 text-sm text-muted-foreground">
              <li>Level 1: Initial analysis</li>
              <li>Level 2: Unlocked at 100 combined tweets and insights</li>
              <li>Level 3: Unlocked at 200 combined tweets and insights</li>
              <li>Higher levels mean more accurate and detailed analysis!</li>
            </ul>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default PersonasInfo 