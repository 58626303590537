/* global BigInt */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button } from "./ui/button";
import { useToast } from "./ui/use-toast";
import TwitterHandle from './TwitterHandle';
import { AlertTriangle, RefreshCw, Edit, Trash2, Loader2, Lightbulb, ChevronDown, ChevronUp, Target, Book, LayoutGrid, LayoutList, ExternalLink } from 'lucide-react';
import { Switch } from "./ui/switch";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import ReactMarkdown from 'react-markdown';
import { formatDistanceToNow, differenceInMinutes } from 'date-fns';
import { useProfile } from '../contexts/ProfileContext';
import { useInView } from 'react-intersection-observer';
import CreditCostBadge from "./ui/credit-cost-badge"
import TrackersInfo from './ui/trackers-info'
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover"
import TweetPreview from "./ui/tweet-preview"
import { useTweetPreview } from "../hooks/useTweetPreview"
import TweetSheet from "./ui/tweet-sheet"
import { useMediaQuery } from "../hooks/useMediaQuery"
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "./ui/tooltip"
import { cn } from "../lib/utils"
import { EmptyState } from './ui/empty-state'
import TextScramble from './ui/text-scramble'

function formatCompactTime(date) {
  const minutes = differenceInMinutes(new Date(), new Date(date))
  
  if (minutes < 60) {
    return `${minutes}m ago`
  }
  
  const hours = Math.floor(minutes / 60)
  if (hours < 24) {
    return `${hours}h ago`
  }
  
  const days = Math.floor(hours / 24)
  if (days < 7) {
    return `${days}d ago`
  }
  
  const weeks = Math.floor(days / 7)
  if (weeks < 4) {
    return `${weeks}w ago`
  }
  
  const months = Math.floor(days / 30)
  if (months < 12) {
    return `${months}mo ago`
  }
  
  return `${Math.floor(months / 12)}y ago`
}

function LazyTwitterLink({ href, isVisible, children }) {
  const [isHovering, setIsHovering] = useState(false);
  const [sheetOpen, setSheetOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  
  // Extract timestamp from tweet ID without API call
  const getTweetTimestamp = (url) => {
    const match = url.match(/\/status\/(\d+)/);
    if (!match) return null;
    
    const tweetId = match[1];
    try {
      // Try using BigInt first
      if (typeof BigInt !== 'undefined') {
        const timestamp = Number(BigInt(tweetId) >> 22n) + 1288834974657;
        return new Date(timestamp);
      } else {
        // Fallback for browsers without BigInt support
        // Convert ID to binary string and get first 42 bits
        const binary = parseInt(tweetId).toString(2);
        const timestamp = parseInt(binary.slice(0, -22), 2) + 1288834974657;
        return new Date(timestamp);
      }
    } catch (error) {
      console.warn('Error parsing tweet timestamp:', error);
      return null;
    }
  };
  
  // Only fetch full tweet data when hovering on desktop or sheet is open on mobile
  const shouldFetch = isVisible && ((isHovering && !isMobile) || (sheetOpen && isMobile));
  const { data: tweet, isLoading, error, rateLimit } = useTweetPreview(href, shouldFetch);
  
  const isTwitterLink = href.match(/(?:twitter\.com|x\.com)\/\w+\/status\/\d+/);

  if (!isTwitterLink) {
    return <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>;
  }

  // Get timestamp from URL immediately without API call
  const urlTimestamp = getTweetTimestamp(href);
  const tweetTime = urlTimestamp ? formatCompactTime(urlTimestamp) : null;

  // Calculate remaining cooldown time
  const cooldownText = rateLimit.isLimited && rateLimit.resetTime ? 
    `Preview available in ${Math.ceil((rateLimit.resetTime - Date.now()) / 1000)}s` : 
    null;

  const handleClick = (e) => {
    if (isMobile) {
      e.preventDefault();
      setSheetOpen(true);
    } else {
      e.preventDefault();
      window.open(href, '_blank');
    }
  };

  return (
    <>
      <Popover open={!isMobile && isHovering}>
        <PopoverTrigger asChild>
          <button 
            className="inline-flex items-center gap-1 text-muted-foreground hover:text-foreground relative group"
            onMouseEnter={() => !isMobile && setIsHovering(true)}
            onMouseLeave={() => !isMobile && setIsHovering(false)}
            onClick={handleClick}
          >
            <span className="inline-flex items-center gap-1 px-1.5 py-0.5 rounded-full 
              bg-background dark:bg-[hsl(20_12%_16%)] 
              border border-border dark:border-[hsl(20_12%_20%)]
              hover:bg-accent dark:hover:bg-[hsl(20_12%_20%)] 
              text-xs"
            >
              <svg
                className="h-3 w-3"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
              </svg>
              {tweetTime && (
                <span className="text-[10px] font-normal border-l border-muted-foreground/20 pl-1">
                  {tweetTime}
                </span>
              )}
            </span>
            {cooldownText && (
              <span className="absolute -top-6 left-1/2 transform -translate-x-1/2 text-xs text-muted-foreground bg-background border rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
                {cooldownText}
              </span>
            )}
          </button>
        </PopoverTrigger>
        {!rateLimit.isLimited && !isMobile && isHovering && (
          <PopoverContent 
            side="top" 
            align="start" 
            className="p-0 w-[300px] z-[100]"
            sideOffset={5}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <div className="bg-background border rounded-lg shadow-lg overflow-hidden">
              <TweetPreview tweet={tweet} isLoading={isLoading} error={error} />
            </div>
          </PopoverContent>
        )}
      </Popover>

      {/* Mobile Sheet */}
      <TweetSheet
        isOpen={sheetOpen}
        onOpenChange={setSheetOpen}
        tweetUrl={href}
      />
    </>
  );
}

const ReminderItem = React.memo(({ reminder, onCheck, onEdit, onDelete, onToggle, isDarkMode, isChecking, onGenerateInsight, isGeneratingInsight, onAddProfile, currentTrackedProfiles, onMarkSeen, onDeleteTruffle }) => {
  const [cooldownRemaining, setCooldownRemaining] = useState(0);
  const [checkCooldownRemaining, setCheckCooldownRemaining] = useState(0);
  const [insightCooldownRemaining, setInsightCooldownRemaining] = useState(0);
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  });

  // First, add the helper function
  const getEventTimestamp = (event) => {
    if (event.type === 'insight') {
      return new Date(event.createdAt).getTime();
    }
    return new Date(event.triggeredAt).getTime();
  };

  // Combined events logic
  const combinedEvents = useMemo(() => {
    const events = [
      ...(reminder.triggerEvents || []).map(event => ({ 
        ...event, 
        timestamp: new Date(event.triggeredAt).getTime() 
      })),
      ...(reminder.insights || []).map(insight => ({ 
        ...insight, 
        type: 'insight',
        timestamp: new Date(insight.createdAt).getTime()
      }))
    ].sort((a, b) => b.timestamp - a.timestamp);

    return events.reduce((acc, event) => {
      if (event.type === 'insight') {
        const existingInsightIndex = acc.findIndex(e => e.type === 'insight' && e.content === event.content);
        if (existingInsightIndex === -1) {
          acc.push(event);
        }
      } else {
        const existingTruffleIndex = acc.findIndex(e => !e.type && e.analysis === event.analysis);
        if (existingTruffleIndex === -1) {
          acc.push(event);
        }
      }
      return acc;
    }, []);
  }, [reminder.triggerEvents, reminder.insights]);

  // Single expandedTruffles state
  const [expandedTruffles, setExpandedTruffles] = useState(() => {
    const newState = {};
    if (reminder.isActive && combinedEvents.length > 0) {
      const mostRecentEvent = combinedEvents[0];
      if (!mostRecentEvent.type) { // If it's a truffle
        newState[mostRecentEvent.id] = true;
      }
    }
    return newState;
  });

  // Combined effect for localStorage
  useEffect(() => {
    localStorage.setItem(`truffle-expanded-${reminder.id}`, JSON.stringify(expandedTruffles));
  }, [expandedTruffles, reminder.id]);

  // Combined effect for handling new content
  useEffect(() => {
    if (combinedEvents.length > 0 && reminder.hasUnreadContent) {
      const mostRecentEvent = combinedEvents[0];
      
      // Collapse all
      setExpandedTruffles({});
      
      // Expand only the most recent
      if (mostRecentEvent.type === 'insight') {
        setExpandedTruffles(prev => ({
          ...prev,
          [mostRecentEvent.id]: true
        }));
      } else {
        setExpandedTruffles(prev => ({
          ...prev,
          [mostRecentEvent.id]: true
        }));
      }
    }
  }, [combinedEvents, reminder.hasUnreadContent]);

  // Update cooldowns
  useEffect(() => {
    const updateCooldowns = () => {
      if (reminder.lastChecked) {
        const lastCheckedTime = new Date(reminder.lastChecked).getTime();
        const currentTime = new Date().getTime();
        const timeDiff = Math.max(0, 10 - Math.floor((currentTime - lastCheckedTime) / (60 * 1000)));
        setCheckCooldownRemaining(timeDiff);
      } else {
        setCheckCooldownRemaining(0);
      }

      if (reminder.lastInsightGenerated) {
        const lastInsightTime = new Date(reminder.lastInsightGenerated).getTime();
        const currentTime = new Date().getTime();
        const timeDiff = Math.max(0, 10 - Math.floor((currentTime - lastInsightTime) / (60 * 1000)));
        setInsightCooldownRemaining(timeDiff);
      } else {
        setInsightCooldownRemaining(0);
      }
    };

    // Update immediately
    updateCooldowns();
    
    // Then update every second for more precise countdown
    const intervalId = setInterval(updateCooldowns, 1000);
    return () => clearInterval(intervalId);
  }, [reminder.lastChecked, reminder.lastInsightGenerated]);

  // Mark as seen when in view
  useEffect(() => {
    if (inView && reminder.hasUnreadContent) {
      const timeout = setTimeout(() => {
        onMarkSeen?.(reminder.id);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [inView, reminder.hasUnreadContent, reminder.id, onMarkSeen]);

  const isOptimistic = reminder.id.startsWith('temp-');
  const creditCost = reminder.profiles ? reminder.profiles.length * 10 : 10;

  const renderTruffle = (event) => {
    if (!event) return null;

    const isNew = reminder.hasUnreadContent && event === reminder.triggerEvents[0];
    const isExpanded = expandedTruffles[event.id];

    return (
      <div
        key={event.id}
        className={`mt-2 first:mt-0 rounded-lg transition-all duration-300 ease-in-out overflow-hidden ${
          isDarkMode ? 'bg-[hsl(20_12%_12%)] text-[hsl(20_10%_90%)]' : 'bg-gray-100 text-gray-700'
        } ${isNew ? 'ring-2 ring-[hsl(20_89%_48%)] bg-[hsl(20_12%_10%)]/10' : ''}`}
      >
        {/* Header - clickable for both expand and collapse */}
        <div 
          onClick={() => handleTruffleToggle(event.id)}
          className="p-2 flex items-center justify-between cursor-pointer hover:bg-black/5 rounded-t-lg"
        >
          <div className="flex items-center gap-2">
            <span className="text-amber-600 dark:text-amber-500">🍄‍🟫</span>
            <p className="text-sm font-medium">Found Truffle!</p>
            <div className="transform transition-transform duration-300" 
              style={{ 
                transform: `rotate(${isExpanded ? '180deg' : '0deg'})`,
                transformOrigin: 'center' 
              }}>
              <ChevronDown className="h-4 w-4 text-muted-foreground" />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={(e) => {
                e.stopPropagation(); // Prevent expansion toggle
                onDeleteTruffle(event.id);
              }}
              className="p-1 hover:bg-black/10 rounded-full transition-colors"
            >
              <Trash2 className="h-3 w-3 text-muted-foreground hover:text-red-500" />
            </button>
            <p className="text-xs text-muted-foreground flex-shrink-0">
              {event.triggeredAt ? formatCompactTime(event.triggeredAt) : 'Unknown time'}
            </p>
          </div>
        </div>

        {/* Content - only clickable to expand if collapsed */}
        <div
          className={`overflow-hidden transition-all duration-300 ease-spring relative ${!isExpanded ? 'cursor-pointer' : ''}`}
          onClick={() => !isExpanded && handleTruffleToggle(event.id)}
          style={{
            maxHeight: isExpanded ? '2000px' : '100px',
            opacity: 1,
          }}
        >
          <div className="p-2 pt-0">
            <ReactMarkdown
              className={`text-sm prose prose-sm max-w-none ${
                isDarkMode ? 'text-gray-200' : 'text-gray-800'
              } [&_h2]:mt-12 [&_h2]:mb-8 [&_p]:mb-2 first:[&_p]:mt-0 last:[&_p]:mb-0 [&_ul]:mt-0 [&_ul]:mb-4`}
              components={{
                p: ({ node, ...props }) => <p {...props} />,
                h2: ({ node, ...props }) => (
                  <h2 
                    className="text-base font-semibold" 
                    {...props} 
                  />
                ),
                li: ({ node, ordered, ...props }) => {
                  const listStyleClass = ordered ? "list-decimal" : "list-disc";
                  return (
                    <li 
                      className={`${listStyleClass} ml-4 mb-2`} 
                      {...props} 
                    />
                  );
                },
                a: ({ node, href, ...props }) => (
                  <LazyTwitterLink href={href} isVisible={isExpanded} {...props} />
                )
              }}
            >
              {event.analysis}
            </ReactMarkdown>
          </div>
          {!isExpanded && (
            <div 
              className="absolute bottom-0 left-0 right-0 h-16 pointer-events-none"
              style={{
                background: `linear-gradient(to bottom, transparent, ${
                  isDarkMode ? 'hsl(20 12% 12%)' : '#f3f4f6'
                })`
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderPromptSkeleton = () => (
    <div className="text-xl font-semibold text-card-foreground w-full">
      <TextScramble 
        finalText={reminder?.reformulatedPrompt} 
        isLoading={!reminder?.reformulatedPrompt || reminder.id.startsWith('temp-')} 
      />
    </div>
  );

  const wrappedOnCheck = useCallback(async () => {
    if (reminder.hasUnreadContent) {
      await onMarkSeen(reminder.id);
    }
    onCheck(reminder.id);
  }, [reminder.hasUnreadContent, reminder.id, onMarkSeen, onCheck]);

  // Add expandedInsights state with localStorage persistence
  const [expandedInsights, setExpandedInsights] = useState(() => {
    const newState = {};
    if (reminder.isActive && combinedEvents.length > 0) {
      const mostRecentEvent = combinedEvents[0];
      if (mostRecentEvent.type === 'insight') {
        newState[mostRecentEvent.id] = true;
      }
    }
    return newState;
  });

  // Save expanded insights state to localStorage
  useEffect(() => {
    localStorage.setItem(`insight-expanded-${reminder.id}`, JSON.stringify(expandedInsights));
  }, [expandedInsights, reminder.id]);

  // Handle new insights expansion
  useEffect(() => {
    if (reminder.insights?.length > 0) {
      const latestInsight = reminder.insights[0];
      const latestTruffle = reminder.triggerEvents?.[0];
      
      if (reminder.hasUnreadContent) {
        // Only expand if this is the most recent overall
        if (!latestTruffle || new Date(latestInsight.createdAt) > new Date(latestTruffle.triggeredAt)) {
          setExpandedInsights(prev => ({
            ...prev,
            [latestInsight.id]: true
          }));
        }
      }
    }
  }, [reminder.insights, reminder.triggerEvents, reminder.hasUnreadContent]);

  const renderInsight = (insight) => {
    if (!insight) return null;

    const isExpanded = expandedInsights[insight.id];

    return (
      <div
        key={insight.id}
        className={`mt-2 first:mt-0 rounded-lg transition-all duration-300 ease-in-out overflow-hidden ${
          isDarkMode ? 'bg-[hsl(20_12%_14%)] text-[hsl(20_10%_90%)]' : 'bg-blue-50 text-blue-800'
        }`}
      >
        {/* Header - clickable for both expand and collapse */}
        <div 
          onClick={() => handleInsightToggle(insight.id)}
          className="p-2 flex items-center justify-between cursor-pointer hover:bg-black/5 rounded-t-lg"
        >
          <div className="flex items-center gap-2">
            <span className="text-blue-600 dark:text-blue-500">💡</span>
            <p className="text-sm font-medium">Insight!</p>
            <div className="transform transition-transform duration-300" 
              style={{ 
                transform: `rotate(${isExpanded ? '180deg' : '0deg'})`,
                transformOrigin: 'center' 
              }}>
              <ChevronDown className="h-4 w-4 text-muted-foreground" />
            </div>
          </div>
          <div className="text-xs text-muted-foreground ml-2 flex-shrink-0 flex items-center gap-2">
            <span>{insight.tweetCount} posts</span>
            <span>·</span>
            <span>{insight.createdAt ? formatCompactTime(insight.createdAt) : 'Unknown time'}</span>
          </div>
        </div>

        {/* Content - only clickable to expand if collapsed */}
        <div
          className={`overflow-hidden transition-all duration-300 ease-spring relative ${!isExpanded ? 'cursor-pointer' : ''}`}
          onClick={() => !isExpanded && handleInsightToggle(insight.id)}
          style={{
            maxHeight: isExpanded ? '2000px' : '100px',
            opacity: 1,
          }}
        >
          <div className="p-2 pt-0">
            <ReactMarkdown
              className={`text-sm prose prose-sm max-w-none ${
                isDarkMode ? 'text-indigo-200' : 'text-indigo-800'
              } [&_h2]:mt-12 [&_h2]:mb-8 [&_p]:mb-2 first:[&_p]:mt-0 last:[&_p]:mb-0 [&_ul]:mt-0 [&_ul]:mb-4`}
              components={{
                p: ({ node, ...props }) => <p {...props} />,
                h2: ({ node, ...props }) => (
                  <h2 
                    className="text-base font-semibold" 
                    {...props} 
                  />
                ),
                li: ({ node, ordered, ...props }) => {
                  const listStyleClass = ordered ? "list-decimal" : "list-disc";
                  return (
                    <li 
                      className={`${listStyleClass} ml-4 mb-2`} 
                      {...props} 
                    />
                  );
                },
                a: ({ node, href, ...props }) => (
                  <LazyTwitterLink href={href} isVisible={isExpanded} {...props} />
                )
              }}
            >
              {insight.content}
            </ReactMarkdown>
          </div>
          {!isExpanded && (
            <div 
              className="absolute bottom-0 left-0 right-0 h-16 pointer-events-none"
              style={{
                background: `linear-gradient(to bottom, transparent, ${
                  isDarkMode ? 'hsl(20 12% 14%)' : '#eff6ff'
                })`
              }}
            />
          )}
        </div>
      </div>
    );
  };

  // Add handlers for toggling expansion
  const handleTruffleToggle = useCallback((eventId) => {
    setExpandedTruffles(prev => ({
      ...prev,
      [eventId]: !prev[eventId]
    }));
  }, []);

  const handleInsightToggle = useCallback((insightId) => {
    setExpandedInsights(prev => ({
      ...prev,
      [insightId]: !prev[insightId]
    }));
  }, []);

  // Add opacity management based on expanded state
  const isAnyExpanded = useMemo(() => {
    return Object.values(expandedTruffles).some(Boolean) || 
           Object.values(expandedInsights).some(Boolean);
  }, [expandedTruffles, expandedInsights]);

  return (
    <Card 
      ref={ref}
      id={`reminder-${reminder.id}`}
      className={cn(`
        ${!reminder.isActive && !isAnyExpanded ? 'opacity-50' : ''} 
        border-white border-[2px] dark:border-[hsl(20_12%_16%)] rounded-xl overflow-hidden 
        bg-gray-50 dark:bg-[hsl(20_12%_8%)] mb-4 shadow-md
        transition-all duration-300 ease-in-out
        ${reminder.hasUnreadContent ? 'ring-2 ring-[hsl(20_89%_48%)]' : 'ring-0'}
        ${reminder.id.startsWith('temp-') ? 'animate-slide-in animate-ripple-ring' : ''}
      `)}
    >
      <div className="bg-gray-100 dark:bg-[hsl(20_12%_10%)] p-0">
        <div className="flex items-start justify-between p-2">
          {/* Left side with Twitter handles */}
          <div className="flex-1 flex flex-wrap gap-2 pr-2">
            {reminder.profiles && reminder.profiles.length > 0 ? (
              reminder.profiles.map((profile, index) => (
                <TwitterHandle
                  key={index}
                  handle={profile.handle}
                  displayName={profile.displayName}
                  profileImageUrl={profile.profileImageUrl}
                  showRemove={false}
                  isOnCard={true}
                  onAdd={() => onAddProfile(profile)}
                  currentTrackedProfiles={currentTrackedProfiles || []}
                />
              ))
            ) : (
              <TwitterHandle
                handle={reminder.profileToTrack}
                displayName={reminder.displayName}
                profileImageUrl={reminder.profileImageUrl}
                showRemove={false}
                isOnCard={true}
                onAdd={() =>
                  onAddProfile({
                    handle: reminder.profileToTrack,
                    displayName: reminder.displayName,
                    profileImageUrl: reminder.profileImageUrl,
                  })
                }
                currentTrackedProfiles={currentTrackedProfiles || []}
              />
            )}
          </div>
          
          {/* Right side with toggle switch */}
          <div className="flex-shrink-0">
            <Switch
              checked={reminder.isActive}
              onCheckedChange={() => onToggle(reminder.id, reminder.isActive)}
              className="mt-1"
              disabled={isOptimistic}
            />
          </div>
        </div>
        <div className="w-full border-gray-200 dark:border-[hsl(20_12%_14%)]"></div>
      </div>
      <CardHeader className="p-2 sm:p-3">
        <CardTitle className="text-xl font-semibold text-card-foreground w-full">
          <div className="transition-opacity duration-200 ease-in-out w-full">
            <TextScramble 
              key={`${reminder.id}-${!!reminder.reformulatedPrompt}`}
              reminderId={reminder.id}
              finalText={reminder.reformulatedPrompt} 
              isLoading={!reminder.reformulatedPrompt || reminder.id.startsWith('temp-') || reminder.isOptimistic}
            />
          </div>
        </CardTitle>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          <span className="font-normal">{creditCost} credits</span> · Check {reminder.frequency.toLowerCase()} ·
          {reminder.includeReplies ? ' Posts & replies' : ' Posts only'} · 
          {reminder.lastChecked 
            ? ` Last checked ${formatCompactTime(reminder.lastChecked)}`
            : ' Never checked'}
        </p>
      </CardHeader>
      <CardContent className="pt-0 p-2 sm:p-3">
        <div className="relative">
          {/* Outer Container with Hidden Overflow */}
          <div className="overflow-x-auto no-scrollbar -mx-2 px-2"> {/* Negative margin to extend to edges, padding to maintain visual spacing */}
            {/* Inner Scrollable Container */}
            <div className="flex items-center space-x-2 min-w-fit">
              <Button
                onClick={() => wrappedOnCheck(reminder.id)}
                size="sm"
                variant="outline"
                disabled={isChecking || checkCooldownRemaining > 0 || isOptimistic}
                className="text-xs whitespace-nowrap flex-shrink-0
                  bg-background hover:bg-accent
                  dark:bg-[hsl(20_12%_12%)] dark:hover:bg-[hsl(20_12%_16%)]
                  border-border hover:border-border/80
                  dark:border-[hsl(20_12%_20%)] dark:hover:border-[hsl(20_12%_24%)]"
              >
                {isChecking ? (
                  <>
                    <Loader2 className="h-3 w-3 mr-1 animate-spin" />
                    Checking...
                  </>
                ) : checkCooldownRemaining > 0 ? (
                  <>
                    <RefreshCw className="h-3 w-3 mr-1" />
                    Check Recent {checkCooldownRemaining}m
                  </>
                ) : (
                  <>
                    <RefreshCw className="h-3 w-3 mr-1" />
                    Check Recent
                    <CreditCostBadge cost={creditCost} />
                  </>
                )}
              </Button>
              <Button
                onClick={() => onGenerateInsight(reminder.id)}
                size="sm"
                variant="outline"
                disabled={isGeneratingInsight || insightCooldownRemaining > 0 || isOptimistic}
                className="text-xs whitespace-nowrap flex-shrink-0
                  bg-background hover:bg-accent
                  dark:bg-[hsl(20_12%_12%)] dark:hover:bg-[hsl(20_12%_16%)]
                  border-border hover:border-border/80
                  dark:border-[hsl(20_12%_20%)] dark:hover:border-[hsl(20_12%_24%)]"
              >
                {isGeneratingInsight ? (
                  <>
                    <Loader2 className="h-3 w-3 mr-1 animate-spin" />
                    Generating...
                  </>
                ) : insightCooldownRemaining > 0 ? (
                  <>
                    <Lightbulb className="h-3 w-3 mr-1" />
                    Insight {insightCooldownRemaining}m
                  </>
                ) : (
                  <>
                    <Lightbulb className="h-3 w-3 mr-1" />
                    Insight
                    <CreditCostBadge cost={creditCost} />
                  </>
                )}
              </Button>
              <Button
                onClick={() => onEdit(reminder.id)}
                size="sm"
                variant="outline"
                disabled={isOptimistic}
                className="text-xs whitespace-nowrap flex-shrink-0
                  bg-background hover:bg-accent
                  dark:bg-[hsl(20_12%_12%)] dark:hover:bg-[hsl(20_12%_16%)]
                  border-border hover:border-border/80
                  dark:border-[hsl(20_12%_20%)] dark:hover:border-[hsl(20_12%_24%)]"
              >
                <Edit className="h-3 w-3 mr-1" /> Edit
              </Button>
              <Button
                onClick={() => onDelete(reminder.id)}
                size="sm"
                variant="delete"
                disabled={isOptimistic}
                className="text-xs whitespace-nowrap flex-shrink-0"
              >
                <Trash2 className="h-3 w-3" />
              </Button>
            </div>
          </div>
        </div>
        {combinedEvents.map((event, index) => (
          <React.Fragment key={event.id || `event-${index}`}>
            {event.type === 'insight' ? renderInsight(event) : renderTruffle(event)}
          </React.Fragment>
        ))}
      </CardContent>
    </Card>
  );
});

// Add this utility function at the top
const fetchWithRetry = async (url, options = {}, maxRetries = 3) => {
  for (let i = 0; i < maxRetries; i++) {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        if (response.status === 429 || (errorData.error && errorData.error.includes('rate limit'))) {
          // If rate limited, wait longer before retry
          await new Promise(resolve => setTimeout(resolve, (i + 1) * 2000));
          continue;
        }
        throw new Error(errorData.details || `HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      if (i === maxRetries - 1) throw error;
      // Exponential backoff
      await new Promise(resolve => setTimeout(resolve, Math.pow(2, i) * 1000));
    }
  }
};

const ReminderComponent = ({
  authenticated,
  userId,
  credits,
  deductCredits,
  hasEnoughCredits,
  reminders,
  updateReminders,
  checkingReminders,
  setCheckingReminders,
  generatingInsights,
  setGeneratingInsights,
  isDarkMode,
  onAddProfile,
  onEditReminder,
  onUpdateStatus,
  selectedProfiles,
  onMarkSeen
}) => {
  const { toast } = useToast();
  const { addInsightToProfile, addTweetsToProfile } = useProfile();

  // Lift expandedTruffles state up to parent component
  const [expandedTrufflesState, setExpandedTrufflesState] = useState({});

  const checkReminder = useCallback(async (reminderId) => {
    const reminder = reminders.find(r => r.id === reminderId);
    const creditCost = reminder.profiles.length * 10;

    async function operation() {
      setCheckingReminders(prev => ({ ...prev, [reminderId]: true }));
      
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/check-updates/${reminderId}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId })
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to check updates');
        }

        const data = await response.json();

        // Update reminders while preserving existing trigger events
        await new Promise(resolve => {
          updateReminders(prevReminders => {
            const updated = prevReminders.map(r => {
              if (r.id === reminderId) {
                // Get existing trigger events that aren't in the new data
                const existingEvents = r.triggerEvents || [];
                const newEvents = data.reminder.triggerEvents || [];
                
                // Combine events, avoiding duplicates by ID
                const seenIds = new Set(newEvents.map(e => e.id));
                const combinedEvents = [
                  ...newEvents,
                  ...existingEvents.filter(e => !seenIds.has(e.id))
                ];
                
                return {
                  ...r,
                  hasUnreadContent: data.found ? true : r.hasUnreadContent,
                  triggerEvents: combinedEvents,
                  lastChecked: new Date().toISOString()
                };
              }
              return r;
            });
            resolve();
            return updated;
          });
        });

        if (data.found) {
          setTimeout(() => {
            const reminderElement = document.getElementById(`reminder-${reminderId}`);
            if (reminderElement) {
              reminderElement.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'center'
              });
            }
          }, 100);

          toast({
            title: "Truffle found! 🍄‍🟫",
            description: "New matching content found. Check it out!",
            duration: 5000,
          });
        } else {
          // Add toast for when no new truffles are found
          toast({
            title: "No new truffles",
            description: "No new matching content found at this time.",
            duration: 3000,
          });
        }

        return data;
      } catch (error) {
        console.error('Error in check operation:', error);
        throw error;
      } finally {
        setCheckingReminders(prev => ({ ...prev, [reminderId]: false }));
      }
    }

    return await deductCredits(creditCost, operation);
  }, [reminders, deductCredits, updateReminders, toast, userId]);

  // Update the generateInsight function
  const generateInsight = useCallback(async (reminderId) => {
    const reminder = reminders.find(r => r.id === reminderId);
    const creditCost = reminder.profiles.length * 10;

    async function operation() {
      setGeneratingInsights(prev => ({ ...prev, [reminderId]: true }));
      
      try {
        const data = await fetchWithRetry(
          `${process.env.REACT_APP_API_URL}/api/generate-insight/${reminderId}`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId }),
          }
        );

        // Update reminders with hasUnreadContent set to true
        await new Promise(resolve => {
          updateReminders(prevReminders => {
            const updated = prevReminders.map(r => 
              r.id === reminderId 
                ? { 
                    ...r,
                    lastInsightGenerated: new Date().toISOString(),
                    hasUnreadContent: true,
                    insights: [
                      {
                        id: data.id,
                        content: data.insight,
                        createdAt: data.createdAt,
                        tweetCount: data.tweetCount,
                        isNew: true,
                        type: 'insight'
                      },
                      ...(r.insights || [])
                    ]
                  } 
                : r
            );
            resolve();
            return updated;
          });
        });

        // Wait a bit for the DOM to update after the state change and sorting
        setTimeout(() => {
          const reminderElement = document.getElementById(`reminder-${reminderId}`);
          if (reminderElement) {
            reminderElement.scrollIntoView({ 
              behavior: 'smooth', 
              block: 'center'
            });
          }
        }, 100);

        reminder.profiles.forEach(profile => {
          addInsightToProfile(profile.id, {
            id: data.id,
            content: data.insight,
            createdAt: data.createdAt,
            tweetCount: data.tweetCount
          });
          if (data.tweets) {
            addTweetsToProfile(profile.id, data.tweets);
          }
        });

        return data;
      } catch (error) {
        console.error('Error generating insight:', error);
        throw error;
      } finally {
        setGeneratingInsights(prev => ({ ...prev, [reminderId]: false }));
      }
    }

    return await deductCredits(creditCost, operation);
  }, [reminders, deductCredits, userId, updateReminders, addInsightToProfile, addTweetsToProfile]);

  const deleteReminder = useCallback(async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reminders/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Failed to delete reminder: ${response.status}`);
      }

      // Update local state to remove the reminder
      updateReminders(prevReminders => 
        prevReminders.filter(reminder => reminder.id !== id)
      );

      toast({
        title: "Reminder deleted",
        description: "The reminder has been successfully deleted.",
        duration: 3000,
      });
    } catch (error) {
      console.error('Error deleting reminder:', error);
      toast({
        title: "Error",
        description: "Failed to delete reminder. Please try again.",
        variant: "destructive",
        duration: 5000,
      });
    }
  }, [updateReminders, toast]);

  // Update the toggleReminderActive function
  const toggleReminderActive = useCallback(async (id, currentStatus) => {
    // Optimistically update the UI first
    updateReminders(prevReminders =>
      prevReminders.map(reminder =>
        reminder.id === id ? { ...reminder, isActive: !currentStatus } : reminder
      )
    );

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reminders/${id}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          status: !currentStatus ? 'active' : 'disabled'
        })
      });

      if (!response.ok) {
        // If the API call fails, revert the optimistic update
        updateReminders(prevReminders =>
          prevReminders.map(reminder =>
            reminder.id === id ? { ...reminder, isActive: currentStatus } : reminder
          )
        );
        
        const errorData = await response.json();
        throw new Error(errorData.error || `Failed to update reminder status: ${response.status}`);
      }

      toast({
        title: currentStatus ? "Reminder disabled" : "Reminder enabled",
        description: `The reminder has been ${currentStatus ? 'disabled' : 'enabled'}.`,
        duration: 3000,
      });
    } catch (error) {
      console.error('Error toggling reminder:', error);
      toast({
        title: "Error",
        description: "Failed to update reminder status. Please try again.",
        variant: "destructive",
        duration: 5000,
      });
    }
  }, [updateReminders, toast]);

  // Add a proper markAsSeen function
  const markAsSeen = useCallback(async (reminderId) => {
    try {
      // Optimistic update
      updateReminders(prevReminders =>
        prevReminders.map(reminder =>
          reminder.id === reminderId
            ? { ...reminder, hasUnreadContent: false }
            : reminder
        )
      );

      // Persist to backend
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reminders/${reminderId}/seen`, {
        method: 'PUT',
      });

      if (!response.ok) {
        throw new Error('Failed to mark reminder as seen');
      }
    } catch (error) {
      console.error('Error marking reminder as seen:', error);
      // Revert optimistic update on error
      updateReminders(prevReminders =>
        prevReminders.map(reminder =>
          reminder.id === reminderId
            ? { ...reminder, hasUnreadContent: true }
            : reminder
        )
      );
      toast({
        title: "Error",
        description: "Failed to mark reminder as seen. Please try again.",
        variant: "destructive",
      });
    }
  }, [updateReminders, toast]);

  const deleteTruffle = useCallback(async (eventId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/trigger-events/${eventId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete truffle');
      }

      // Update local state to remove the truffle
      updateReminders(prevReminders => 
        prevReminders.map(reminder => ({
          ...reminder,
          triggerEvents: reminder.triggerEvents.filter(event => event.id !== eventId)
        }))
      );

      toast({
        title: "Truffle deleted",
        description: "Don't worry, tweets you collected are still used for the profile persona",
        duration: 3000,
      });
    } catch (error) {
      console.error('Error deleting truffle:', error);
      toast({
        title: "Error",
        description: "Failed to delete truffle. Please try again.",
        variant: "destructive",
      });
    }
  }, [updateReminders, toast]);

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-2 text-lg text-muted-foreground font-normal">
          <Target className="h-5 w-5" />
          {reminders.length} Tracker{reminders.length !== 1 ? 's' : ''}
          <TrackersInfo />
        </div>
      </div>

      {reminders.length === 0 && (
        <EmptyState
          type="reminder"
          title="Create your first tracker"
          description={
            <>
              Track when someone tweets about specific topics, mentions competitors, 
              or shares insights. Get notified instantly and generate AI summaries.
            </>
          }
        />
      )}

      {reminders.map(reminder => (
        <ReminderItem
          key={reminder.id}
          reminder={reminder}
          onCheck={checkReminder}
          onEdit={onEditReminder}
          onDelete={deleteReminder}
          onToggle={toggleReminderActive}
          isDarkMode={isDarkMode}
          isChecking={checkingReminders[reminder.id]}
          onGenerateInsight={generateInsight}
          isGeneratingInsight={generatingInsights[reminder.id]}
          onAddProfile={onAddProfile}
          currentTrackedProfiles={selectedProfiles}
          onMarkSeen={markAsSeen}
          onDeleteTruffle={deleteTruffle}
        />
      ))}
    </div>
  );
};

export default React.memo(ReminderComponent);
