import { useEffect, useRef } from 'react'
import * as THREE from 'three'

function GeometricPulseLoader({ className = '' }) {
    const containerRef = useRef(null)
    const sceneRef = useRef(null)

    useEffect(() => {
        if (!containerRef.current) return

        // Setup scene
        const scene = new THREE.Scene()
        const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000)
        const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true })
        
        renderer.setSize(200, 200) // Fixed size for the loader
        containerRef.current.appendChild(renderer.domElement)
        
        // Create geometry (icosahedron for interesting shape)
        const geometry = new THREE.IcosahedronGeometry(1, 0)
        
        // Shader materials
        const material = new THREE.ShaderMaterial({
            uniforms: {
                time: { value: 0 },
                color: { value: new THREE.Color('#3b82f6') } // Tailwind blue-500
            },
            vertexShader: `
                uniform float time;
                varying float vOpacity;
                
                void main() {
                    float displacement = sin(time + position.x * 5.0) * 0.1;
                    vec3 newPosition = position + normal * displacement;
                    vOpacity = displacement + 0.5;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
                }
            `,
            fragmentShader: `
                uniform vec3 color;
                varying float vOpacity;
                
                void main() {
                    gl_FragColor = vec4(color, vOpacity);
                }
            `,
            transparent: true
        })

        const mesh = new THREE.Mesh(geometry, material)
        scene.add(mesh)
        
        camera.position.z = 2.5

        // Animation
        function animate() {
            requestAnimationFrame(animate)
            material.uniforms.time.value += 0.05
            mesh.rotation.x += 0.01
            mesh.rotation.y += 0.01
            renderer.render(scene, camera)
        }

        animate()

        // Cleanup
        return () => {
            if (containerRef.current) {
                containerRef.current.removeChild(renderer.domElement)
            }
            geometry.dispose()
            material.dispose()
            renderer.dispose()
        }
    }, [])

    return (
        <div 
            ref={containerRef} 
            className={`flex items-center justify-center ${className}`}
            style={{ width: 200, height: 200 }}
        />
    )
}

export default GeometricPulseLoader