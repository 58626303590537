import React from 'react';
import { createRoot } from 'react-dom/client';
import './globals.css';
import App from './App';
import { PrivyProvider } from '@privy-io/react-auth';

const root = createRoot(document.getElementById('root'));
const originalConsoleError = console.error
console.error = (...args) => {
  if (args[0]?.includes?.('third-party cookies')) return
  originalConsoleError(...args)
}
root.render(
  <React.StrictMode>
    <PrivyProvider
      appId={process.env.REACT_APP_PRIVY_APP_ID}
      config={{
        // Disable embedded wallets
        embeddedWallets: {
          createOnLogin: false,
          noPromptOnSignature: true
        },
        // Only allow these login methods
        loginMethods: ['wallet', 'email', 'twitter', 'farcaster'],
        // Don't show wallet selector on login
        defaultAuthMode: 'email',
        // Appearance customization
        appearance: {
          theme: 'light',
          accentColor: '#FF5D0A',
          showWalletLoginFirst: false,
        },
        // Add these Twitter-specific settings
        oauth: {
          twitter: {
            redirectUri: `${window.location.origin}/auth/callback`, // Adjust if needed
            scopes: ['users.read', 'tweet.read', 'offline.access']
          }
        }
      }}
    >

      <App />
    </PrivyProvider>
  </React.StrictMode>
);