import { Sheet, SheetContent } from "./sheet"
import TweetPreview from "./tweet-preview"
import { useTweetPreview } from "../../hooks/useTweetPreview"

function TweetSheet({ isOpen, onOpenChange, tweetUrl }) {
  const { data: tweet, isLoading, error, rateLimit } = useTweetPreview(tweetUrl, isOpen)
  
  const href = tweetUrl || '#'

  // Don't render anything if there's no URL
  if (!tweetUrl) return null

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent side="bottom" className="p-0">
        <div className="p-4">
          {rateLimit.isLimited ? (
            <div className="text-sm text-muted-foreground text-center py-4">
              <p>Rate limit exceeded.</p>
              <p>Please try again in {Math.ceil((rateLimit.resetTime - Date.now()) / 1000 / 60)} minutes</p>
            </div>
          ) : (
            <>
              {isOpen && (
                <TweetPreview tweet={tweet} isLoading={isLoading} error={error} />
              )}
              {!isLoading && !error && tweet && (
                <a
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block w-full mt-4 text-center text-sm text-blue-500 hover:text-blue-600"
                >
                  Open on Twitter →
                </a>
              )}
            </>
          )}
        </div>
      </SheetContent>
    </Sheet>
  )
}

export default TweetSheet 