import * as React from "react"
import { cn } from "../../lib/utils"

const Input = React.forwardRef(({ className, type, multiline, ...props }, ref) => {
  const Component = multiline ? 'textarea' : 'input'
  return (
    <Component
      type={multiline ? undefined : type}
      className={cn(
        "flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-darkGreen-start focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        multiline && "min-h-[80px]",
        className
      )}
      ref={ref}
      {...props}
    />
  )
})
Input.displayName = "Input"

export { Input }