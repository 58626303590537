import React from 'react';
import { Button } from "./ui/button";
import { LogOut, Sun, Moon, Bell, BarChart, Plus, X, Lightbulb } from 'lucide-react';
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { ToggleGroup, ToggleGroupItem } from "./ui/toggle-group";
import TwitterHandle from './TwitterHandle';
import InspirationDialog from './InspirationDialog';
import { useState } from 'react'

export const SidebarContent = (props) => {
  const [showInspiration, setShowInspiration] = useState(false)
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 1024

  const handleSelectPrompt = (prompt) => {
    props.setInputDescription(prompt)
    setShowInspiration(false)
  }

  return (
    <div className="space-y-4 w-full px-1 pb-1">
      {(!props.authenticated && props.showSubtext) && (
        <div className="mt-2">
          <div className="bg-muted/50 rounded-lg p-4 relative">
            <Button
              onClick={() => props.toggleSubtext(false)}
              variant="ghost"
              size="sm"
              className="absolute top-2 right-2 h-6 w-6 p-0"
              aria-label="Close about section"
            >
              <X className="h-4 w-4" />
            </Button>
            <h3 className="text-lg font-semibold mb-2 text-primary">About Truffle</h3>
            <p className="text-sm text-muted-foreground">
              Truffle is a Twitter / X Social Listening app that analyses posts with AI, helping you find hidden patterns and insights. Enter up to 3 profiles at the same time, what you're looking for, and AI tracks them for you. Always double check, AI can make mistakes.
            </p>
          </div>
        </div>
      )}

      <div className="space-y-2">
        <Label htmlFor="profile" className="block">Enter X (Twitter) to track</Label>
        <div className="relative w-full">
          <Input 
            id="profile" 
            placeholder="@username or URL"
            value={props.inputProfileToTrack}
            onChange={props.handleProfileToTrackChange}
            className="w-full"
            data-profile-input
          />
        </div>
        {props.profileInfo && !props.selectedProfiles.some(p => p.handle === `@${props.profileInfo.username}`) && (
          <div className="mt-2">
            <div className="flex items-center justify-between">
              <TwitterHandle 
                handle={`@${props.profileInfo.username}`}
                displayName={props.profileInfo.displayName}
                profileImageUrl={props.profileInfo.profileImageUrl}
                showRemove={false}
              />
              <Button 
                onClick={() => {
                  props.addProfile(props.profileInfo);
                  props.setInputProfileToTrack('');
                  props.setProfileInfo(null);
                }} 
                disabled={props.selectedProfiles.length >= 5}
                className="ml-2 bg-dark-green-gradient from-darkGreen-start to-darkGreen-end text-white"
                size="sm"
              >
                <Plus className="h-4 w-4 mr-1" />
                <span className="text-xs">Confirm</span>
              </Button>
            </div>
          </div>
        )}
      </div>

      {props.selectedProfiles.length > 0 && (
        <>
          <p className="text-sm font-semibold text-muted-foreground">To track:</p>
          <div className="space-y-2">
            {props.selectedProfiles.map((profile, index) => (
              <div key={index} className="flex items-center justify-between">
                <TwitterHandle
                  handle={profile.handle}
                  displayName={profile.displayName}
                  profileImageUrl={profile.profileImageUrl}
                  showRemove={false}
                />
                <Button
                  onClick={() => props.removeSelectedProfile(index)}
                  variant="ghost"
                  size="sm"
                  className="text-muted-foreground hover:text-foreground"
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            ))}
          </div>
          <p className="text-xs text-muted-foreground">You can track up to 3 profiles at once!</p>
        </>
      )}

      <div className="space-y-2">
        <Label htmlFor="description" className="block">Let me know...</Label>
        <div className="relative">
          <Input 
            id="description" 
            placeholder={props.currentPlaceholderRef.current}
            className="h-24 w-full"
            value={props.inputDescription}
            onChange={props.handleDescriptionChange}
            multiline
          />
          <Button
            variant="outline"
            size="sm"
            className="absolute bottom-2 right-2 shadow-sm hover:shadow-md transition-shadow duration-200 bg-background"
            onClick={() => setShowInspiration(true)}
          >
            <Lightbulb className="h-4 w-4 mr-1.5" />
            <span className="text-xs">Give me ideas</span>
          </Button>
        </div>
      </div>

      <div className="space-y-2 w-full">
        <Label htmlFor="includeReplies" className="block">Track</Label>
        <ToggleGroup
          type="single"
          id="includeReplies"
          value={props.includeReplies}
          onValueChange={props.handleIncludeRepliesChange}
          className="w-full grid grid-cols-2 gap-2"
        >
          <ToggleGroupItem value="only-posts" aria-label="Only posts" className="w-full">
            Posts
          </ToggleGroupItem>
          <ToggleGroupItem value="posts-and-replies" aria-label="Posts and replies" className="w-full">
            Posts & replies
          </ToggleGroupItem>
        </ToggleGroup>
      </div>

      <div className="space-y-2 w-full">
        <Label className="block">Check every</Label>
        <ToggleGroup
          type="single"
          value={props.frequency}
          onValueChange={props.setFrequency}
          className="w-full grid grid-cols-3 gap-2"
        >
          <ToggleGroupItem value="Daily" aria-label="Daily" className="w-full">
            Day
          </ToggleGroupItem>
          <ToggleGroupItem value="Weekly" aria-label="Weekly" className="w-full">
            Week
          </ToggleGroupItem>
          <ToggleGroupItem value="Monthly" aria-label="Monthly" className="w-full">
            Month
          </ToggleGroupItem>
        </ToggleGroup>
      </div>

      <div className="relative group pt-[5px] pb-[5px] px-[5px]">
        {/* Outer outline */}
        <div className="absolute -inset-[0px] rounded-[12px] bg-gray-200/50 dark:bg-gray-700/50"></div>
        
        {/* Button with white border */}
        <Button 
          className="w-full h-12 relative rounded-[8px] border-[1px] border-white/80
            bg-[#2F9E44] text-white font-normal text-base
            hover:opacity-95 transition-all duration-200
            dark:border-[hsl(20_12%_16%)]"
          onClick={props.authenticated ? props.addOrUpdateReminder : props.safeLogin}
          disabled={props.isAddingReminder}
          variant="primary"
          data-add-reminder-button
        >
          <span style={{ fontFamily: 'DepartureMono, monospace' }} className="text-base">
            {props.authenticated 
              ? (props.isAddingReminder ? 'Unleashing the dogs...' : (props.editingReminder ? 'Update Tracker' : 'Create Tracker 🐶'))
              : 'Log in to Set Tracker'}
          </span>
        </Button>
      </div>

      {props.editingReminder && (
        <Button 
          className={`w-full mt-2 
            bg-gray-100 hover:bg-gray-200 
            dark:bg-gray-800 dark:hover:bg-gray-700
            text-gray-900 dark:text-gray-100
            border border-gray-200 dark:border-gray-700
            transition-colors duration-200
          `}
          onClick={() => {
            props.setEditingReminder(null);
            props.setInputDescription("");
            props.setSelectedProfiles([]);
            props.setIsEditingInDrawer(false);
            if (window.innerWidth < 1024) {
              props.setIsDrawerOpen(false);
            }
          }}
        >
          Cancel Edit
        </Button>
      )}

      <div className="mt-8 text-center text-sm text-muted-foreground">
        <p className="font-medium">Built by Dominic Emanuel Horn & Claude</p>
        <p className="mt-1">Feedback? <a 
          href="https://x.com/dmnc_eu" 
          target="_blank" 
          rel="noopener noreferrer"
          className="hover:text-foreground transition-colors"
        >Message me on X</a></p>
      </div>

      <InspirationDialog
        show={showInspiration}
        onClose={() => setShowInspiration(false)}
        onSelectPrompt={handleSelectPrompt}
        isMobile={isMobile}
      />
    </div>
  );
};

const Sidebar = ({ 
  isDarkMode, 
  toggleDarkMode, 
  authState, 
  safeLogin, 
  safeLogout, 
  authenticated, 
  isPushEnabled, 
  handleEnablePushNotifications, 
  showSubtext, 
  toggleSubtext,
  isAdmin,
  toggleAdminDashboard,
  // Add the props needed for sidebar content
  inputProfileToTrack,
  handleProfileToTrackChange,
  profileInfo,
  addProfile,
  selectedProfiles,
  removeSelectedProfile,
  inputDescription,
  handleDescriptionChange,
  includeReplies,
  handleIncludeRepliesChange,
  frequency,
  setFrequency,
  isAddingReminder,
  addOrUpdateReminder,
  editingReminder,
  setEditingReminder,
  setIsEditingInDrawer,
  setIsDrawerOpen,
  currentPlaceholderRef,
  setInputProfileToTrack,
  setProfileInfo,
  setInputDescription,
  setSelectedProfiles
}) => {


  return (
    <div className="w-full rounded-xl bg-gray-50 dark:bg-[hsl(20_12%_8%)] shadow-md border-[2px] border-white dark:border-[hsl(20_12%_16%)] flex flex-col overflow-y-auto">
      <div className="p-4">
        <div className="mb-4">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <img src="/truffle-logo.png" alt="Truffle Logo" className="w-8 h-8 mr-2" />
              <h1 className="text-2xl font-normal" style={{ 
                fontFamily: 'DepartureMono, monospace',
                color: '#B58E73'
              }}>Truffle</h1>
            </div>
            <div className="flex items-center space-x-2">
              {authState === 'authenticated' ? (
                <Button 
                  onClick={safeLogout} 
                  variant="secondaryToggle" 
                  size="icon"
                  className="h-10 w-10"
                  title="Log Out"
                >
                  <LogOut className="h-[1.2rem] w-[1.2rem]" />
                </Button>
              ) : (
                <Button 
                  onClick={safeLogin} 
                  variant="secondaryToggle"
                  className="h-10"
                >
                  Log In
                </Button>
              )}
              <Button 
                onClick={toggleDarkMode} 
                variant="secondaryToggle" 
                size="icon"
                className="h-10 w-10"
                title={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
              >
                {isDarkMode ? <Sun className="h-[1.2rem] w-[1.2rem]" /> : <Moon className="h-[1.2rem] w-[1.2rem]" />}
              </Button>
            </div>
          </div>
          
          {/* Admin button moved here, right after the header */}
          {authenticated && isAdmin === true && (
            <Button
              onClick={toggleAdminDashboard}
              variant="outline"
              size="sm"
              className="w-full mb-4"
            >
              <BarChart className="h-4 w-4 mr-2" />
              Admin Dashboard
            </Button>
          )}

          {authenticated && !isPushEnabled && (
            <Button 
              onClick={handleEnablePushNotifications}
              size="sm"
              variant="secondaryToggle"
              className="h-10 w-full"
            >
              <Bell className="h-4 w-4 mr-2" />
              Enable Push
            </Button>
          )}
        </div>

        {/* Sidebar content */}
        <div className="flex-grow overflow-y-auto w-full">
          <SidebarContent 
            inputProfileToTrack={inputProfileToTrack}
            handleProfileToTrackChange={handleProfileToTrackChange}
            profileInfo={profileInfo}
            addProfile={addProfile}
            selectedProfiles={selectedProfiles}
            removeSelectedProfile={removeSelectedProfile}
            inputDescription={inputDescription}
            handleDescriptionChange={handleDescriptionChange}
            includeReplies={includeReplies}
            handleIncludeRepliesChange={handleIncludeRepliesChange}
            frequency={frequency}
            setFrequency={setFrequency}
            isAddingReminder={isAddingReminder}
            addOrUpdateReminder={addOrUpdateReminder}
            editingReminder={editingReminder}
            setEditingReminder={setEditingReminder}
            setIsEditingInDrawer={setIsEditingInDrawer}
            setIsDrawerOpen={setIsDrawerOpen}
            currentPlaceholderRef={currentPlaceholderRef}
            setInputProfileToTrack={setInputProfileToTrack}
            setProfileInfo={setProfileInfo}
            setInputDescription={setInputDescription}
            setSelectedProfiles={setSelectedProfiles}
            authenticated={authenticated}
            safeLogin={safeLogin}
            showSubtext={showSubtext}
            toggleSubtext={toggleSubtext}
          />
        </div>
      </div>
    </div>
  );
};

// Export both the Sidebar and the content component
Sidebar.Content = SidebarContent;

export default Sidebar;
