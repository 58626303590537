import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';
import { useToast } from "./ui/use-toast";

// Make sure to use the correct publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
  stripeAccount: process.env.REACT_APP_STRIPE_ACCOUNT_ID, // Add if you have a connected account
  betas: ['elements_enable_localhost'], // Enable localhost testing
  apiVersion: '2023-10-16', // Use latest API version
  locale: 'auto',
  // Add cookie settings
  cookies: {
    secure: true,
    sameSite: 'None'
  }
});

function StripeDialog({ show, onClose, clientSecret, amount, onSuccess }) {
  const { toast } = useToast();

  // Don't render anything if not showing
  if (!show) return null;

  // Validate stripe initialization
  if (!stripePromise) {
    console.error('Stripe failed to initialize. Check REACT_APP_STRIPE_PUBLISHABLE_KEY');
    toast({
      title: "Error",
      description: "Payment system failed to initialize. Please try again.",
      variant: "destructive"
    });
    return null;
  }

  // Validate client secret
  if (!clientSecret) {
    console.error('No client secret provided to StripeDialog');
    return null;
  }

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#0A2540',
      },
    },
    loader: 'auto', // Add explicit loader option
    fonts: [{
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    }],
    // Add cookie settings
    cookies: {
      secure: true,
      sameSite: 'None'
    }
  };

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Complete Purchase</DialogTitle>
        </DialogHeader>
        <Elements stripe={stripePromise} options={options}>
          <StripeCheckoutForm 
            amount={amount} 
            onSuccess={onSuccess}
          />
        </Elements>
      </DialogContent>
    </Dialog>
  );
}

export default StripeDialog;
