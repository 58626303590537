import React from 'react';

const UpdateBanner = ({ newVersionAvailable, handleUpdate, isOnline }) => {
  return (
    <div className="lg:sticky lg:top-0 z-50">
      {newVersionAvailable && (
        <div className="bg-blue-500 text-white p-4 flex items-center justify-center" role="alert">
          <p className="font-medium mr-4">Fresh updates 🎉</p>
          <button 
            onClick={handleUpdate}
            className="bg-white text-blue-500 px-4 py-2 rounded hover:bg-blue-100 transition-colors"
          >
            Update Now
          </button>
        </div>
      )}
      {!isOnline && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
          <p className="font-medium">You are offline</p>
          <p>Some features may be unavailable until you reconnect.</p>
        </div>
      )}
    </div>
  );
};

export default UpdateBanner;
