import React from 'react';
import { Card, CardContent } from "./ui/card";
import TwitterHandle from './TwitterHandle';
import { formatDistanceToNow } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import { Button } from "./ui/button";
import { Switch } from "./ui/switch";
import CreditCostBadge from "./ui/credit-cost-badge";

const ExampleReminders = ({ onSignIn, isDarkMode }) => {
  const exampleTrackers = [
    {
      id: 'example1',
      profiles: [
        {
          handle: '@naval',
          displayName: 'Naval',
          profileImageUrl: 'https://pbs.twimg.com/profile_images/1256841238298292232/ycqwaMI2_400x400.jpg',
        },
        {
          handle: '@vitalikbuterin',
          displayName: 'Vitalik Buterin',
          profileImageUrl: 'https://pbs.twimg.com/profile_images/1748153260203229184/sXJIGMBk_400x400.jpg',
        }
      ],
      description: "Letting you know when they discuss AI and blockchain integration or crypto developments.",
      reformulatedPrompt: "Letting you know when they discuss AI and blockchain integration or crypto developments.",
      frequency: 'Daily',
      lastChecked: new Date().toISOString(),
      isActive: true,
      includeReplies: true,
      triggerEvents: [
        {
          id: 'truffle1',
          triggeredAt: new Date(Date.now() - 1000 * 60 * 30).toISOString(),
          analysis: "Naval and Vitalik just engaged in a fascinating discussion about AI's role in blockchain scalability and crypto governance. Key insights on decentralized AI systems and their integration with smart contracts. [Link↗](https://x.com/naval/status/1234567890)",
        }
      ],
      insights: [
        {
          content: "Analysis of recent AI-blockchain discussions reveals key patterns:\n\n• Growing focus on AI-powered smart contract optimization\n• Increased attention to decentralized AI governance\n• Emerging frameworks for AI-crypto integration\n• Evolution of privacy-preserving AI computation\n• Novel approaches to blockchain scalability using AI\n\nShows convergence of AI and blockchain technologies with emphasis on practical applications.",
          createdAt: new Date(Date.now() - 1000 * 60 * 60).toISOString(),
          tweetCount: 42,
        }
      ],
    },
    {
      id: 'example2',
      profiles: [
        {
          handle: '@KingJames',
          displayName: 'LeBron James',
          profileImageUrl: 'https://pbs.twimg.com/profile_images/1841648257719808001/hze5hlp__400x400.jpg',
        },
        {
          handle: '@StephenCurry30',
          displayName: 'Stephen Curry',
          profileImageUrl: 'https://pbs.twimg.com/profile_images/1826710994590334977/shbgXcDB_400x400.jpg',
        }
      ],
      description: "Letting you know when they discuss NBA playoffs and championship predictions.",
      reformulatedPrompt: "Letting you know when they discuss NBA playoffs and championship predictions.",
      frequency: 'Daily',
      lastChecked: new Date(Date.now() - 1000 * 60 * 45).toISOString(),
      isActive: true,
      includeReplies: true,
      triggerEvents: [
        {
          id: 'truffle2',
          triggeredAt: new Date(Date.now() - 1000 * 60 * 120).toISOString(),
          analysis: "Key discussion: LeBron and Steph share thoughts on playoff matchups and championship contenders. Fascinating insights on team strategies and playoff preparation. [Link↗](https://x.com/KingJames/status/2345678901)",
        }
      ],
      insights: [
        {
          content: "Recent analysis shows emerging patterns in playoff discussions:\n\n• Impact of new playoff format on team strategies\n• Evolution of offensive tactics in modern NBA\n• Key matchups that could decide championship\n• Load management considerations for stars\n• Importance of bench depth in playoffs\n\nReveals changing dynamics of championship-level basketball in today's NBA.",
          createdAt: new Date(Date.now() - 1000 * 60 * 90).toISOString(),
          tweetCount: 38,
        }
      ],
    },
    {
      id: 'example3',
      profiles: [
        {
          handle: '@kaitlancollins',
          displayName: 'Kaitlan Collins',
          profileImageUrl: 'https://pbs.twimg.com/profile_images/1240082910826377219/gE5NNsPH_400x400.jpg',
        },
        {
          handle: '@jaketapper',
          displayName: 'Jake Tapper',
          profileImageUrl: 'https://pbs.twimg.com/profile_images/1815523839834488834/RuEvvSVx_400x400.jpg',
        }
      ],
      description: "Letting you know when they discuss key developments in Trump's legal cases or campaign events.",
      reformulatedPrompt: "Letting you know when they discuss key developments in Trump's legal cases or campaign events.",
      frequency: 'Daily',
      lastChecked: new Date(Date.now() - 1000 * 60 * 15).toISOString(),
      isActive: true,
      includeReplies: false,
      triggerEvents: [
        {
          id: 'truffle3',
          triggeredAt: new Date(Date.now() - 1000 * 60 * 45).toISOString(),
          analysis: "Breaking: Collins and Tapper report major developments in Trump's legal cases, with new evidence presented in NY trial and updates on campaign strategy shifts. [Link↗](https://x.com/kaitlancollins/status/3456789012)",
        }
      ],
      insights: [
        {
          content: "Analysis of recent legal and campaign coverage reveals:\n\n• Increased focus on trial developments and evidence\n• Shifts in campaign messaging and strategy\n• Impact of legal proceedings on campaign\n• Evolution of media coverage patterns\n• Growing intersection of legal and political narratives\n\nShows complex interplay between ongoing legal cases and campaign dynamics.",
          createdAt: new Date(Date.now() - 1000 * 60 * 120).toISOString(),
          tweetCount: 50,
        }
      ],
    },
    {
      id: 'example4',
      profiles: [
        {
          handle: '@AOC',
          displayName: 'Alexandria Ocasio-Cortez',
          profileImageUrl: 'https://pbs.twimg.com/profile_images/923274881197895680/AbHcStkl_400x400.jpg',
        }
      ],
      description: "Letting you know when AOC discusses climate policy or green energy initiatives.",
      reformulatedPrompt: "Letting you know when AOC discusses climate policy or green energy initiatives.",
      frequency: 'Daily',
      lastChecked: new Date(Date.now() - 1000 * 60 * 20).toISOString(),
      isActive: true,
      includeReplies: true,
      triggerEvents: [
        {
          id: 'truffle4',
          triggeredAt: new Date(Date.now() - 1000 * 60 * 25).toISOString(),
          analysis: "AOC just announced a major push for new green energy legislation, highlighting job creation potential and environmental impact. Includes specific policy proposals for urban sustainability. [Link↗](https://x.com/AOC/status/2345678901)",
        }
      ],
      insights: [
        {
          content: "Recent climate policy communications show emerging focus:\n\n• Emphasis on green jobs and economic opportunities\n• New urban sustainability initiatives\n• Growing coalition for climate legislation\n• Increased attention to environmental justice\n• Strategic focus on local implementation\n\nShows evolution in climate policy approach combining economic and environmental priorities.",
          createdAt: new Date(Date.now() - 1000 * 60 * 90).toISOString(),
          tweetCount: 35,
        }
      ],
    },
    {
      id: 'example5',
      profiles: [
        {
          handle: '@KamalaHarris',
          displayName: 'Vice President Harris',
          profileImageUrl: 'https://pbs.twimg.com/profile_images/1592241313700782081/T2pTYU8d_400x400.jpg',
        }
      ],
      description: "Letting you know when VP Harris discusses voting rights or election integrity.",
      reformulatedPrompt: "Letting you know when VP Harris discusses voting rights or election integrity.",
      frequency: 'Daily',
      lastChecked: new Date(Date.now() - 1000 * 60 * 20).toISOString(),
      isActive: true,
      includeReplies: true,
      triggerEvents: [
        {
          id: 'truffle5',
          triggeredAt: new Date(Date.now() - 1000 * 60 * 25).toISOString(),
          analysis: "VP Harris announces new initiatives to protect voting rights and enhance election security, including partnerships with state officials and community organizations. [Link↗](https://x.com/KamalaHarris/status/4567890123)",
        }
      ],
      insights: [
        {
          content: "Analysis of recent voting rights messaging reveals:\n\n• Enhanced focus on election security measures\n• New state-level partnerships and initiatives\n• Increased emphasis on voter education\n• Strategic approach to addressing voting barriers\n• Growing coalition building efforts\n\nShows evolution in voting rights protection strategy.",
          createdAt: new Date(Date.now() - 1000 * 60 * 180).toISOString(),
          tweetCount: 38,
        }
      ],
    },
    {
      id: 'example6',
      profiles: [
        {
          handle: '@BillGates',
          displayName: 'Bill Gates',
          profileImageUrl: 'https://pbs.twimg.com/profile_images/1674815862879178752/nTGMV1Eo_400x400.jpg',
        },
        {
          handle: '@GretaThunberg',
          displayName: 'Greta Thunberg',
          profileImageUrl: 'https://pbs.twimg.com/profile_images/1459213153301053442/rL5hhpAI_400x400.jpg',
        }
      ],
      description: "Letting you know when they discuss climate innovation or environmental activism.",
      reformulatedPrompt: "Letting you know when they discuss climate innovation or environmental activism.",
      frequency: 'Weekly',
      lastChecked: new Date(Date.now() - 1000 * 60 * 10).toISOString(),
      isActive: true,
      includeReplies: false,
      triggerEvents: [
        {
          id: 'truffle6',
          triggeredAt: new Date(Date.now() - 1000 * 60 * 60).toISOString(),
          analysis: "Gates shares breakthrough in clean energy technology while Thunberg mobilizes youth climate action. Fascinating intersection of innovation and activism. [Link↗](https://x.com/BillGates/status/5678901234)",
        }
      ],
      insights: [
        {
          content: "Analysis reveals complementary approaches to climate action:\n\n• Convergence of technological and grassroots solutions\n• Growing focus on scalable clean energy innovations\n• Increased youth engagement in climate initiatives\n• Balance of immediate action and long-term solutions\n• Evolution of climate communication strategies\n\nShows emerging synthesis of innovation and activism in climate movement.",
          createdAt: new Date(Date.now() - 1000 * 60 * 240).toISOString(),
          tweetCount: 42,
        }
      ],
    }
  ];

  const renderTruffle = (event, isDarkMode) => (
    <div 
      key={event.id} 
      className={`mt-2 rounded-lg ${
        isDarkMode 
          ? 'bg-[hsl(20_12%_12%)] text-[hsl(20_10%_90%)]' 
          : 'bg-white text-gray-800'
      }`}
    >
      <div className={`p-2 flex flex-wrap items-center justify-between`}>
        <p className="text-sm font-semibold flex items-center mr-2">
          <span className="text-amber-600 dark:text-amber-500 mr-2">🍄‍🟫</span>
          Found truffle!
        </p>
        <p className="text-sm">{formatDistanceToNow(new Date(event.triggeredAt), { addSuffix: true })}</p>
      </div>
      <div className="p-2 pt-0">
        <ReactMarkdown 
          className={`text-sm prose prose-sm max-w-none font-sans ${
            isDarkMode ? 'text-[hsl(20_10%_90%)]' : 'text-gray-800'
          }`}
          components={{
            p: ({node, ...props}) => <p className="font-sans mb-2" {...props} />,
            a: ({node, ...props}) => <a target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline" {...props} />,
          }}
        >
          {event.analysis}
        </ReactMarkdown>
      </div>
    </div>
  );

  const renderInsight = (insight, isDarkMode) => (
    <div 
      key={insight.createdAt} 
      className={`mt-2 rounded-lg ${
        isDarkMode 
          ? 'bg-[hsl(20_12%_14%)] text-[hsl(20_10%_90%)]' 
          : 'bg-indigo-50 text-indigo-800'
      }`}
    >
      <div className={`p-2 flex flex-wrap items-center justify-between`}>
        <p className="text-sm font-semibold flex items-center mr-2">
          <span className="text-blue-600 dark:text-blue-500 mr-2">💡</span>
          Insight!
        </p>
        <p className="text-sm">
          Based on last {insight.tweetCount} posts
        </p>
      </div>
      <div className="p-2 pt-0">
        <ReactMarkdown 
          className={`text-sm prose prose-sm max-w-none font-sans ${
            isDarkMode ? 'text-[hsl(20_10%_90%)]' : 'text-indigo-800'
          }`}
          components={{
            p: ({node, ...props}) => <p className="font-sans mb-2" {...props} />,
            li: ({node, ...props}) => <li className="font-sans list-disc ml-4" {...props} />,
            ul: ({node, ...props}) => <ul className="mb-2" {...props} />,
          }}
        >
          {insight.content}
        </ReactMarkdown>
      </div>
    </div>
  );

  const getCreditCost = (profiles) => profiles.length * 10;

  return (
    <div className="space-y-4">
      {exampleTrackers.map((tracker) => {
        const creditCost = getCreditCost(tracker.profiles);
        
        return (
          <div key={tracker.id} className="relative group">
            <Card className={`
              border-white border-[2px] dark:border-[hsl(20_12%_16%)] rounded-xl overflow-hidden 
              bg-gray-50 dark:bg-[hsl(20_12%_8%)] ${!tracker.isActive ? 'opacity-50' : ''} 
              shadow-md
            `}>
              <div className="bg-gray-100 dark:bg-[hsl(20_12%_10%)] p-0">
                <div className="flex flex-wrap gap-2 p-2">
                  {tracker.profiles.map((profile, index) => (
                    <TwitterHandle
                      key={index}
                      handle={profile.handle}
                      displayName={profile.displayName}
                      profileImageUrl={profile.profileImageUrl}
                      showRemove={false}
                      isOnCard={true}
                    />
                  ))}
                </div>
                <div className="w-full border-gray-200 dark:border-[hsl(20_12%_14%)]"></div>
              </div>
              <CardContent className="p-2 sm:p-3">
                <div className="flex justify-between items-center mb-1">
                  <p className="text-xl font-semibold text-card-foreground">{tracker.description}</p>
                  <Switch checked={tracker.isActive} className="ml-2 flex-shrink-0" />
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-normal">{creditCost} credits</span> · Check {tracker.frequency.toLowerCase()} · 
                  {tracker.includeReplies ? " Posts & replies" : " Posts only"} ·
                  Last checked {formatDistanceToNow(new Date(tracker.lastChecked), { addSuffix: true })}
                </p>
                {tracker.triggerEvents.map(event => renderTruffle(event, isDarkMode))}
                {tracker.insights.map(insight => renderInsight(insight, isDarkMode))}
              </CardContent>
            </Card>
            <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl">
              <div className="text-center">
                <p className="text-white text-lg mb-4">Get started with 1000 free credits</p>
                <Button onClick={onSignIn} variant="primary">Sign In</Button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ExampleReminders;
