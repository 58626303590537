import { memo, useMemo } from 'react'
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts'
import { format, parseISO, subDays } from 'date-fns'
import { CustomTooltip, TimelineTooltip } from './shared/CustomTooltip'

function TemporalChart({ 
  patterns,
  className = '',
  height = 200 
}) {
  const isMobile = window.innerWidth < 640

  // Add debug logging
  console.log('TemporalChart patterns:', patterns);

  const formattedData = useMemo(() => {
    // Ensure data is sorted by date
    return [...patterns].sort((a, b) => 
      new Date(a.period).getTime() - new Date(b.period).getTime()
    )
  }, [patterns])

  // Calculate domain for x-axis
  const xDomain = useMemo(() => {
    if (!patterns.length) return [subDays(new Date(), 30), new Date()]
    const dates = patterns.map(p => new Date(p.period))
    return [Math.min(...dates), Math.max(...dates)]
  }, [patterns])

  return (
    <div className={`w-full ${className}`} style={{ height }}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart 
          data={formattedData} 
          margin={{ 
            top: 10, 
            right: isMobile ? 10 : 30, 
            left: isMobile ? -20 : 0, 
            bottom: 0 
          }}
        >
          <defs>
            <linearGradient id="activityGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="hsl(var(--primary))" stopOpacity={0.3} />
              <stop offset="95%" stopColor="hsl(var(--primary))" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" className="stroke-muted/20" />
          <XAxis 
            dataKey="period"
            domain={xDomain}
            tickFormatter={str => format(parseISO(str), isMobile ? 'MMM d' : 'MMM d, yyyy')}
            className="text-xs"
            tick={{ 
              fill: 'currentColor',
              fontSize: isMobile ? 10 : 12 
            }}
            tickLine={{ stroke: 'currentColor' }}
            interval={isMobile ? 'preserveStartEnd' : 0}
          />
          <YAxis 
            className="text-xs"
            tick={{ 
              fill: 'currentColor',
              fontSize: isMobile ? 10 : 12 
            }}
            tickLine={{ stroke: 'currentColor' }}
            tickFormatter={value => Math.round(value)}
            width={isMobile ? 30 : 40}
          />
          <Tooltip content={<TimelineTooltip />} />
          <Area
            type="monotone"
            dataKey="activity"
            stroke="hsl(var(--primary))"
            fill="url(#activityGradient)"
            strokeWidth={2}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default memo(TemporalChart)
