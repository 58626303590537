import React, { useCallback, useState, useEffect, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button } from "./ui/button";
import { Layout, User, MessageSquare, LineChart, TrendingUp, Camera, Quote, RefreshCw, Trash2, Plus, Sparkles, Clock, Loader2 } from 'lucide-react';
import ProfileAnalysisSkeleton from './ProfileAnalysisSkeleton';
import { useToast } from "./ui/use-toast";
import TwitterHandle from './TwitterHandle';
import { Card } from "./ui/card";
import { useMediaQuery } from '../hooks/useMediaQuery';
import html2canvas from 'html2canvas';
import AlignmentChart from './charts/AlignmentChart'
import TopicsChart from './charts/TopicsChart'
import TemporalChart from './charts/TemporalChart'
import * as Tabs from "@radix-ui/react-tabs"
import { cn } from '../lib/utils';
import CreditCostBadge from "./ui/credit-cost-badge"
import { useProfile } from '../contexts/ProfileContext';
import { CREDIT_COSTS } from '../constants/credits';
import { Info } from 'lucide-react';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "./ui/tooltip";

// Create styled tab components
const TabsRoot = Tabs.Root;
const TabsList = Tabs.List;
const TabsTrigger = Tabs.Trigger;
const TabsContent = Tabs.Content;

// Update the QuoteCard component to use theme colors
function QuoteCard({ quote, author, isDarkMode }) {
  return (
    <div className="relative p-6 rounded-xl bg-card border shadow-sm">
      <Quote className="absolute top-4 left-4 h-6 w-6 text-primary/30" />
      <div className="pl-8">
        <p className="text-lg font-medium text-foreground">{quote}</p>
      </div>
    </div>
  )
}

// Update the SectionCard component
function SectionCard({ title, content, isDarkMode }) {
  return (
    <Card className="overflow-hidden rounded-lg border border-border/50 hover:border-border transition-colors duration-200">
      <div className="p-4">
        <h3 className="text-base font-semibold mb-2 text-foreground/90">{title}</h3>
        <div className="prose dark:prose-invert max-w-none prose-sm">
          <ReactMarkdown
            components={{
              p: ({ children }) => <p className="text-sm text-muted-foreground">{children}</p>,
              ul: ({ children }) => <ul className="space-y-2 text-sm">{children}</ul>,
              li: ({ children }) => (
                <li className="text-muted-foreground flex items-start gap-2">
                  <span className="mt-1.5 h-1.5 w-1.5 rounded-full bg-primary/50 flex-shrink-0" />
                  <span className="flex-1">{children}</span>
                </li>
              ),
            }}
          >
            {content}
          </ReactMarkdown>
        </div>
      </div>
    </Card>
  )
}

// Add calculateLevel function at the top with other utility functions
const calculateLevel = (tweets = [], insights = []) => {
  const totalItems = tweets.length + insights.length;
  if (totalItems >= 200) return 3;
  if (totalItems >= 100) return 2;
  return 1;
};

const ProfileDetails = ({
  profile,
  profileId,
  handleDeleteProfile,
  showDeleteConfirmation,
  setShowDeleteConfirmation,
  renderPromptTable,
  credits,
  deductCredits,
  hasEnoughCredits,
  isDarkMode,
  onAddProfile,
  currentTrackedProfiles
}) => {
  const { 
    profileAnalyses,
    analysisLoadingStates,
    generateAnalysis,
    regenerateAnalysis
  } = useProfile();

  // IMPORTANT: Check both sources for analysis
  const analysis = useMemo(() => {
    // First check the profile object
    if (profile?.analysis) {
      return profile.analysis;
    }
    // Fallback to context if not in profile
    return profileAnalyses[profileId];
  }, [profile?.analysis, profileAnalyses, profileId]);

  const loadingState = useMemo(() => analysisLoadingStates[profileId] || {}, [analysisLoadingStates, profileId]);
  const [isDeleting, setIsDeleting] = useState(false);
  const { toast } = useToast();

  // Calculate level from profile data
  const level = useMemo(() => calculateLevel(
    profile?.tweets || [],
    profile?.insights || []
  ), [profile?.tweets, profile?.insights]);

  // Add debug logging
  useEffect(() => {
    console.log('ProfileDetails mounted:', {
      profileId,
      hasProfile: !!profile,
      profileFields: profile ? Object.keys(profile) : [],
      hasAnalysis: !!profile?.analysis,
      analysisFromContext: !!analysis,
      analysisFields: analysis ? Object.keys(analysis) : [],
      profileAnalyses: Object.keys(profileAnalyses)
    });
  }, [profile, profileId, analysis, profileAnalyses]);

  // Move all useEffect hooks to the top
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .analysis-enter {
        opacity: 0;
        transform: translateY(10px);
      }
      .analysis-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 300ms, transform 300ms;
      }
      .analysis-exit {
        opacity: 1;
      }
      .analysis-exit-active {
        opacity: 0;
        transition: opacity 300ms;
      }

      .content-transition-enter {
        opacity: 0;
        transform: translateY(10px);
      }
      .content-transition-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 300ms, transform 300ms;
      }
      .content-transition-exit {
        opacity: 1;
      }
      .content-transition-exit-active {
        opacity: 0;
        transition: opacity 300ms;
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  // Helper functions
  const parseAnalysisContent = useCallback((analysis) => {
    console.log('Parsing analysis:', {
      hasAnalysis: !!analysis,
      analysisFields: analysis ? Object.keys(analysis) : [],
      hasContent: !!analysis?.content
    });

    if (!analysis || !analysis.content) return { sections: [], quotes: [] };

    const sections = analysis.content.split(/(?=###\s)/).filter(Boolean).map(section => {
      const [title, ...contentParts] = section.split('\n');
      return {
        title: title.replace('### ', ''),
        content: contentParts.join('\n').trim()
      }
    });

    const quotes = analysis.content.match(/[""]([^""]+)[""]/g)?.map(quote => ({
      text: quote.replace(/[""]/g, ''),
      author: profile.displayName
    })) || [];

    return { sections, quotes };
  }, [profile.displayName]);

  const captureSection = useCallback(async (elementId) => {
    try {
      const element = document.getElementById(elementId);
      if (!element) return;

      const canvas = await html2canvas(element, {
        backgroundColor: isDarkMode ? '#1a1a1a' : '#ffffff',
        scale: 2,
        logging: false,
        useCORS: true
      });

      const link = document.createElement('a');
      link.download = `${profile.handle}-${elementId}.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();

      toast({
        title: "Screenshot captured!",
        description: "Your image has been downloaded.",
        duration: 3000
      });
    } catch (error) {
      console.error('Error capturing screenshot:', error);
      toast({
        title: "Screenshot failed",
        description: "Failed to capture screenshot. Please try again.",
        variant: "destructive"
      });
    }
  }, [isDarkMode, profile.handle, toast]);

  const handleQuickStartPersona = async () => {
    const operation = async () => {
      try {
        const data = await generateAnalysis(profileId);
        return data;
      } catch (error) {
        console.error('Error in quick start:', error);
        toast({
          title: "Error",
          description: "Failed to generate persona. Please try again.",
          variant: "destructive"
        });
        throw error;
      }
    };

    return await deductCredits(CREDIT_COSTS.GENERATE_PERSONA, operation);
  };

  const handleReloadProfile = async () => {
    const operation = async () => {
      try {
        const data = await regenerateAnalysis(profileId);
        return data;
      } catch (error) {
        console.error('Error in reload:', error);
        toast({
          title: "Error",
          description: "Failed to regenerate persona. Please try again.",
          variant: "destructive"
        });
        throw error;
      }
    };

    return await deductCredits(CREDIT_COSTS.REGENERATE_PERSONA, operation);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await handleDeleteProfile();
    } finally {
      setIsDeleting(false);
    }
  };

  // Compute classes before any returns
  const contentClasses = cn(
    "transition-all duration-300",
    {
      "opacity-0 translate-y-4": loadingState.isGenerating || loadingState.isRegenerating,
      "opacity-100 translate-y-0": !loadingState.isGenerating && !loadingState.isRegenerating
    }
  );

  // Now we can have our conditional returns
  if (!analysis && !loadingState.isGenerating && !loadingState.isRegenerating) {
    console.log('Showing empty state:', {
      hasAnalysis: !!analysis,
      hasProfileAnalysis: !!profile?.analysis,
      hasContextAnalysis: !!profileAnalyses[profileId],
      loadingState
    });

    return (
      <div className="space-y-6">
        {/* Profile Header */}
        <div className="space-y-4">
          <div className="inline-flex-wrap items-center justify-between w-full">
            <div className="flex justify-between items-center w-full">
              <TwitterHandle
                handle={profile.handle}
                displayName={profile.displayName}
                profileImageUrl={profile.profileImageUrl}
                showRemove={false}
                isOnCard={true}
                onAdd={onAddProfile}
                currentTrackedProfiles={currentTrackedProfiles}
              />
              {/* Add delete button */}
              <Button
                onClick={() => setShowDeleteConfirmation(true)}
                size="sm"
                variant="delete"
                className="text-xs whitespace-nowrap"
              >
                <Trash2 className="h-4 w-4 mr-1" />
                Delete Profile
              </Button>
            </div>
          </div>
        </div>

        {/* Generate Button Section */}
        <div className="flex flex-col items-center justify-center py-12 space-y-4">
          <div className="text-center space-y-2 mb-6">
            <h3 className="text-lg font-semibold">No Persona Generated Yet</h3>
            <p className="text-sm text-muted-foreground">
              Create an AI-powered analysis of this profile's content and behavior.
            </p>
          </div>
          
          <Button
            onClick={handleQuickStartPersona}
            disabled={credits < CREDIT_COSTS.GENERATE_PERSONA || loadingState.isGenerating}
            size="lg"
            className="w-full max-w-sm"
          >
            {loadingState.isGenerating ? (
              <>
                <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                Generating...
              </>
            ) : (
              <>
                <Sparkles className="h-4 w-4 mr-2" />
                Generate Persona
                <CreditCostBadge cost={CREDIT_COSTS.GENERATE_PERSONA} variant="primary" />
              </>
            )}
          </Button>
          
          {credits < CREDIT_COSTS.GENERATE_PERSONA && (
            <p className="text-sm text-destructive">
              Insufficient credits. You need {CREDIT_COSTS.GENERATE_PERSONA} credits to generate a Persona.
            </p>
          )}
        </div>
      </div>
    );
  }

  if (loadingState.isGenerating || loadingState.isRegenerating) {
    return (
      <div className="space-y-6">
        {/* Keep the header visible during loading */}
        <div className="space-y-4">
          <div className="inline-flex-wrap items-center justify-between">
            <TwitterHandle
              handle={profile.handle}
              displayName={profile.displayName}
              profileImageUrl={profile.profileImageUrl}
              showRemove={false}
              isOnCard={true}
            />
          </div>
          <p className="text-sm text-muted-foreground">
            Generating AI Persona analysis...
          </p>
        </div>
        
        <ProfileAnalysisSkeleton isDarkMode={isDarkMode} />
      </div>
    );
  }

  const { sections, quotes } = parseAnalysisContent(analysis);

  // Add debug logging
  console.log('Profile Analysis Data:', {
    hasTopics: !!analysis?.topics,
    topicsLength: analysis?.topics?.length,
    topicsExample: analysis?.topics?.[0],
  });

  const tabs = [
    { id: 'overview', label: 'Overview', icon: Layout },
    { id: 'personality', label: 'Personality', icon: User },
    { id: 'needs', label: 'Needs', icon: MessageSquare },
    { id: 'patterns', label: 'Patterns', icon: LineChart },
    { id: 'predictions', label: 'Predictions', icon: TrendingUp },
    { id: 'history', label: 'History', icon: Clock } // Add new tab
  ]

  // Group sections into tabs
  const sectionGroups = {
    overview: ['Overview', 'Influence'],
    personality: ['Mindset', 'Insights', 'Communication'],
    needs: ['Needs', 'Pains', 'Opportunities'],
    patterns: ['What you can learn from them', 'Network'],
    predictions: ['Predictions', 'Actions'],
    history: [] // Empty array since this tab has special content
  }

  // At the start of the component
  if (!analysis) {
    return (
      <div className="p-4">
        <p className="text-muted-foreground">Loading analysis...</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Profile Header */}
      <div className="space-y-4">
        {/* Make header more responsive */}
        <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-0 w-full">
          <div className="flex items-center flex-1">
            <TwitterHandle
              handle={profile.handle}
              displayName={profile.displayName}
              profileImageUrl={profile.profileImageUrl}
              showRemove={false}
              isOnCard={false}
              onAdd={onAddProfile}
              currentTrackedProfiles={currentTrackedProfiles}
              isClickable={true}
            />
            {/* Level Badge - Fixed dark theme color */}
            <div className={cn(
              "ml-3 px-2 py-0.5 rounded-full text-xs", 
              "bg-gradient-to-r shadow-sm",
              isDarkMode 
                ? "from-[#ff5d0a]/20 via-[#ff5d0a]/10 to-transparent text-[#ff5d0a]" 
                : "from-[#0066ff]/20 via-[#0066ff]/10 to-transparent text-[#0066ff]"
            )}>
              Lvl {level || 1}
            </div>
            {/* Add Info Tooltip */}
            <TooltipProvider>
              <Tooltip delayDuration={200} defaultOpen={false}>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="ml-2 h-6 w-6 p-0 hover:bg-transparent"
                    tabIndex={-1}
                  >
                    <Info className="h-4 w-4 text-muted-foreground" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent 
                  side="bottom"
                  align="start"
                  className="max-w-[300px] p-4 bg-white/95 text-black border-0 shadow-lg dark:bg-black/95 dark:text-white"
                >
                  <p className="text-sm">
                    This Persona has been automatically generated by AI based on the posts and insights we have. 
                    When your Persona levels up, we automatically generate a new Persona for free, improving it with each level.
                    Always double check, AI can make mistakes.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <div className="flex items-center gap-2 self-end sm:self-auto">
            {analysis && (
              <Button
                onClick={handleReloadProfile}
                disabled={credits < CREDIT_COSTS.REGENERATE_PERSONA || loadingState.isRegenerating}
                size="sm"
                variant="outline"
                className="text-xs"
              >
                <RefreshCw className={cn("h-3 w-3 mr-1", { "animate-spin": loadingState.isRegenerating })} />
                {loadingState.isRegenerating ? "Regenerating..." : "Regenerate"}
                <CreditCostBadge cost={CREDIT_COSTS.REGENERATE_PERSONA} />
              </Button>
            )}
            <Button
              onClick={() => setShowDeleteConfirmation(true)}
              size="sm"
              variant="delete"
              className="text-xs whitespace-nowrap"
            >
              <Trash2 className="h-4 w-4 mr-1" />
              Delete Profile
            </Button>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteConfirmation && (
        <div className="absolute inset-0 bg-background/80 backdrop-blur-sm z-50 flex items-center justify-center">
          <div className="bg-card p-6 rounded-lg shadow-lg max-w-md mx-4">
            <h3 className="text-lg font-semibold mb-2">Delete Persona?</h3>
            <p className="text-muted-foreground mb-4">
              This will permanently delete this persona and all associated data. This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-2">
              <Button
                variant="outline"
                onClick={() => setShowDeleteConfirmation(false)}
              >
                Cancel
              </Button>
              <Button
                variant="delete"
                onClick={handleDelete}
                size="sm"
                disabled={isDeleting}
                className="text-xs whitespace-nowrap"
              >
                {isDeleting ? (
                  <>
                    <Loader2 className="h-4 w-4 mr-1 animate-spin" />
                    Deleting...
                  </>
                ) : (
                  <>
                    <Trash2 className="h-4 w-4 mr-1" />
                    Confirm Delete
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Tabbed Content */}
      <TabsRoot defaultValue="overview" className="w-full">
        <TabsList className="flex border-b mb-6 overflow-x-auto no-scrollbar">
          {tabs.map(tab => (
            <TabsTrigger
              key={tab.id}
              value={tab.id}
              className="flex items-center gap-2 px-2 py-2 text-sm font-medium whitespace-nowrap transition-colors
                hover:text-primary data-[state=active]:text-primary data-[state=active]:border-b-2 
                data-[state=active]:border-primary min-w-fit"
            >
              <tab.icon className="h-4 w-4" />
              <span className="inline">{tab.label}</span>
            </TabsTrigger>
          ))}
        </TabsList>

        {/* Content with transitions */}
        <div className={contentClasses}>
          {loadingState.isGenerating || loadingState.isRegenerating ? (
            <ProfileAnalysisSkeleton isDarkMode={isDarkMode} />
          ) : (
            <>
              {/* Overview Tab with Charts */}
              <TabsContent value="overview" className="space-y-12">
                {/* Featured Quotes */}
                <div className="space-y-6">
                  {quotes.length > 0 && (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      {quotes.slice(0, 2).map((quote, index) => (
                        <QuoteCard
                          key={index}
                          quote={quote.text}
                          author={quote.author}
                          isDarkMode={isDarkMode}
                        />
                      ))}
                    </div>
                  )}

                  {/* Overview Text Sections */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {sections
                      .filter(section => sectionGroups.overview.includes(section.title))
                      .map((section, index) => (
                        <div key={index} id={`section-overview-${index}`} className="relative group">
                          <SectionCard
                            title={section.title}
                            content={section.content}
                            isDarkMode={isDarkMode}
                          />
                          <Button
                            onClick={() => captureSection(`section-overview-${index}`)}
                            className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                            size="sm"
                            variant="ghost"
                          >
                            <Camera className="h-4 w-4" />
                          </Button>
                        </div>
                      ))}
                  </div>
                </div>

                {/* Charts Section */}
                <div className="space-y-8 sm:space-y-12">
                  {/* Content Alignment Chart */}
                  {analysis?.overview?.metrics && (
                    <div className="space-y-4">
                      <h3 className="text-lg font-semibold">Content Alignment</h3>
                      <div className="p-2 bg-card rounded-lg shadow-sm overflow-hidden">
                        <AlignmentChart 
                          metrics={analysis.overview.metrics}
                          height={300}
                          className="sm:-mx-4"
                        />
                      </div>
                    </div>
                  )}

                  {/* Key Topics Chart */}
                  {analysis?.topics && Array.isArray(analysis.topics) && analysis.topics.length > 0 && (
                    <div className="space-y-4">
                      <h3 className="text-lg font-semibold">Key Topics</h3>
                      <TopicsChart 
                        topics={analysis.topics}
                        height={300}
                      />
                    </div>
                  )}

                  {/* Activity Timeline Chart */}
                  {analysis?.temporal?.patterns && analysis.temporal.patterns.length > 0 && (
                    <div className="space-y-4">
                      <h3 className="text-lg font-semibold">Activity Timeline</h3>
                      <div className="p-2 bg-card rounded-lg shadow-sm overflow-hidden">
                        <TemporalChart 
                          patterns={analysis.temporal.patterns}
                          height={200}
                          className="sm:-mx-4"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </TabsContent>

              {/* Other Tabs */}
              {tabs.filter(tab => tab.id !== 'overview').map(tab => (
                <TabsContent key={tab.id} value={tab.id} className="mt-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {sections
                      .filter(section => sectionGroups[tab.id].includes(section.title))
                      .map((section, index) => (
                        <div key={index} id={`section-${tab.id}-${index}`} className="relative group">
                          <SectionCard
                            title={section.title}
                            content={section.content}
                            isDarkMode={isDarkMode}
                          />
                          <Button
                            onClick={() => captureSection(`section-${tab.id}-${index}`)}
                            className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                            size="sm"
                            variant="ghost"
                          >
                            <Camera className="h-4 w-4" />
                          </Button>
                        </div>
                      ))}
                  </div>
                </TabsContent>
              ))}

              {/* History Tab */}
              <TabsContent value="history" className="mt-6">
                <div className="space-y-4">
                  {renderPromptTable ? renderPromptTable() : (
                    <p className="text-muted-foreground">No tracking history available</p>
                  )}
                </div>
              </TabsContent>
            </>
          )}
        </div>
      </TabsRoot>
    </div>
  );
};

export default ProfileDetails;
