import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export function formatCompactNumber(num) {
  if (!num) return '0'
  
  const formatter = Intl.NumberFormat('en', { notation: 'compact' })
  return formatter.format(num)
}