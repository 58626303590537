import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "./ui/sheet";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { CreditCard, Bitcoin, Coins, Loader2, Sparkles } from 'lucide-react';
import StripeDialog from './StripeDialog';
import { useToast } from "./ui/use-toast";
import { ethers } from 'ethers';
import PaymentABI from '../contracts/PaymentABI.json';
import { usePrivy } from '@privy-io/react-auth';
import { useMediaQuery } from "../hooks/useMediaQuery";

const isDev = process.env.NODE_ENV === 'development'

async function ensureUPConnection() {
  if (!window.lukso) {
    throw new Error('LUKSO extension not found. Please install the UP Browser Extension.');
  }

  try {
    const accounts = await window.lukso.request({ 
      method: 'eth_requestAccounts' 
    });
    
    if (!accounts || accounts.length === 0) {
      throw new Error('No UP account connected. Please connect your Universal Profile.');
    }
    
    return accounts[0];
  } catch (error) {
    console.error('UP connection error:', error);
    if (error.code === 4001) {
      throw new Error('Please connect your Universal Profile to continue.');
    }
    throw new Error('Failed to connect UP account. Please try again.');
  }
}

function BuyCreditsDialog({
  show,
  onClose,
  usdcAmount,
  onUsdcAmountChange,
  onBuyWithCrypto,
  onBuyWithStripe
}) {
  const [stripeClientSecret, setStripeClientSecret] = useState(null);
  const [showStripeDialog, setShowStripeDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [lyxAmount, setLyxAmount] = useState(null);
  const { toast } = useToast();
  const { user } = usePrivy();
  const [selectedPack, setSelectedPack] = useState(null)
  const isMobile = useMediaQuery("(max-width: 640px)")

  // Add defaultPack constant
  const defaultPack = 'pro'

  // Credit pack definitions
  const creditPacks = [
    {
      id: 'starter',
      name: 'Starter Pack',
      credits: 1000,
      bonus: 0,
      price: 10,
      popular: false,
      gradient: 'from-blue-500/20 to-purple-500/20 dark:from-blue-900/30 dark:to-purple-900/30'
    },
    {
      id: 'pro',
      name: 'Pro Pack',
      credits: 5000,
      bonus: 500,
      price: 45,
      popular: true,
      gradient: 'from-emerald-500/20 to-cyan-500/20 dark:from-emerald-900/30 dark:to-cyan-900/30'
    },
    {
      id: 'unlimited',
      name: 'Power Pack',
      credits: 12000,
      bonus: 2000,
      price: 99,
      popular: false,
      gradient: 'from-orange-500/20 to-pink-500/20 dark:from-orange-900/30 dark:to-pink-900/30'
    }
  ]

  useEffect(() => {
    if (!show) {
      setStripeClientSecret(null);
      setShowStripeDialog(false);
      setIsProcessing(false);
      setLyxAmount(null);
    }
  }, [show]);

  useEffect(() => {
    if (selectedPack) {
      onUsdcAmountChange({ target: { value: selectedPack.price } })
    }
  }, [selectedPack])

  // Add useEffect to set default pack when dialog opens
  useEffect(() => {
    if (show) {
      const pack = creditPacks.find(p => p.id === defaultPack)
      if (pack) {
        setSelectedPack(pack)
        onUsdcAmountChange({ target: { value: pack.price } })
      }
    } else {
      setSelectedPack(null) // Reset when dialog closes
    }
  }, [show])

  // Get required LYX amount from contract
  const getLYXAmount = async () => {
    try {
      console.log('Getting LYX amount for USD:', usdcAmount);
      const contractAddress = window.env.REACT_APP_PAYMENT_CONTRACT;
      console.log('Using contract address:', contractAddress);
      
      const provider = new ethers.providers.JsonRpcProvider(window.env.REACT_APP_LUKSO_RPC);
      console.log('Connected to LUKSO RPC:', window.env.REACT_APP_LUKSO_RPC);
      
      const contract = new ethers.Contract(
        contractAddress,
        PaymentABI,
        provider
      );
      console.log('Contract initialized');

      // Verify contract exists
      const code = await provider.getCode(contractAddress);
      console.log('Contract code exists:', code !== '0x');

      // Call the function
      console.log('Calling calculateLYXAmount with:', ethers.utils.parseUnits(usdcAmount.toString(), 18).toString());
      const amount = await contract.calculateLYXAmount(
        ethers.utils.parseUnits(usdcAmount.toString(), 18)
      );
      console.log('Calculated LYX amount:', ethers.utils.formatEther(amount));
      
      const formattedAmount = Number(ethers.utils.formatEther(amount)).toFixed(2);
      setLyxAmount(formattedAmount);
    } catch (error) {
      console.error('Error getting LYX amount:', error);
      if (error.error) {
        console.error('Error details:', {
          reason: error.error.reason,
          code: error.error.code,
          body: error.error.body,
          method: error.error.requestMethod,
          url: error.error.url
        });
      }
      toast({
        title: "Error",
        description: "Failed to get LYX price. Please try again.",
        variant: "destructive"
      });
    }
  };

  useEffect(() => {
    if (show && usdcAmount > 0) {
      getLYXAmount();
    }
  }, [usdcAmount, show]);

  async function handleLYXPayment() {
    try {
      setIsProcessing(true);
      
      // Connect UP only when user wants to pay
      const account = await ensureUPConnection();
      
      // Initialize provider and contract
      const provider = new ethers.providers.Web3Provider(window.lukso);
      const signer = provider.getSigner();
      
      // Verify the connection
      const connectedAddress = await signer.getAddress();
      if (!connectedAddress) {
        throw new Error('No UP account connected');
      }

      const contractAddress = '0xe76e3E53FfEC2337d9317BCdA6245A75e3d92826';
      console.log('Payment setup:', {
        contractAddress,
        upAddress: connectedAddress,
        usdAmount: usdcAmount
      });

      // Get contract instance
      const contract = new ethers.Contract(
        contractAddress,
        PaymentABI,
        signer
      );

      // Get TYPE_PAYMENT from contract
      const TYPE_PAYMENT = await contract.TYPE_PAYMENT();
      console.log('Payment type:', TYPE_PAYMENT);

      const usdAmountWei = ethers.utils.parseUnits(usdcAmount.toString(), 18);
      const requiredLYX = await contract.calculateLYXAmount(usdAmountWei);

      // Create LSP0 interface
      const lsp0Interface = new ethers.utils.Interface([
        "function execute(uint256 operationType, address target, uint256 value, bytes memory data) external payable returns (bytes memory)"
      ]);

      // Create payment contract interface
      const paymentInterface = new ethers.utils.Interface([
        "function universalReceiver(bytes32 typeId, bytes memory data) payable returns (bytes memory)"
      ]);

      // Encode the payment data with correct TYPE_PAYMENT
      const paymentData = paymentInterface.encodeFunctionData("universalReceiver", [
        TYPE_PAYMENT,
        ethers.utils.defaultAbiCoder.encode(['uint256'], [usdAmountWei])
      ]);

      // Encode the execute call
      const executeData = lsp0Interface.encodeFunctionData("execute", [
        0, // OPERATION_CALL
        contractAddress,
        requiredLYX,
        paymentData
      ]);

      console.log('Payment details:', {
        to: connectedAddress,
        data: executeData,
        contractAddress,
        usdAmount: usdAmountWei.toString(),
        lyxAmount: ethers.utils.formatEther(requiredLYX),
        TYPE_PAYMENT
      });

      // Send transaction
      const tx = await signer.sendTransaction({
        to: connectedAddress,
        data: executeData,
        value: requiredLYX,
        gasLimit: 1000000
      });

      const receipt = await tx.wait();
      console.log('Transaction receipt:', {
        status: receipt.status,
        gasUsed: receipt.gasUsed.toString(),
        logs: receipt.logs.length
      });

      // Process payment on backend
      const requestBody = { 
        txHash: tx.hash,
        usdAmount: usdcAmount,
        userId: connectedAddress,
        privyId: user.id
      };
      console.log('Sending verification request:', requestBody);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/payments/verify-lyx`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.details || 'Failed to verify payment');
      }

      const responseData = await response.json();
      
      toast({
        title: "Payment Successful",
        description: `Added ${responseData.credits} credits to your account.`,
      });
      
      onClose();
    } catch (error) {
      console.error('Payment error:', error);
      toast({
        title: "Error",
        description: error.message || 'Failed to process payment. Please try again.',
        variant: "destructive"
      });
    } finally {
      setIsProcessing(false);
    }
  }

  async function handleTestPayment(amount) {
    try {
      setIsProcessing(true)
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/payments/test-payment`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount,
          userId: user.id,
          packDetails: selectedPack
        })
      })
      
      if (!response.ok) throw new Error('Test payment failed')
      
      const data = await response.json()
      toast({
        title: "Test Payment Successful",
        description: `Added ${data.credits.toLocaleString()} credits to your account (including ${selectedPack?.bonus?.toLocaleString() || 0} bonus credits)`,
      })
      onClose()
    } catch (error) {
      toast({
        title: "Error",
        description: "Test payment failed",
        variant: "destructive" 
      })
    } finally {
      setIsProcessing(false)
    }
  }

  const handleStripeClick = async () => {
    if (isProcessing) return;
    
    try {
      setIsProcessing(true);
      const clientSecret = await onBuyWithStripe(usdcAmount);
      
      if (clientSecret) {
        setStripeClientSecret(clientSecret);
        setShowStripeDialog(true);
      } else {
        throw new Error('No client secret received');
      }
    } catch (error) {
      console.error('Error initializing Stripe payment:', error);
      toast({
        title: "Error",
        description: "Failed to initialize payment. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCryptoClick = async () => {
    try {
      const result = await onBuyWithCrypto(usdcAmount);
      if (result?.hostedUrl) {
        window.open(result.hostedUrl, '_blank', 'noopener,noreferrer');
      }
    } catch (error) {
      console.error('Error initiating crypto payment:', error);
      toast({
        title: "Error",
        description: "Failed to initialize crypto payment. Please try again.",
        variant: "destructive"
      });
    }
  };

  const renderCreditPacks = () => (
    <div className="grid gap-4 mb-6">
      {creditPacks.map((pack) => (
        <button
          key={pack.id}
          onClick={() => setSelectedPack(pack)}
          className={`relative w-full rounded-lg p-4 text-left transition-all
            ${selectedPack?.id === pack.id 
              ? 'ring-2 ring-primary scale-[1.02]' 
              : 'hover:scale-[1.01]'
            }
            bg-gradient-to-r ${pack.gradient} border border-border`}
        >
          {pack.popular && (
            <div className="absolute -top-2 -right-2 bg-primary text-primary-foreground text-xs px-2 py-0.5 rounded-full">
              Most Popular
            </div>
          )}
          
          <div className="flex justify-between items-start mb-2">
            <h3 className="font-semibold text-lg">{pack.name}</h3>
            <div className="text-2xl font-bold">${pack.price}</div>
          </div>
          
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <Coins className="h-4 w-4 text-primary" />
              <span className="font-medium">{pack.credits.toLocaleString()} credits</span>
            </div>
            
            {pack.bonus > 0 && (
              <div className="flex items-center gap-2 text-emerald-600 dark:text-emerald-400">
                <Sparkles className="h-4 w-4" />
                <span className="font-medium">+{pack.bonus.toLocaleString()} bonus credits!</span>
              </div>
            )}
            
            <div className="text-sm text-muted-foreground">
              ${(pack.price / (pack.credits + pack.bonus) * 1000).toFixed(2)} per 1000 credits
            </div>
          </div>
        </button>
      ))}
    </div>
  )

  const renderPaymentMethods = () => (
    <div className="space-y-4">
      <Button
        onClick={handleLYXPayment}
        className="w-full"
        variant="outline"
        disabled={isProcessing || !lyxAmount}
      >
        {isProcessing ? (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <>
            <img 
              src="/lyx.svg" 
              alt="LYX" 
              className="mr-2 h-5 w-5" 
            />
            Pay with LYX
            {lyxAmount && <span className="ml-2">({lyxAmount} LYX)</span>}
          </>
        )}
      </Button>
      
      <div className="grid grid-cols-2 gap-2">
        <Button
          onClick={handleStripeClick}
          className="w-full"
          variant="outline"
          disabled={isProcessing}
        >
          <CreditCard className="mr-2 h-4 w-4" />
          Credit Card
        </Button>
        <Button
          onClick={handleCryptoClick}
          className="w-full"
          variant="outline"
        >
          <Bitcoin className="mr-2 h-4 w-4" />
          Crypto
        </Button>
      </div>
      
      {isDev && (
        <div className="relative">
          <Button
            onClick={() => handleTestPayment(selectedPack?.price || usdcAmount)}
            className="w-full"
            variant="secondary"
          >
            🛠️ Test Payment
          </Button>
          <div className="absolute -top-2 -right-2 bg-yellow-500 text-yellow-950 text-[10px] px-1.5 py-0.5 rounded-full font-medium">
            DEV
          </div>
        </div>
      )}
    </div>
  )

  // Render mobile dialog
  if (isMobile) {
    return (
      <>
        <Sheet open={show} onOpenChange={onClose}>
          <SheetContent side="bottom" className="px-4 pb-8">
            <SheetHeader>
              <SheetTitle className="flex items-center gap-2">
                <Coins className="h-5 w-5" />
                Buy Credits
              </SheetTitle>
            </SheetHeader>
            <div className="py-4">
              {renderCreditPacks()}
              {renderPaymentMethods()}
              
              <p className="text-xs text-muted-foreground text-center mt-4">
                Secure payments powered by LUKSO, Stripe and Coinbase
                <br />
                <span className="opacity-75">
                  Payment processing requires cookies to function properly
                </span>
              </p>
            </div>
          </SheetContent>
        </Sheet>

        {showStripeDialog && stripeClientSecret && (
          <StripeDialog
            show={showStripeDialog}
            onClose={() => setShowStripeDialog(false)}
            clientSecret={stripeClientSecret}
            amount={usdcAmount}
            onSuccess={() => {
              setShowStripeDialog(false);
              onClose();
            }}
          />
        )}
      </>
    );
  }

  // Render desktop dialog
  return (
    <>
      <Dialog open={show} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              <Coins className="h-5 w-5" />
              Buy Credits
            </DialogTitle>
          </DialogHeader>
          <div className="py-4">
            {renderCreditPacks()}
            {renderPaymentMethods()}
            
            <p className="text-xs text-muted-foreground text-center mt-4">
              Secure payments powered by LUKSO, Stripe and Coinbase
              <br />
              <span className="opacity-75">
                Payment processing requires cookies to function properly
              </span>
            </p>
          </div>
        </DialogContent>
      </Dialog>

      {showStripeDialog && stripeClientSecret && (
        <StripeDialog
          show={showStripeDialog}
          onClose={() => setShowStripeDialog(false)}
          clientSecret={stripeClientSecret}
          amount={usdcAmount}
          onSuccess={() => {
            setShowStripeDialog(false);
            onClose();
          }}
        />
      )}
    </>
  );
}

export default BuyCreditsDialog;
