import React from 'react';

function SkeletonLoader({ isDarkMode }) {
  return (
    <div className="animate-in fade-in duration-300 ease-in-out">
      <div className="w-full max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Profile cards grid */}
        <div className="py-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <div className="h-5 w-5 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded mr-2"></div>
              <div className="text-lg h-6 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded w-32"></div>
            </div>
          </div>
          
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3 mx-16 -ml-0 auto-rows-max">
            {[1, 2, 3, 4, 5, 6].map((i) => (
              <div key={i} className="w-48 h-24 rounded-xl bg-white dark:bg-[hsl(20_12%_8%)] animate-pulse border border-border/40">
                <div className="p-0 flex flex-col h-full">
                  {/* Top Section */}
                  <div className="px-2.5 pt-2.5 pb-1 flex items-center">
                    <div className="w-5 h-5 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded-full mr-1.5"></div>
                    <div className="flex flex-col flex-1">
                      <div className="h-3 w-24 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded mb-1"></div>
                      <div className="h-2 w-16 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded"></div>
                    </div>
                  </div>

                  {/* Stats Section - Adjusted margin to match new spacing */}
                  <div className="px-2.5 flex items-center space-x-3 mt-3">
                    <div className="h-4 w-12 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded"></div>
                    <div className="h-4 w-12 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded"></div>
                  </div>

                  {/* Bottom Progress Bar */}
                  <div className="mt-auto w-full">
                    <div className="w-full h-1 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded-none rounded-b-xl"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Trackers section */}
        <div className="mb-8">
          <div className="flex items-center mb-6">
            <div className="h-5 w-5 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded mr-2"></div>
            <div className="text-lg h-6 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded w-32"></div>
          </div>
          
          {/* Tracker cards */}
          <div className="space-y-6">
            {[1, 2].map((i) => (
              <div key={i} className="border border-border rounded-xl overflow-hidden bg-white dark:bg-[hsl(20_12%_8%)] animate-pulse shadow-md">
                <div className="bg-gray-50 dark:bg-[hsl(20_12%_10%)] p-0">
                  <div className="flex flex-wrap gap-2 p-2">
                    <div className="flex items-center space-x-2 bg-white dark:bg-[hsl(20_12%_8%)] rounded-lg px-2 py-1">
                      <div className="h-6 w-6 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded-full flex-shrink-0"></div>
                      <div className="flex flex-col">
                        <div className="text-sm h-4 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded w-24"></div>
                        <div className="text-xs h-3 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded w-20 mt-1"></div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full "></div>
                </div>
                <div className="p-2 sm:p-3">
                  <div className="flex justify-between items-center mb-2">
                    <div className="text-xl h-7 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded w-3/4"></div>
                    <div className="ml-2 flex-shrink-0 h-6 w-11 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded-full"></div>
                  </div>
                  <div className="text-sm h-4 bg-gray-200 dark:bg-[hsl(20_12%_16%)] rounded w-1/2 mb-4"></div>
                  <div className="flex flex-wrap gap-2">
                    {[1, 2, 3, 4].map((j) => (
                      <div key={j} className="h-9 bg-gray-100 dark:bg-white/5 hover:bg-gray-200 dark:hover:bg-white/10 rounded-md w-28"></div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonLoader;
