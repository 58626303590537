import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Bell, Trash2, Edit, Moon, Sun, AlertTriangle, Loader2, LogOut, Plus, RefreshCw, X, BarChart, Lightbulb, ChevronDown, ChevronUp, ChevronRight, ChevronLeft, Book, Target } from 'lucide-react';
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import { Switch } from "./ui/switch";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { usePrivy } from '@privy-io/react-auth';
import ReactMarkdown from 'react-markdown';
import { useToast } from "./ui/use-toast";
import { Toaster } from "./ui/toaster";
import { useTheme } from '../contexts/ThemeContext';
import { ToggleGroup, ToggleGroupItem } from "./ui/toggle-group";
import { useLocalStorage } from '../hooks/useLocalStorage';
import { debounce } from 'lodash';
import SkeletonLoader from './ui/SkeletonLoader';
import { formatDistanceToNow } from 'date-fns';
import { loadStripe } from '@stripe/stripe-js';
import { useProfile } from '../contexts/ProfileContext';
import ExampleProfiles from './ExampleProfiles';
import ExampleReminders from './ExampleReminders';
import BuyCreditsDialog from './BuyCreditsDialog';
import StripeDialog from './StripeDialog';
import PushDialog from './PushDialog';
import AdminDashboardDialog from './AdminDashboardDialog';
import FloatingButton from './FloatingButton';
import ReminderComponent from './ReminderComponent';
import TwitterHandle from './TwitterHandle';
import ProfileCarousel from './ProfileCarousel';
import UpdateBanner from './UpdateBanner';
import MobileTopBar from './MobileTopBar';
import Sidebar from './Sidebar';
import Footer from './Footer'
import PushNotificationDialog from './PushNotificationDialog';
import CreditsInfo from './ui/credits-info';
import useCredits from '../hooks/useCredits';
import CreditWidget from './CreditWidget';
import TrackersInfo from './ui/trackers-info';



const formatNumber = (number) => {
  return new Intl.NumberFormat().format(number);
};

const useCache = () => {
  const cache = useMemo(() => new Map(), []);

  const getCachedData = useCallback(  
    (key) => {
      const cachedItem = cache.get(key);
      if (cachedItem && Date.now() - cachedItem.timestamp < 60000) {
        // 1 minute cache
        return cachedItem.data;
      }
      return null;
    },
    [cache]
  );

  const setCachedData = useCallback(
    (key, data) => {
      cache.set(key, { data, timestamp: Date.now() });
    },
    [cache]
  );

  return { getCachedData, setCachedData };
};

const formatRelativeTime = (date) => {
  if (!date) return 'never';
  return formatDistanceToNow(new Date(date), { addSuffix: true });
};

// Update the sortByLatestContent function
const sortByLatestContent = (reminders) => {
  return [...reminders].sort((a, b) => {
    const getLatestTimestamp = (reminder) => {
      // Get latest truffle time by sorting all trigger events first
      const latestTruffleTime = reminder.triggerEvents?.length
        ? Math.max(...reminder.triggerEvents.map(e => 
            new Date(e.triggeredAt).getTime()
          ))
        : -1;
      
      // Get latest insight time by sorting all insights first
      const latestInsightTime = reminder.insights?.length
        ? Math.max(...reminder.insights.map(i => 
            new Date(i.createdAt).getTime()
          ))
        : -1;

      // Get creation time
      const creationTime = new Date(reminder.createdAt).getTime();

      // Return the latest of all timestamps
      return Math.max(latestTruffleTime, latestInsightTime, creationTime);
    };

    const aLatest = getLatestTimestamp(a);
    const bLatest = getLatestTimestamp(b);

    return bLatest - aLatest;
  });
};

const TruffleApp = () => {
  const { 
    profileData, 
    fetchProfileData, 
    addTweetsToProfile, 
    addInsightToProfile,
    resetProfileData,
    userId: contextUserId
  } = useProfile();
  
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { login, logout, authenticated, user, ready } = usePrivy();
  
  const { 
    credits,
    isLoading: isLoadingCredits,
    isPendingTransaction,
    handleBuyCredits: handleBuyCreditsFn,
    handleStripePayment,
    hasEnoughCredits,
    deductCredits,
    refreshCredits
  } = useCredits({ 
    userId: user?.id, 
    isOnline 
  });

  const isLoadingCreditData = isLoadingCredits || !credits;

  const [frequency, setFrequency] = useState("Weekly");
  const [reminders, setReminders] = useState([]);
  const [editingReminder, setEditingReminder] = useState(null);
  const [isPushEnabled, setIsPushEnabled] = useLocalStorage('isPushEnabled', false);
  const [isAddingReminder, setIsAddingReminder] = useState(false);
  const [checkingReminders, setCheckingReminders] = useState({});
  const [userId, setUserId] = useState(contextUserId);
  const [isLoading, setIsLoading] = useState(true);
  const [profileImageUrl] = useState("");
  const { toast } = useToast();
  const { isDarkMode, toggleDarkMode } = useTheme();
  const [showPushDialog, setShowPushDialog] = useState(false);
  const [showBuyCreditsDialog, setShowBuyCreditsDialog] = useState(false);
  const [usdcAmount, setUsdcAmount] = useState(5);
  const [inputProfileToTrack, setInputProfileToTrack] = useState("");
  const [debouncedProfileToTrack, setDebouncedProfileToTrack] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [setInputEmail] = useState("");
  const [authState, setAuthState] = useState('loading');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditingInDrawer, setIsEditingInDrawer] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [offlineToastId, setOfflineToastId] = useState(null);
  const [profileInfo, setProfileInfo] = useState(null);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);
  const [dismissedVersion, setDismissedVersion] = useState(null);
  const [showAdminDashboard, setShowAdminDashboard] = useState(false);
  const [showSubtext, setShowSubtext] = useState(true);
  const [generatingInsights, setGeneratingInsights] = useState({});
  const [includeReplies, setIncludeReplies] = useState("only-posts");
  const [showStripeDialog, setShowStripeDialog] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [showPushAfterReminder, setShowPushAfterReminder] = useState(false);

  const profileCardsRef = useRef(null);

  const scrollProfileCards = (direction) => {
    if (profileCardsRef.current) {
      const scrollAmount = profileCardsRef.current.offsetWidth * 0.8; // Scroll 80% of the container width
      profileCardsRef.current.scrollBy({
        left: direction === 'right' ? scrollAmount : -scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const updateStatusBarStyle = useCallback((isDark) => {
    const metaStatusBar = document.getElementById('apple-status-bar-style');
    if (metaStatusBar) {
      metaStatusBar.setAttribute('content', isDark ? 'black' : 'default');
    }
    // Also update the theme-color meta tag for Android devices
    const metaThemeColor = document.getElementById('theme-color-meta');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', isDark ? '#000000' : '#ffffff');
    }
  }, []);

  useEffect(() => {
    updateStatusBarStyle(isDarkMode);
  }, [isDarkMode, updateStatusBarStyle]);

  const examplePromptsRef = useRef([
    "when they tweet about new product features or updates",
    "if they mention our competitors or similar products",
    "when they share insights about industry trends",
    "if they post job openings or hint at team expansion",
    "when they discuss challenges or pain points in our industry",
    "if they announce partnerships or collaborations",
    "when they share customer success stories or testimonials",
    "if they mention specific technologies or tools we're interested in",
    "when they talk about upcoming events or conferences",
    "if they discuss regulatory changes affecting our industry"
  ]);

  const currentPlaceholderRef = useRef("");

  const getRandomPrompt = useCallback(() => {
    const prompts = examplePromptsRef.current;
    return prompts[Math.floor(Math.random() * prompts.length)];
  }, []);

  const updatePlaceholder = useCallback(() => {
    currentPlaceholderRef.current = getRandomPrompt();
  }, [getRandomPrompt]);

  // Set initial placeholder
  useEffect(() => {
    updatePlaceholder();
  }, [updatePlaceholder]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      if (authenticated) {
        // Attempt to reconnect Privy
        try {
          login();
        } catch (error) {
          console.error('Failed to reconnect to Privy:', error);
        }
      }
    };
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
    };
  }, [authenticated, login]);

  useEffect(() => {
    if (!isOnline) {
      const id = toast({
        title: "You are offline",
        description: "Some features may be unavailable until you reconnect.",
        duration: null, // Keep the toast visible until dismissed
      });
      setOfflineToastId(id);
    } else if (offlineToastId) {
      toast({
        id: offlineToastId,
        title: "You are back online",
        description: "All features are now available.",
        duration: 3000,
      });
      setOfflineToastId(null);
    }
  }, [isOnline, offlineToastId, toast]);

  // Single source of truth for app state
  const [appState, setAppState] = useState({
    isInitialLoad: true,
    isLoadingData: false,
    isContentReady: false
  });

  // Add this near the top with other state declarations
  const [isPrivyReady, setIsPrivyReady] = useState(false);
  // Handle user authentication changes
  useEffect(() => {
    if (!ready) return

    setIsPrivyReady(true)

    if (!authenticated || !user) {
      setAuthState('unauthenticated')
      resetProfileData()
      setReminders([])
      setUserId(null)
      setAppState({
        isInitialLoad: false,
        isLoadingData: false,
        isContentReady: true
      })
      setIsLoading(false)
      return
    }

    setAuthState('authenticated')
    setAppState(prev => ({ ...prev, isLoadingData: true }))

    async function loadUserData() {
      try {
        const loginResponse = await loginUser();
        if (!loginResponse?.userId) {
          throw new Error('Login response missing userId');
        }
        
        await fetchProfileData(loginResponse.userId, true);
        
        setAppState({
          isInitialLoad: false,
          isLoadingData: false,
          isContentReady: true
        });
      } catch (error) {
        console.error('Error loading user data:', error);
        toast({
          title: "Error",
          description: "Failed to load user data. Please refresh the page.",
          variant: "destructive",
        });
        // Reset states on error
        setAppState({
          isInitialLoad: false,
          isLoadingData: false,
          isContentReady: false
        });
        setIsLoading(false);
      }
    }

    loadUserData();
  }, [ready, authenticated, user]);

  useEffect(() => {
    const checkAdminStatus = async (user) => {
      if (!user) return;
      
      try {
        const userId = user.id;
        
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/admin/check-admin?userId=${encodeURIComponent(userId)}`,
          {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setIsAdmin(data.isAdmin);
      } catch (error) {
        setIsAdmin(false);
      }
    };

    checkAdminStatus(user);
  }, [authenticated, user]);

  const calculateMonthlyCreditSpend = useMemo(() => {
    return reminders.reduce((total, reminder) => {
      if (!reminder.isActive) return total;
      const checksPerMonth = {
        'Hourly': 24 * 30,
        'Daily': 30,
        'Weekly': 4,
        'Monthly': 1
      };
      const profileCount = reminder.profiles ? reminder.profiles.length : 1;
      return total + (checksPerMonth[reminder.frequency] || 0) * 10 * profileCount;
    }, 0);
  }, [reminders]);

  // Single function to handle ALL reminder updates
  const updateReminders = useCallback((updateFn) => {
    setReminders(prev => {
      const updated = typeof updateFn === 'function' ? updateFn(prev) : updateFn;
      return sortByLatestContent(updated);
    });
  }, []);

  const fetchUserData = useCallback(async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reminders?userId=${userId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch reminders');
      }
      const data = await response.json();
      updateReminders(data); // Use updateReminders instead of setReminders
    } catch (error) {
      console.error('Error fetching user data:', error);
      toast({
        title: "Error",
        description: "Failed to fetch user data. Please try again.",
        variant: "destructive",
      });
    }
  }, [updateReminders, toast]);

  const loginUser = async () => {
    setIsLoading(true);
    try {
      const walletAddresses = user.linkedAccounts
        .filter(account => account.type === 'wallet')
        .map(account => account.address);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          privyId: user.id,
          walletAddresses: walletAddresses
        }),
      });

      if (!response.ok) {
        throw new Error(`Login failed with status: ${response.status}`);
      }

      const loginResponse = await response.json();
      setUserId(loginResponse.userId);

      // Fetch reminders after successful login
      const remindersResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/reminders?userId=${loginResponse.userId}`
      );
      
      if (!remindersResponse.ok) {
        throw new Error('Failed to fetch reminders');
      }
      
      const remindersData = await remindersResponse.json();
      updateReminders(remindersData); // Use updateReminders instead of setReminders

      // Return the login response
      return loginResponse;
    } catch (error) {
      console.error('Error logging in user:', error);
      toast({
        title: "Login Error",
        description: "Failed to log in. Please try again.",
        variant: "destructive",
        duration: 5000,
      });
      setIsLoading(false);
      setAuthState('unauthenticated');
      throw error;
    }
  };

  // Wrap Privy-related functions to catch potential errors
  const safeLogin = useCallback(async () => {
    try {
      await login();
    } catch (error) {
      console.error('Login error:', error);
      toast({
        title: "Login Error",
        description: "Failed to log in. Please try again.",
        variant: "destructive",
      });
    }
  }, [login, toast]);

  const safeLogout = useCallback(async () => {
    try {
      // First clear all local state
      setReminders([]);
      setUserId(null);
      setAuthState('unauthenticated');
      setAppState({
        isInitialLoad: false,
        isLoadingData: false,
        isContentReady: false
      });

      // Then call Privy logout
      await logout();
    } catch (error) {
      console.error('Logout error:', error);
      // Even if Privy logout fails, ensure user is logged out locally
      setAuthState('unauthenticated');
      setReminders([]);
      setUserId(null);
      setAppState({
        isInitialLoad: false,
        isLoadingData: false,
        isContentReady: false
      });
    }
  }, [logout]);

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  // Debounced function to fetch profile info
  const debouncedFetchProfileInfo = useMemo(
    () => debounce(async (profile) => {
      if (!profile) return;
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile-info?profile=${encodeURIComponent(profile)}`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setProfileInfo(data);
      } catch (error) {
        console.error('Error fetching profile info:', error);
        setProfileInfo(null);
      }
    }, 1500),
    []
  );

  // Effect to call debounced function when input changes
  useEffect(() => {
    if (inputProfileToTrack) {
      debouncedFetchProfileInfo(inputProfileToTrack);
    } else {
      setProfileInfo(null);
    }
    return () => debouncedFetchProfileInfo.cancel();
  }, [inputProfileToTrack, debouncedFetchProfileInfo]);

  const addProfile = (profile) => {
    if (selectedProfiles.length < 5 && !selectedProfiles.some(p => p.handle === `@${profile.username}`)) {
      setSelectedProfiles([...selectedProfiles, {
        handle: `@${profile.username}`,
        displayName: profile.displayName,
        profileImageUrl: profile.profileImageUrl
      }]);
      setInputProfileToTrack('');
      setProfileInfo(null);
    }
  };

  const removeSelectedProfile = (index) => {
    setSelectedProfiles(selectedProfiles.filter((_, i) => i !== index));
  };

  const updateReminderStatus = useCallback(async (id, status) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reminders/${id}/status`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (status === 'deleted') {
        setReminders(prevReminders => prevReminders.filter(reminder => reminder.id !== id));
      } else if (status === 'fullyDeleted') {
        setReminders(prevReminders => prevReminders.filter(reminder => reminder.id !== id));
      } else {
        setReminders(prevReminders => 
          prevReminders.map(reminder => 
            reminder.id === id 
              ? { ...reminder, isActive: status === 'active' } 
              : reminder
          )
        );
      }

      toast({
        title: `Reminder ${status}`,
        description: `The reminder has been ${status}.`,
      });
    } catch (error) {
      console.error(`Error ${status} reminder:`, error);
      toast({
        title: "Error",
        description: `Failed to ${status} reminder. Please try again.`,
        variant: "destructive",
      });
    }
  }, [setReminders, toast]);
  const addOrUpdateReminder = async () => {
    if (!user?.id) {
      toast({
        title: "Authentication Required",
        description: "Please log in to add or update reminders.",
        variant: "destructive",
      });
      return;
    }

    if (!inputDescription.trim()) {
      toast({
        title: "Oops!",
        description: "Please add a tracking prompt!",
        variant: "destructive",
      });
      return;
    }

    if (selectedProfiles.length === 0) {
      toast({
        title: "Oops!",
        description: "You forgot to track someone! Add at least one profile to track.",
        variant: "destructive",
      });
      return;
    }

    // Validate profile data
    const validProfiles = selectedProfiles.filter(profile => 
      profile.handle && 
      typeof profile.handle === 'string' && 
      profile.handle.trim().length > 0
    );

    if (validProfiles.length === 0) {
      toast({
        title: "Error",
        description: "Invalid profile data. Please try again.",
        variant: "destructive",
      });
      return;
    }

    setIsAddingReminder(true);
    let optimisticReminder; // Declare outside try block so it's accessible in catch

    try {
      // If editing, remove the old reminder first
      if (editingReminder) {
        setReminders(prev => prev.filter(r => r.id !== editingReminder));
      }

      // Create optimistic reminder with isOptimistic flag
      optimisticReminder = {
        id: editingReminder || `temp-${Date.now()}`,
        description: inputDescription.trim(),
        profiles: validProfiles.map(profile => ({
          handle: profile.handle.trim(),
          displayName: profile.displayName || profile.handle,
          profileImageUrl: profile.profileImageUrl || ''
        })),
        frequency,
        userId,
        includeReplies: includeReplies === "posts-and-replies",
        reformulatedPrompt: '', // Start with empty string to trigger scrambling
        isActive: true,
        triggerEvents: [],
        insights: [],
        createdAt: new Date().toISOString(),
        isOptimistic: true // Add the flag here
      };

      // Add optimistic reminder at the top
      setReminders(prev => [optimisticReminder, ...prev]);

      // API call creates/updates reminder
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/reminders${editingReminder ? `/${editingReminder}` : ''}`,
        {
          method: editingReminder ? 'PUT' : 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            description: inputDescription.trim(),
            profiles: validProfiles.map(profile => ({
              handle: profile.handle.trim(),
              displayName: profile.displayName || profile.handle,
              profileImageUrl: profile.profileImageUrl || ''
            })),
            frequency,
            userId,
            includeReplies: includeReplies === "posts-and-replies"
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.details || `HTTP error! status: ${response.status}`);
      }
      
      const newReminder = await response.json();
      
      // First update with the new reminder data but keep reformulatedPrompt empty
      setReminders(prev => prev.map(r => {
        if (r.id === optimisticReminder.id) {
          return {
            ...newReminder,
            reformulatedPrompt: '', // Keep empty to maintain loading state
            id: newReminder.id
          };
        }
        return r;
      }));

      // Scroll to the optimistic reminder immediately after it's added
      setTimeout(() => {
        const reminderElement = document.getElementById(`reminder-${newReminder.id}`);
        if (reminderElement) {
          reminderElement.scrollIntoView({ 
            behavior: 'smooth', 
            block: 'center'
          });
        }
      }, 100); // Short delay to ensure DOM update

      // Add a longer delay before showing the reformulated prompt
      await new Promise(resolve => setTimeout(resolve, 1500));

      // Then update with the reformulatedPrompt to trigger the animation
      setReminders(prev => prev.map(r => {
        if (r.id === newReminder.id) {
          return {
            ...r,
            reformulatedPrompt: newReminder.reformulatedPrompt
          };
        }
        return r;
      }));

      // Only update profiles if this is a new reminder, not an edit
      if (!editingReminder) {
        validProfiles.forEach(profile => {
          if (newReminder.profiles.some(p => p.handle === profile.handle)) {
            // Update the profile data in context by adding the new reminder
            fetchProfileData(userId, true); // Force refresh profile data after adding reminder
          }
        });
      }

      // Reset form
      setSelectedProfiles([]);
      setInputDescription('');
      setFrequency('Weekly');
      setEditingReminder(null);
      setIsDrawerOpen(false);

      toast({
        title: "Success",
        description: `Reminder ${editingReminder ? 'updated' : 'created'} successfully!`,
        duration: 3000
      });

      // If this is their first reminder and push isn't enabled, show the dialog
      const isFirstReminder = reminders.length === 0;
      if (isFirstReminder && !isPushEnabled) {
        setShowPushAfterReminder(true);
      }
    } catch (error) {
      // Now optimisticReminder is accessible here
      if (editingReminder) {
        setReminders(prev => {
          const originalReminder = reminders.find(r => r.id === editingReminder);
          return originalReminder ? [originalReminder, ...prev] : prev;
        });
      } else if (optimisticReminder) { // Check if it was created
        // Remove optimistic reminder if creating new
        setReminders(prev => prev.filter(r => r.id !== optimisticReminder.id));
      }
      
      console.error('Error adding/updating reminder:', error);
      toast({
        title: "Error",
        description: error.message || `Failed to ${editingReminder ? 'update' : 'add'} reminder. Please try again.`,
        variant: "destructive",
        duration: 5000,
      });
    } finally {
      setIsAddingReminder(false);
    }
  };

  const handleProfileToTrackChange = useCallback((e) => {
    const value = e.target.value;
    setInputProfileToTrack(value);
    debouncedSetProfileToTrack(value);
  }, []);

  const debouncedSetProfileToTrack = useMemo(
    () => debounce(setDebouncedProfileToTrack, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSetProfileToTrack.cancel();
    };
  }, [debouncedSetProfileToTrack]);

  const handleIncludeRepliesChange = useCallback((value) => {
    setIncludeReplies(value);
  }, []);

  const handleDescriptionChange = useCallback((e) => {
    setInputDescription(e.target.value);
  }, []);

  const handleBuyCredits = useCallback(() => {
    if (!authenticated) {
      toast({
        title: "Authentication Required",
        description: "Please log in to buy credits.",
        variant: "destructive",
      });
      return;
    }
    handleBuyCreditsFn(usdcAmount);
  }, [authenticated, handleBuyCreditsFn, usdcAmount, toast]);

  // Add this near the top of the file with other useEffects
  useEffect(() => {
    // Register service worker on app initialization
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
        })
        .catch(error => {
          console.error('Service Worker registration failed:', error);
        });
    }
  }, []); // Empty dependency array means this runs once on mount

  // Update the push notification handler
  const handleEnablePushNotifications = async () => {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      toast({
        title: "Push Not Supported",
        description: "Push notifications are not supported in your browser.",
        variant: "destructive",
      });
      return;
    }

    try {
      const registration = await navigator.serviceWorker.ready;

      // Request notification permission
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        throw new Error('Notification permission denied');
      }

      // Get existing subscription or create new one
      let subscription = await registration.pushManager.getSubscription();
      if (!subscription) {
        const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY);
        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedVapidKey
        });
        console.log('Created new push subscription:', subscription);
      }

      // Save subscription to server
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/save-subscription`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          subscription: subscription,
          userId: userId
        })
      });

      if (!response.ok) {
        throw new Error('Failed to save subscription on server');
      }

      setIsPushEnabled(true);
      setShowPushDialog(false);

      // Send test notification
      const testResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/test-notification`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          subscription,
          payload: JSON.stringify({
            title: 'Push Notifications Enabled! 🔔',
            body: 'You will now receive notifications when truffles are found.',
            data: { url: '/dashboard' }
          })
        })
      });

      if (!testResponse.ok) {
        throw new Error('Failed to send test notification');
      }

      toast({
        title: "Push Notifications Enabled",
        description: "You'll now receive notifications when truffles are found.",
        duration: 5000
      });

    } catch (error) {
      console.error('Error enabling push notifications:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to enable push notifications. Please try again.",
        variant: "destructive"
      });
    }
  };

  // Simplify the handleEnablePush function
  const handleEnablePush = () => {
    setShowPushDialog(false);
    handleEnablePushNotifications();
  };

  const editReminder = useCallback((id) => {
    const reminderToEdit = reminders.find(r => r.id === id);
    if (reminderToEdit) {
      // Clear the scramble completion flag when editing
      localStorage.removeItem(`scramble-complete-${id}`);
      
      setEditingReminder(id);
      setInputDescription(reminderToEdit.description);
      setSelectedProfiles(reminderToEdit.profiles || []);
      setFrequency(reminderToEdit.frequency);
      setIncludeReplies(reminderToEdit.includeReplies ? "posts-and-replies" : "only-posts");
      
      if (window.innerWidth < 1024) {
        setIsDrawerOpen(true);
        setIsEditingInDrawer(true);
      }
    }
  }, [reminders, setEditingReminder, setInputDescription, setSelectedProfiles, setFrequency, setIncludeReplies, setIsDrawerOpen, setIsEditingInDrawer]);

  const deleteReminder = useCallback(async (id) => {
    try {
      await updateReminderStatus(id, 'deleted');
      
      // Remove the reminder from the local state
      setReminders(prevReminders => prevReminders.filter(r => r.id !== id));
      
      // Update profile cards
      fetchProfileData(prevData => {
        const updatedData = { ...prevData };
        Object.keys(updatedData).forEach(profileId => {
          updatedData[profileId] = {
            ...updatedData[profileId],
            reminders: updatedData[profileId].reminders.filter(r => r.id !== id)
          };
          
          // If this was the last reminder for this profile, remove the profile
          if (updatedData[profileId].reminders.length === 0) {
            delete updatedData[profileId];
          }
        });
        return updatedData;
      });
    } catch (error) {
      console.error('Error deleting reminder:', error);
      toast({
        title: "Error",
        description: "Failed to delete reminder. Please try again.",
        variant: "destructive",
      });
    }
  }, [updateReminderStatus, setReminders, fetchProfileData, toast]);

  const toggleReminderActive = useCallback(async (id, currentStatus) => {
    updateReminderStatus(id, currentStatus ? 'disabled' : 'active');
  }, [updateReminderStatus]);

  const renderCreditWarning = () => {
    if (credits <= 0) {
      return (
        <div className={`p-4 mb-6 rounded-md ${isDarkMode ? 'bg-red-900 text-red-100' : 'bg-red-100 text-red-800'} flex items-center`}>
          <AlertTriangle className="mr-2 h-6 w-6" />
          <span className="text-lg font-semibold">Not enough credits to track Truffle. Please buy some more.</span>
        </div>
      );
    }
    return null;
  };

  const fetchProfiles = useCallback(() => {
    if (authenticated && userId && Object.keys(profileData).length === 0) {
      fetchProfileData(userId);
    }
  }, [authenticated, userId, fetchProfileData, profileData]);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  // Add this before the sortedReminders useMemo
  const updateRemindersWithSort = useCallback((updateFn) => {
    setReminders(prev => {
      const updated = typeof updateFn === 'function' ? updateFn(prev) : updateFn;
      return [...updated].sort((a, b) => {
        const latestTruffleA = a.triggerEvents?.[0]?.triggeredAt 
          ? new Date(a.triggerEvents[0].triggeredAt).getTime() 
          : -1;
        
        const latestTruffleB = b.triggerEvents?.[0]?.triggeredAt 
          ? new Date(b.triggerEvents[0].triggeredAt).getTime() 
          : -1;

        const latestInsightA = a.insights?.[0]?.createdAt 
          ? new Date(a.insights[0].createdAt).getTime() 
          : -1;
        
        const latestInsightB = b.insights?.[0]?.createdAt 
          ? new Date(b.insights[0].createdAt).getTime() 
          : -1;

        const mostRecentA = Math.max(latestTruffleA, latestInsightA);
        const mostRecentB = Math.max(latestTruffleB, latestInsightB);

        if (mostRecentA > -1 && mostRecentB > -1) {
          return mostRecentB - mostRecentA;
        }

        if (mostRecentA > -1) return -1;
        if (mostRecentB > -1) return 1;

        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
    });
  }, []);

  // Add this near other useEffect hooks
  useEffect(() => {
    // Reset scroll position when transitioning from loading to content
    if (isPrivyReady && authState === 'authenticated' && !appState.isLoadingData) {
      window.scrollTo(0, 0);
    }
  }, [isPrivyReady, authState, appState.isLoadingData]);

  // Render function for the main content
  const renderMainContent = () => {
    // Show skeleton loader while Privy is initializing or while authenticated content is loading
    if (!isPrivyReady || ((appState.isInitialLoad || appState.isLoadingData) && authState === 'authenticated')) {
      return (
        <div className="min-h-screen">
          <SkeletonLoader isDarkMode={isDarkMode} />
        </div>
      );
    }

    if (authState === 'authenticated') {
      return (
        <div className="min-h-screen animate-in fade-in duration-300 ease-in-out">
          <div className="w-full max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-6">
              <ProfileCarousel
                authenticated={!!user}
                onAddProfile={handleAddProfile}
                setReminders={setReminders}
                userId={user?.id}
                credits={credits}
                deductCredits={deductCredits}
                hasEnoughCredits={hasEnoughCredits}
                isDarkMode={isDarkMode}
                selectedProfiles={selectedProfiles}
              />
            </div>
            <div className="mb-4">
              <ReminderComponent
                authenticated={authState === 'authenticated'}
                userId={userId}
                credits={credits}
                deductCredits={deductCredits}
                hasEnoughCredits={hasEnoughCredits}
                reminders={reminders}
                updateReminders={updateReminders}
                checkingReminders={checkingReminders}
                setCheckingReminders={setCheckingReminders}
                generatingInsights={generatingInsights}
                setGeneratingInsights={setGeneratingInsights}
                isDarkMode={isDarkMode}
                onAddProfile={handleAddProfile}
                onEditReminder={editReminder}
                onUpdateStatus={updateReminderStatus}
                selectedProfiles={selectedProfiles}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="min-h-screen animate-in fade-in duration-300 ease-in-out">
        <div className="w-full max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col space-y-8 pt-4">
            <div className="w-full">
              <h2 className="text-lg font-medium text-gray-500 dark:text-gray-400 mb-4">
                Example Profiles
              </h2>
              <ExampleProfiles />
            </div>
            <div className="w-full">
              <h2 className="text-lg font-medium text-gray-500 dark:text-gray-400 mb-4">
                Example Trackers
              </h2>
              <ExampleReminders onSignIn={safeLogin} isDarkMode={isDarkMode} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleUsdcAmountChange = (e) => {
    const value = e.target.value;
    if (value === '' || isNaN(value)) {
      setUsdcAmount('');
    } else {
      setUsdcAmount(Math.max(1, Math.floor(parseFloat(value))));
    }
  };

  const handleUpdate = useCallback(() => {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  }, [waitingWorker]);

  const checkForUpdates = useCallback(async () => {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.getRegistration();
        if (registration) {
          await registration.update();
          if (registration.waiting) {
            setWaitingWorker(registration.waiting);
            setNewVersionAvailable(true);
          }
        }

        const response = await fetch('/version.json?t=' + new Date().getTime());
        const data = await response.json();
        const storedVersion = localStorage.getItem('appVersion');
        
        if (storedVersion && storedVersion !== data.version && data.version !== dismissedVersion) {
          setNewVersionAvailable(true);
          localStorage.setItem('appVersion', data.version);
        } else if (!storedVersion) {
          localStorage.setItem('appVersion', data.version);
        }
      } catch (error) {
        console.error('Error checking for updates:', error);
      }
    }
  }, [dismissedVersion]);

  useEffect(() => {
    checkForUpdates();
    const intervalId = setInterval(checkForUpdates, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, [checkForUpdates, toast]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        console.log('New service worker activated');
        window.location.reload();
      });
    }
  }, []);

  const toggleAdminDashboard = useCallback(() => {
    setShowAdminDashboard(prev => !prev);
  }, []);

  const generateInsight = useCallback(async (reminderId) => {
    const reminder = reminders.find(r => r.id === reminderId);
    if (!reminder) return;

    const requiredCredits = reminder.profiles.length * 10;
    if (credits < requiredCredits) {
      toast({
        title: "Insufficient Credits",
        description: `You need at least ${requiredCredits} credits to generate insights for all profiles.`,
        variant: "destructive",
      });
      return;
    }

    setGeneratingInsights(prev => ({ ...prev, [reminderId]: true }));

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate-insight/${reminderId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      setReminders(prevReminders => 
        prevReminders.map(r => 
          r.id === reminderId 
            ? { 
                ...r, 
                lastInsightGenerated: new Date().toISOString(),
                insights: [
                  {
                    id: data.id,
                    content: data.insight,
                    createdAt: data.createdAt,
                    tweetCount: data.tweetCount,
                    isNew: true,
                    type: 'insight'
                  },
                  ...(r.insights || [])
                ],
                tweets: data.tweets || r.tweets // Update tweets if new ones were fetched
              } 
            : r
        )
      );

      reminder.profiles.forEach(profile => {
        addInsightToProfile(profile.id, {
          id: data.id,
          content: data.insight,
          createdAt: data.createdAt,
          tweetCount: data.tweetCount
        });
        if (data.tweets) {
          addTweetsToProfile(profile.id, data.tweets);
        }
      });

      toast({
        title: "Insight Generated",
        description: "Check the reminder for the new insight.",
        duration: 5000,
      });
    } catch (error) {
      console.error('Error generating insight:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to generate insight. Please try again.",
        variant: "destructive",
      });
    } finally {
      setGeneratingInsights(prev => ({ ...prev, [reminderId]: false }));
    }
  }, [credits, setGeneratingInsights, setReminders, addInsightToProfile, addTweetsToProfile, toast, userId, reminders]);

  useEffect(() => {
    if (user) {
      console.log('Current user ID:', user.id);
    }
  }, [user]);

  useEffect(() => {
    const savedShowSubtext = localStorage.getItem('showSubtext');
    if (savedShowSubtext !== null) {
      setShowSubtext(JSON.parse(savedShowSubtext));
    }
  }, []);

  const toggleSubtext = () => {
    const newShowSubtext = !showSubtext;
    setShowSubtext(newShowSubtext);
    localStorage.setItem('showSubtext', JSON.stringify(newShowSubtext));
  };

  useEffect(() => {
    const loadTimeout = setTimeout(() => {
      if (document.readyState !== 'complete') {
        console.log('App failed to load, forcing reload');
        window.location.reload();
      }
    }, 10000); // 10 seconds timeout

    return () => clearTimeout(loadTimeout);
  }, []);

  // 1. Add handleAddProfile function
  const handleAddProfile = useCallback((profile) => {
    setSelectedProfiles(prev => [...prev, profile]);
    
    if (window.innerWidth < 1024) {
      setIsDrawerOpen(true);
    }
    
    toast({
      title: "Profile Added",
      description: `${profile.displayName || profile.handle} added to tracking list.`,
      duration: 3000,
    });
  }, [setIsDrawerOpen, toast]);

  if (!mounted) {
    return null; // or return a loading spinner
  }

return (
  <div className="flex flex-col min-h-screen bg-background">
    <Toaster position="top-right" />
    
    <UpdateBanner 
      newVersionAvailable={newVersionAvailable} 
      handleUpdate={handleUpdate}
      isOnline={isOnline}
    />
    
    {/* Main content wrapper - centered with max width */}
    <div className="flex-grow w-full max-w-[1200px] mx-auto px-4 sm:px-6">
      <div className="flex flex-col lg:flex-row lg:gap-6 relative py-4">
        {/* Sidebar - now floating and closer to content */}
        <div className="hidden lg:block lg:w-[360px] lg:flex-shrink-0">
          <div className="sticky top-4 max-h-[calc(100vh-2rem)] overflow-y-auto scrollbar-none">
            <Sidebar
              isDarkMode={isDarkMode}
              toggleDarkMode={toggleDarkMode}
              authState={authState}
              safeLogin={safeLogin}
              safeLogout={safeLogout}
              authenticated={authenticated}
              isPushEnabled={isPushEnabled}
              handleEnablePushNotifications={handleEnablePushNotifications}
              showSubtext={showSubtext}
              toggleSubtext={toggleSubtext}
              isAdmin={isAdmin}
              toggleAdminDashboard={toggleAdminDashboard}
              inputProfileToTrack={inputProfileToTrack}
              handleProfileToTrackChange={handleProfileToTrackChange}
              profileInfo={profileInfo}
              addProfile={addProfile}
              selectedProfiles={selectedProfiles}
              removeSelectedProfile={removeSelectedProfile}
              inputDescription={inputDescription}
              handleDescriptionChange={handleDescriptionChange}
              includeReplies={includeReplies}
              handleIncludeRepliesChange={handleIncludeRepliesChange}
              frequency={frequency}
              setFrequency={setFrequency}
              isAddingReminder={isAddingReminder}
              addOrUpdateReminder={addOrUpdateReminder}
              editingReminder={editingReminder}
              setEditingReminder={setEditingReminder}
              setIsEditingInDrawer={setIsEditingInDrawer}
              setIsDrawerOpen={setIsDrawerOpen}
              currentPlaceholderRef={currentPlaceholderRef}
              setInputProfileToTrack={setInputProfileToTrack}
              setProfileInfo={setProfileInfo}
              setInputDescription={setInputDescription}
              setSelectedProfiles={setSelectedProfiles}
            />
          </div>
        </div>

        {/* Mobile top bar */}
        <MobileTopBar
          isDarkMode={isDarkMode}
          toggleDarkMode={toggleDarkMode}
          authState={authState}
          safeLogin={safeLogin}
          safeLogout={safeLogout}
          showSubtext={showSubtext}
          toggleSubtext={toggleSubtext}
          isAdmin={isAdmin}
          toggleAdminDashboard={toggleAdminDashboard}
          isPushEnabled={isPushEnabled}
          handleEnablePushNotifications={handleEnablePushNotifications}
        />

        {/* Main content area */}
        <div className="flex-1 flex flex-col min-h-screen">
          {renderMainContent()}
        </div>
      </div>
    </div>

    {/* Footer - outside main content for full width */}
    <Footer />

    <FloatingButton 
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      setIsEditingInDrawer={setIsEditingInDrawer}
      setEditingReminder={setEditingReminder}
      isEditingInDrawer={isEditingInDrawer}
      isDarkMode={isDarkMode}
      toggleDarkMode={toggleDarkMode}
      authState={authState}
      safeLogin={safeLogin}
      safeLogout={safeLogout}
      authenticated={authenticated}
      isPushEnabled={isPushEnabled}
      handleEnablePushNotifications={handleEnablePushNotifications}
      showSubtext={showSubtext}
      toggleSubtext={toggleSubtext}
      isAdmin={isAdmin}
      toggleAdminDashboard={toggleAdminDashboard}
      inputProfileToTrack={inputProfileToTrack}
      handleProfileToTrackChange={handleProfileToTrackChange}
      profileInfo={profileInfo}
      addProfile={addProfile}
      selectedProfiles={selectedProfiles}
      removeSelectedProfile={removeSelectedProfile}
      inputDescription={inputDescription}
      handleDescriptionChange={handleDescriptionChange}
      includeReplies={includeReplies}
      handleIncludeRepliesChange={handleIncludeRepliesChange}
      frequency={frequency}
      setFrequency={setFrequency}
      isAddingReminder={isAddingReminder}
      addOrUpdateReminder={addOrUpdateReminder}
      editingReminder={editingReminder}
      currentPlaceholderRef={currentPlaceholderRef}
      setInputProfileToTrack={setInputProfileToTrack}
      setProfileInfo={setProfileInfo}
      setInputDescription={setInputDescription}
      setSelectedProfiles={setSelectedProfiles}
    />
    
    <BuyCreditsDialog
      show={showBuyCreditsDialog}
      onClose={() => setShowBuyCreditsDialog(false)}
      usdcAmount={usdcAmount}
      onUsdcAmountChange={handleUsdcAmountChange}
      onBuyWithCrypto={handleBuyCredits}
      onBuyWithStripe={handleStripePayment}
    />
    
    <StripeDialog
      show={showStripeDialog}
      onClose={() => setShowStripeDialog(false)}
      clientSecret={clientSecret}
      amount={usdcAmount}
      onSuccess={() => setShowStripeDialog(false)}
    />
    
    <PushDialog
      show={showPushDialog}
      onClose={() => setShowPushDialog(false)}
      onEnablePush={handleEnablePush}
    />

    <AdminDashboardDialog
      show={showAdminDashboard}
      onClose={() => setShowAdminDashboard(false)}
      user={user}
    />

    <PushNotificationDialog
      show={showPushAfterReminder}
      onClose={() => setShowPushAfterReminder(false)}
      onEnablePush={() => {
        setShowPushAfterReminder(false);
        handleEnablePushNotifications();
      }}
      isDarkMode={isDarkMode}
    />

    {authenticated && (
      <CreditWidget 
        credits={credits}
        monthlySpend={reminders.length > 0 ? calculateMonthlyCreditSpend : undefined}
        onBuyCredits={() => setShowBuyCreditsDialog(true)}
        isPendingTransaction={isPendingTransaction}
        isDarkMode={isDarkMode}
        isLoading={isLoadingCreditData}
      />
    )}
  </div>
);
};

export default TruffleApp;