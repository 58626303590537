import { Skeleton } from "./skeleton"
import { formatCompactNumber } from "../../lib/utils"

function TweetPreview({ tweet, isLoading, error }) {
  if (error) {
    return (
      <div className="w-full p-4 rounded-lg bg-red-50 dark:bg-red-900/10">
        <p className="text-sm text-red-600 dark:text-red-400">
          {error.message || 'Failed to load tweet'}
        </p>
      </div>
    );
  }

  if (isLoading || !tweet) {
    return (
      <div className="w-full space-y-4 p-4 animate-pulse">
        <div className="flex items-center space-x-3">
          <Skeleton className="h-12 w-12 rounded-full" />
          <div className="space-y-2 flex-1">
            <Skeleton className="h-4 w-[140px]" />
            <Skeleton className="h-4 w-[100px]" />
          </div>
        </div>
        <div className="space-y-2">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-[90%]" />
        </div>
        <Skeleton className="h-48 w-full rounded-lg" />
        <div className="flex space-x-4">
          <Skeleton className="h-4 w-16" />
          <Skeleton className="h-4 w-16" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-[300px] p-4">
      {tweet.author && (
        <div className="flex items-start space-x-2 mb-2">
          <img 
            src={tweet.author.profile_image_url} 
            alt={tweet.author.name}
            className="h-10 w-10 rounded-full"
          />
          <div className="flex-1 min-w-0">
            <p className="font-medium truncate">{tweet.author.name}</p>
            <p className="text-sm text-muted-foreground truncate">@{tweet.author.username}</p>
          </div>
        </div>
      )}
      
      <p className="text-sm mb-2 whitespace-pre-wrap">{tweet.text}</p>
      
      {tweet.media && (
        <div className="mb-2 rounded-lg overflow-hidden">
          <img 
            src={tweet.media.url} 
            alt=""
            className="w-full h-auto"
            loading="lazy"
          />
        </div>
      )}

      <div className="flex items-center space-x-4 text-xs text-muted-foreground">
        <span>{formatCompactNumber(tweet.public_metrics.like_count)} likes</span>
        <span>{formatCompactNumber(tweet.public_metrics.retweet_count)} retweets</span>
      </div>
    </div>
  );
}

export default TweetPreview