import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "./ui/dialog";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetDescription } from "./ui/sheet";
import AdminDashboard from './AdminDashboard'; // Make sure this import is correct
import { useMediaQuery } from '../hooks/useMediaQuery';

const AdminDashboardDialog = ({ show, onClose, user }) => {
  const isMobile = useMediaQuery('(max-width: 640px)');

  const DialogOrSheet = isMobile ? Sheet : Dialog;
  const DialogOrSheetContent = isMobile ? SheetContent : DialogContent;
  const DialogOrSheetHeader = isMobile ? SheetHeader : DialogHeader;
  const DialogOrSheetTitle = isMobile ? SheetTitle : DialogTitle;
  const DialogOrSheetDescription = isMobile ? SheetDescription : DialogDescription;

  return (
    <DialogOrSheet open={show} onOpenChange={onClose}>
      <DialogOrSheetContent 
        side={isMobile ? "bottom" : undefined}
        className={`
          ${isMobile ? "h-[90vh]" : "h-[90vh] w-[90vw] max-w-[1200px]"}
          flex flex-col overflow-hidden
        `}
      >
        <DialogOrSheetHeader>
          <DialogOrSheetTitle>Admin Dashboard</DialogOrSheetTitle>
          <DialogOrSheetDescription>
            Manage users and view analytics
          </DialogOrSheetDescription>
        </DialogOrSheetHeader>
        
        <div className="flex-1 overflow-y-auto">
          <AdminDashboard user={user} />
        </div>
      </DialogOrSheetContent>
    </DialogOrSheet>
  );
};

export default AdminDashboardDialog;
