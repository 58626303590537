import { useState, useEffect, useRef } from 'react'

function TextScramble({ finalText, isLoading, reminderId }) {
  const [displayText, setDisplayText] = useState('')
  const [phase, setPhase] = useState(() => {
    if (finalText && localStorage.getItem(`scramble-complete-${reminderId}`)) {
      return 'complete'
    }
    return 'loading'
  })
  const chars = '!<>-_\\/[]{}—=+*^?#________'
  const frameRequest = useRef()
  const revealIndex = useRef(0)
  const previousText = useRef('')
  const frame = useRef(0)

  const randomChar = () => chars[Math.floor(Math.random() * chars.length)]

  // Consistent scramble effect for both states
  const scrambleText = (text, revealedIndex = -1) => {
    return text
      .split('')
      .map((char, index) => {
        if (char === ' ') return ' '
        if (index <= revealedIndex) return char
        return (frame.current + index) % 3 === 0 ? randomChar() : displayText[index] || randomChar()
      })
      .join('')
  }

  // Reset animation when text changes
  useEffect(() => {
    if (finalText && finalText !== previousText.current) {
      if (!localStorage.getItem(`scramble-complete-${reminderId}`)) {
        previousText.current = finalText
        revealIndex.current = 0
        frame.current = 0
        setPhase('revealing')
        setDisplayText('') // Clear display text to start fresh
      } else {
        setDisplayText(finalText)
        setPhase('complete')
      }
    }
  }, [finalText, reminderId])

  // Handle loading animation
  useEffect(() => {
    if (phase !== 'loading' || !isLoading) return
    
    if (localStorage.getItem(`scramble-complete-${reminderId}`)) {
      return
    }
    
    const loadingText = 'Loading reminder...'
    
    const animate = () => {
      frame.current++
      setDisplayText(scrambleText(loadingText))
      frameRequest.current = setTimeout(() => requestAnimationFrame(animate), 50)
    }
    
    animate()
    return () => {
      clearTimeout(frameRequest.current)
      cancelAnimationFrame(frameRequest.current)
    }
  }, [phase, isLoading, reminderId])

  // Handle reveal animation
  useEffect(() => {
    if (phase !== 'revealing' || !finalText) return

    let currentIndex = revealIndex.current
    
    const update = () => {
      frame.current++
      setDisplayText(scrambleText(finalText, currentIndex))
      
      if (currentIndex < finalText.length - 1) {
        currentIndex++
        revealIndex.current = currentIndex
        frameRequest.current = setTimeout(update, 50)
      } else {
        setPhase('complete')
        localStorage.setItem(`scramble-complete-${reminderId}`, 'true')
      }
    }
    
    frameRequest.current = setTimeout(update, 50)
    
    return () => {
      clearTimeout(frameRequest.current)
    }
  }, [phase, finalText, reminderId])

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (frameRequest.current) {
        clearTimeout(frameRequest.current)
        cancelAnimationFrame(frameRequest.current)
      }
    }
  }, [])

  return (
    <span 
      className={`transition-opacity duration-200 ${
        phase !== 'complete' ? 'text-muted-foreground' : ''
      }`}
      style={{
        display: 'inline-block',
        minHeight: '1.5em',
        minWidth: '200px',
        fontFamily: 'monospace' // Add monospace for better scramble effect
      }}
    >
      {phase === 'complete' ? finalText : displayText || ' '}
    </span>
  )
}

export default TextScramble