import { useState, useCallback, useEffect, useRef } from 'react'
import { useToast } from "../components/ui/use-toast"

function useCredits({ userId, isOnline }) {
  const [credits, setCredits] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isPendingTransaction, setIsPendingTransaction] = useState(false)
  const eventSourceRef = useRef(null)
  const { toast } = useToast()
  const hasValidUserId = useRef(false)
  const reconnectTimeoutRef = useRef(null)
  const isInitialMount = useRef(true)

  // Set up SSE connection
  useEffect(() => {
    // Skip the warning log on initial mount
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (!userId) return;
    }

    if (!userId || !isOnline) {
      return;
    }

    // Set flag that we have a valid userId
    hasValidUserId.current = true;

    const setupEventSource = () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      
      // Close existing connection if any
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }

      const eventSource = new EventSource(
        `${apiUrl}/api/credits-stream?userId=${userId}`
      );

      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (typeof data.credits === 'number') {
            setCredits(data.credits);
            setIsPendingTransaction(false);
            setIsLoading(false);
          }
        } catch (error) {
          console.error('Error parsing SSE message:', error);
        }
      };

      eventSource.onerror = (error) => {
        console.error('EventSource error:', error);
        eventSource.close();
        
        // Only attempt reconnect if we still have a valid userId
        if (hasValidUserId.current) {
          setTimeout(setupEventSource, 1000);
        }
      };

      eventSourceRef.current = eventSource;
    };

    setupEventSource();

    // Cleanup function
    return () => {
      console.log('Cleaning up SSE connection for user:', userId);
      hasValidUserId.current = false;
      clearTimeout(reconnectTimeoutRef.current);
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, [userId, isOnline]);

  // Handle credit purchases
  const handleBuyCredits = useCallback(async (amount) => {
    if (!isOnline) {
      toast({
        title: "Error",
        description: "Cannot purchase credits while offline",
        variant: "destructive"
      })
      return
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-charge`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount, userId })
      })

      if (!response.ok) throw new Error('Failed to create charge')
      
      const data = await response.json()
      
      setIsPendingTransaction(true)
      
      toast({
        title: "Processing Payment",
        description: "Your payment is being processed. Credits will be added shortly."
      })

      window.open(data.hostedUrl, '_blank', 'noopener,noreferrer')

      return data
    } catch (error) {
      console.error('Error creating charge:', error)
      toast({
        title: "Error",
        description: "Failed to process payment. Please try again.",
        variant: "destructive"
      })
      throw error
    }
  }, [userId, isOnline, toast])

  // Handle Stripe payments
  const handleStripePayment = useCallback(async (amount) => {
    if (!isOnline) {
      toast({
        title: "Error",
        description: "Cannot purchase credits while offline",
        variant: "destructive"
      });
      return null;
    }

    if (!amount || amount <= 0) {
      toast({
        title: "Error",
        description: "Please enter a valid amount",
        variant: "destructive"
      });
      return null;
    }

    try {
      console.log('Creating payment intent for amount:', amount);
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-payment-intent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          amount: Number(amount),
          userId 
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to create payment intent');
      }
      
      if (!data.clientSecret) {
        throw new Error('No client secret received from server');
      }

      console.log('Successfully created payment intent');
      return data.clientSecret;
    } catch (error) {
      console.error('Error creating payment intent:', error);
      toast({
        title: "Error",
        description: "Failed to initialize payment. Please try again.",
        variant: "destructive"
      });
      return null;
    }
  }, [userId, isOnline, toast]);

  // Check credit sufficiency
  const hasEnoughCredits = useCallback((required) => {
    if (credits < required) {
      toast({
        title: "Insufficient Credits",
        description: `You need ${required} credits for this operation.`,
        variant: "destructive"
      })
      return false
    }
    return true
  }, [credits, toast])

  // Handle credit deduction
  const deductCredits = useCallback(async (amount, operation) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/credits/deduct`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          userId,
          amount,
          operation: typeof operation === 'function' ? 'CHECK_UPDATES' : operation.name
        })
      });

      if (!response.ok) throw new Error('Failed to deduct credits');
      
      // Execute operation after successful deduction
      if (typeof operation === 'function') {
        await operation();
      }
      
      return true;
    } catch (error) {
      // Attempt to refund credits
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/api/credits/refund`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            userId,
            amount,
            operation: typeof operation === 'function' ? 'CHECK_UPDATES' : operation.name
          })
        });
      } catch (refundError) {
        console.error('Refund error:', refundError);
      }

      throw error;
    }
  }, [userId]);

  // Cancel pending transaction after timeout
  useEffect(() => {
    if (isPendingTransaction) {
      const timeout = setTimeout(() => {
        setIsPendingTransaction(false);
        toast({
          title: "Transaction Timeout",
          description: "The transaction has timed out. Please try again or contact support.",
          variant: "destructive"
        });
      }, 600000); // 10 minutes

      return () => clearTimeout(timeout);
    }
  }, [isPendingTransaction, toast]);

  // Add back the fetchCredits function
  const fetchCredits = useCallback(async () => {
    if (!userId || !isOnline) {
      console.log('Skipping credit fetch:', { userId, isOnline });
      return;
    }
    
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      console.log('Fetching credits from:', apiUrl, 'for user:', userId);
      
      const response = await fetch(`${apiUrl}/api/credits?userId=${userId}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch credits');
      }
      
      const data = await response.json();
      console.log('Credits fetch successful:', data);
      
      if (typeof data.credits === 'number') {
        setCredits(data.credits);
        setIsLoading(false);
      }
      
      return data.credits;
    } catch (error) {
      console.error('Error fetching credits:', error);
      setIsLoading(false);
    }
  }, [userId, isOnline]);

  return {
    credits,
    isLoading,
    isPendingTransaction,
    handleBuyCredits,
    handleStripePayment,
    hasEnoughCredits,
    deductCredits,
    refreshCredits: fetchCredits
  }
}

export default useCredits