import React from 'react';
import GeometricPulseLoader from './GeometricPulseLoader'

function ProfileAnalysisSkeleton() {
    return (
        <div className="w-full max-w-3xl mx-auto p-4 space-y-4">
            <div className="flex flex-col items-center justify-center min-h-[400px]">
                <GeometricPulseLoader className="mb-4" />
                <p className="text-muted-foreground text-sm animate-pulse">
                    Generating profile analysis...
                </p>
            </div>
        </div>
    )
}

export default ProfileAnalysisSkeleton;
