import React from 'react';
import { ThemeProvider } from './contexts/ThemeContext';
import { ProfileProvider } from './contexts/ProfileContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RateLimitProvider } from './hooks/useTweetPreview';
import TruffleApp from './components/TruffleApp';
import './globals.css';

// Create a client
const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RateLimitProvider>
        <ProfileProvider>
          <ThemeProvider>
            <TruffleApp />
          </ThemeProvider>
        </ProfileProvider>
      </RateLimitProvider>
    </QueryClientProvider>
  );
}

export default App;
