import { Target, Users } from 'lucide-react'

export function EmptyState({ 
  type = 'reminder',
  title,
  description
}) {
  return (
    <div className="relative block w-full rounded-lg border-2 border-dashed border-border p-12 text-center">
      <div className="flex flex-col items-center justify-center space-y-4">
        {type === 'reminder' ? (
          <Target className="mx-auto h-12 w-12 text-muted-foreground/50" />
        ) : (
          <Users className="mx-auto h-12 w-12 text-muted-foreground/50" />
        )}
        <div className="space-y-2">
          <h3 className="text-xl font-semibold">{title}</h3>
          <p className="text-muted-foreground text-sm max-w-sm mx-auto">
            {description}
          </p>
        </div>
      </div>
      
      {/* Decorative background pattern */}
      <div className="absolute inset-0 -z-10 h-full w-full bg-background">
        <div className="absolute h-full w-full opacity-50" 
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, var(--border-color) 1px, transparent 0)`,
            backgroundSize: '24px 24px'
          }}
        />
      </div>
    </div>
  )
} 