import React from 'react'

function Footer() {
  return (
    <div className="w-full mt-auto">
      <div 
        className="w-full h-[120px] bg-repeat-x"
        style={{ 
          backgroundImage: 'url(/truffle-banner.png)',
          backgroundSize: 'auto 120px'
        }}
      />
    </div>
  )
}

export default Footer