import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog"
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "./ui/sheet"
import { ScrollArea } from "./ui/scroll-area"
import { Button } from "./ui/button"

const categories = [
  {
    title: "Investment/Trading Signals",
    prompts: [
      "when they discuss specific market positions or trades",
      "if their sentiment changes about {asset/project}",
      "about market bottoms or tops discussions",
      "their commentary on macro economic conditions",
      "when they share portfolio allocations",
      "mentions of 'accumulating' or 'selling' assets",
      "their risk management strategies"
    ]
  },
  {
    title: "Technical Analysis",
    prompts: [
      "when they share chart analysis",
      "discussions of specific price levels or targets",
      "their takes on technical indicators (RSI, MACD, etc.)",
      "mentions of trend reversals or breakouts",
      "their thoughts on trading volume or liquidity"
    ]
  },
  {
    title: "Project Research",
    prompts: [
      "detailed analysis of new projects",
      "discussions about project fundamentals",
      "mentions of team changes or developer activity",
      "their takes on tokenomics or monetary policy",
      "commentary on project partnerships",
      "about protocol upgrades or changes"
    ]
  },
  {
    title: "Alpha/Edge Seeking",
    prompts: [
      "novel insights or unique perspectives they share",
      "discussions of undervalued or overlooked opportunities",
      "mentions of 'alpha' or exclusive information",
      "emerging trends or narratives they spot",
      "when they change their mind about previous positions"
    ]
  },
  {
    title: "Network Analysis",
    prompts: [
      "who they're newly following or engaging with",
      "when they start discussing new projects or people",
      "patterns in their topic engagement",
      "significant changes in their social behavior",
      "their participation in specific communities"
    ]
  },
  {
    title: "Risk Management",
    prompts: [
      "potential risks or threats they discuss",
      "strong warnings or cautions they express",
      "mentions of scams or security issues",
      "protective measures they recommend",
      "their risk assessment of specific projects"
    ]
  },
  {
    title: "Meta Strategy",
    prompts: [
      "their personal investment strategies",
      "frameworks or mental models they share",
      "how they analyze opportunities",
      "mistakes or learnings they discuss",
      "changes in their long-term thesis"
    ]
  },
  {
    title: "Market Psychology",
    prompts: [
      "their commentary on market sentiment",
      "discussions about crowd psychology",
      "mentions of FOMO or market euphoria",
      "contrarian positions they take",
      "their emotional state regarding markets"
    ]
  },
  {
    title: "Regulatory/Legal",
    prompts: [
      "regulatory developments they discuss",
      "legal challenges they mention",
      "their commentary on compliance issues",
      "regulatory risks they highlight",
      "their response to policy changes"
    ]
  },
  {
    title: "Innovation Tracking",
    prompts: [
      "new technologies or innovations they discuss",
      "tools they start experimenting with",
      "their takes on emerging tech trends",
      "interesting new projects they discover",
      "their predictions about future developments"
    ]
  }
]

function InspirationContent({ onSelectPrompt }) {
  return (
    <ScrollArea className="h-[80vh] md:h-[60vh]">
      <div className="space-y-8 p-4">
        {categories.map((category, i) => (
          <div key={i} className="space-y-4">
            <h3 className="font-semibold text-xl tracking-tight text-primary/90 
              border-b border-border/40 pb-2 mb-1"
            >
              {category.title}
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              {category.prompts.map((prompt, j) => (
                <Button
                  key={j}
                  variant="ghost"
                  className="group justify-start h-auto py-3.5 px-4 text-left whitespace-normal
                    bg-gray-50/90 hover:bg-gray-100 
                    dark:bg-gray-900/50 dark:hover:bg-gray-800/80
                    rounded-lg text-base font-normal leading-relaxed
                    border border-gray-200/70 hover:border-gray-300
                    dark:border-gray-700/70 dark:hover:border-gray-600
                    shadow-sm hover:shadow-md
                    transition-all duration-200 ease-in-out
                    text-gray-700 hover:text-gray-900
                    dark:text-gray-200 dark:hover:text-white
                    focus:outline-none focus:ring-2 focus:ring-primary/20
                    hover:scale-[1.02]"
                  onClick={() => onSelectPrompt(prompt)}
                >
                  <div className="flex flex-col space-y-1">
                    <span className="line-clamp-3">{prompt}</span>
                    <span className="text-xs text-primary/60 group-hover:text-primary/80 transition-colors">
                      Use this prompt
                    </span>
                  </div>
                </Button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </ScrollArea>
  )
}

function InspirationDialog({ show, onClose, onSelectPrompt, isMobile = false }) {
  if (isMobile) {
    return (
      <Sheet open={show} onOpenChange={onClose}>
        <SheetContent side="bottom" className="h-[90vh] px-3">
          <SheetHeader className="pb-4 border-b border-border/40">
            <SheetTitle className="text-xl font-semibold tracking-tight">
              Choose Your Tracking Style
            </SheetTitle>
            <p className="text-sm text-muted-foreground mt-1">
              Select a prompt to start tracking or use it as inspiration
            </p>
          </SheetHeader>
          <InspirationContent onSelectPrompt={onSelectPrompt} />
        </SheetContent>
      </Sheet>
    )
  }

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader className="pb-4 border-b border-border/40">
          <DialogTitle className="text-xl font-semibold tracking-tight">
            Choose Your Tracking Style
          </DialogTitle>
          <p className="text-sm text-muted-foreground mt-1">
            Select a prompt to start tracking or use it as inspiration
          </p>
        </DialogHeader>
        <InspirationContent onSelectPrompt={onSelectPrompt} />
      </DialogContent>
    </Dialog>
  )
}

export default InspirationDialog 