import React, { useState, useCallback } from 'react';
import { Card, CardContent } from "./ui/card";
import { Progress } from "./ui/progress";
import { Sparkles, MessageSquare, ChevronLeft, ChevronRight, Database, Lightbulb } from 'lucide-react';
import { Button } from "./ui/button";
import { useMediaQuery } from '../hooks/useMediaQuery';
import { Dialog, DialogContent } from "./ui/dialog";
import { Sheet, SheetContent } from "./ui/sheet";
import ProfileDetails from './ProfileDetails';
import { cn } from '../lib/utils';
import { useSwipeable } from 'react-swipeable';

const formatNumber = (num) => new Intl.NumberFormat().format(num);

// Example analyses data
const exampleAnalyses = {
  '@naval': {
    content: `### Overview
🎯 Naval is a transformative thought leader who combines philosophical wisdom with practical entrepreneurship insights, reaching millions through concise, memorable statements that challenge conventional thinking.

### Mindset
🧠 Demonstrates exceptional first-principles thinking and intellectual sovereignty. Values truth-seeking over social approval, consistently exploring unconventional ideas while maintaining rational skepticism.

### Communication
📢 Masters the art of pithy, memorable statements that encapsulate complex ideas. Favors paradoxical insights that challenge assumptions and trigger deeper thinking.

### Behaviour
🔄 Shows consistent patterns of deep reflection followed by concentrated periods of sharing insights. Actively engages with ideas over social validation.

### Network
🌐 Maintains strong connections with tech founders, philosophers, and independent thinkers. Frequently interacts with cryptocurrency innovators and mental model experts.

### Influence
💫 Significant impact on tech entrepreneurship and personal development spheres. Shapes conversations around wealth creation, technology, and personal sovereignty.

### Predictions
🔮 Likely to expand focus on AI ethics and decentralized systems. May develop more structured educational content around wealth creation and personal development.

### Insights
💡 Uniquely combines Eastern philosophy with Western capitalism, creating frameworks for modern success that transcend traditional business advice.

### Needs
🎯 Seeks platforms for sharing complex ideas simply. Values tools for organizing and sharing knowledge efficiently.

### Pains
😤 Frequently encounters misinterpretation of nuanced ideas. Struggles with the limitations of current social platforms for deep discussions.

### Opportunities
🚀 Potential for developing comprehensive educational platforms. Could expand influence in AI ethics and decentralized governance.

### What you can learn from them
• Master the art of packaging complex ideas into memorable statements
• Build leverage through specific knowledge and technology
• Develop independent thinking while maintaining intellectual humility`,
    topics: [
      { name: 'Wealth Creation', weight: 85, sentiment: 0.8, description: "Focus on building wealth through leverage and specific knowledge", relatedTweets: [0, 1, 4] },
      { name: 'Philosophy', weight: 75, sentiment: 0.6, description: "Integration of Eastern and Western philosophical principles", relatedTweets: [2, 5, 8] },
      { name: 'Technology', weight: 65, sentiment: 0.7, description: "Emphasis on leveraging technology for wealth creation", relatedTweets: [3, 6, 9] },
      { name: 'Personal Development', weight: 60, sentiment: 0.9, description: "Frameworks for personal growth and mental models", relatedTweets: [7, 10, 12] },
      { name: 'Entrepreneurship', weight: 55, sentiment: 0.7, description: "Insights on building successful businesses", relatedTweets: [11, 13, 15] },
      { name: 'Decision Making', weight: 50, sentiment: 0.8, description: "Mental models and decision-making frameworks", relatedTweets: [14, 16, 17] }
    ],
    temporal: {
      patterns: [
        { period: '2024-01-01', activity: 20, sentiment: 0.6, dominantTopics: ['Philosophy', 'Wealth'], contextualEvents: "New Year Reflections" },
        { period: '2024-01-02', activity: 45, sentiment: 0.7, dominantTopics: ['Technology', 'Wealth'], contextualEvents: "Tech Trends" },
        { period: '2024-01-03', activity: 85, sentiment: 0.8, dominantTopics: ['Entrepreneurship'], contextualEvents: "Startup Insights" }
      ]
    },
    overview: {
      metrics: {
        philosophicalVsPractical: {
          score: 90,
          explanation: "Balances deep philosophical insights with practical business advice",
          evidence: "Frequent tweets combining ancient wisdom with modern business applications",
          impact: "Creates unique, actionable frameworks for success"
        },
        individualVsCollective: {
          score: 85,
          explanation: "Emphasizes individual sovereignty while acknowledging network effects",
          evidence: "Consistent focus on personal development alongside collaborative opportunities",
          impact: "Inspires both personal growth and effective collaboration"
        },
        longTermVsShortTerm: {
          score: 95,
          explanation: "Strong focus on long-term thinking and wealth creation",
          evidence: "Regular emphasis on building lasting wealth over quick gains",
          impact: "Influences followers to adopt sustainable growth strategies"
        }
      }
    }
  },
  '@paulg': {
    content: `### Overview
🎯 Paul Graham exemplifies clear thinking in technology and startups, combining deep technical knowledge with practical wisdom about building successful companies.

### Mindset
🧠 Analytical and systematic thinker who values empirical evidence and pattern recognition in startup success.

### Communication
📢 Writing style prioritizes clarity and precision. Uses historical examples and Socratic questioning to illustrate points.

### Behaviour
🔄 Consistently produces well-reasoned essays and threads that build on previous insights. Engages thoughtfully with technical and startup queries.

### Network
🌐 Central figure in startup ecosystem, connected to successful founders and investors. Strong ties to YC community.

### Influence
💫 Shapes how founders think about startups and technology. Significant impact on startup methodology and culture.

### Predictions
🔮 Will likely increase focus on AI's impact on startups. May expand writing on educational reform and remote work evolution.

### Insights
💡 Uniquely able to identify patterns in successful startups and translate them into actionable frameworks.

### Needs
🎯 Seeks efficient ways to share startup knowledge at scale. Values platforms for long-form content sharing.

### Pains
😤 Frequently encounters misunderstanding of nuanced startup advice. Struggles with oversimplification of complex ideas.

### Opportunities
🚀 Potential to develop new frameworks for AI-era startups. Could expand influence in tech education reform.

### What you can learn from them
• Master the art of clear thinking and writing
• Develop pattern recognition in startup success
• Build systematic approaches to complex problems`,
    topics: [
      { name: 'Startups', weight: 90, sentiment: 0.8, description: "Deep insights into startup creation and growth", relatedTweets: [0, 1, 4] },
      { name: 'Technology', weight: 75, sentiment: 0.7, description: "Analysis of technological trends and impact", relatedTweets: [2, 5, 8] },
      { name: 'Programming', weight: 70, sentiment: 0.6, description: "Software development principles and practices", relatedTweets: [3, 6, 9] },
      { name: 'Innovation', weight: 65, sentiment: 0.9, description: "Patterns in successful innovation", relatedTweets: [7, 10, 12] },
      { name: 'Education', weight: 60, sentiment: 0.7, description: "Thoughts on learning and education reform", relatedTweets: [11, 13, 15] },
      { name: 'Writing', weight: 55, sentiment: 0.8, description: "Essays and clear communication", relatedTweets: [14, 16, 17] }
    ],
    temporal: {
      patterns: [
        { period: '2024-01-01', activity: 15, sentiment: 0.7, dominantTopics: ['Startups'], contextualEvents: "YC Batch Review" },
        { period: '2024-01-02', activity: 25, sentiment: 0.8, dominantTopics: ['Technology'], contextualEvents: "Tech Analysis" },
        { period: '2024-01-03', activity: 75, sentiment: 0.9, dominantTopics: ['Innovation'], contextualEvents: "Startup Trends" },
        { period: '2024-01-04', activity: 85, sentiment: 0.6, dominantTopics: ['Programming'], contextualEvents: "Coding Insights" },
        { period: '2024-01-05', activity: 65, sentiment: 0.5, dominantTopics: ['Society'], contextualEvents: "Social Impact" },
        { period: '2024-01-06', activity: 45, sentiment: 0.7, dominantTopics: ['Startups', 'Tech'], contextualEvents: "Industry Review" }
      ]
    },
    overview: {
      metrics: {
        practicalVsTheoretical: {
          score: 95,
          explanation: "Emphasizes real-world application over pure theory"
        },
        startupVsCorporate: {
          score: 90,
          explanation: "Strong focus on early-stage companies and startup thinking"
        },
        innovationVsTradition: {
          score: 85,
          explanation: "Balances novel approaches with proven patterns"
        },
        technicalVsBusiness: {
          score: 92,
          explanation: "Combines deep technical knowledge with business acumen"
        },
        teachingVsExecuting: {
          score: 88,
          explanation: "Shares knowledge while actively building and investing"
        }
      }
    }
  },
  '@balajis': {
    content: `### Overview
🎯 Balaji combines technological insight with societal analysis, pioneering concepts around network states and decentralized governance while maintaining deep crypto expertise.

### Mindset
🧠 Forward-thinking systems architect who excels at identifying macro trends. Shows strong conviction in contrarian viewpoints backed by detailed analysis.

### Communication
📢 Employs detailed frameworks and comprehensive mental models. Combines technical depth with broad societal implications in long-form content.

### Behaviour
🔄 Produces extensive analytical threads and detailed technological forecasts. Consistently challenges conventional wisdom with data-driven insights.

### Network
🌐 Connected to leading crypto innovators, tech founders, and forward-thinking investors. Strong ties in both Silicon Valley and global crypto communities.

### Influence
💫 Shapes discussions around future of governance, technology, and society. Pioneer in cryptocurrency thought leadership and network state concept.

### Predictions
🔮 Will likely expand network state framework development. May increase focus on biotech and health optimization technologies.

### Insights
💡 Uniquely synthesizes technological, societal, and economic trends to predict and shape future developments.

### Needs
🎯 Seeks platforms for complex system visualization. Values tools for coordinating decentralized communities.

### Pains
😤 Faces challenges in communicating complex systemic changes. Encounters resistance to radical technological predictions.

### Opportunities
🚀 Potential to develop comprehensive frameworks for network states. Could expand influence in biotech and health optimization.

### What you can learn from them
• Master the art of systems thinking and trend analysis
• Develop frameworks for understanding societal change
• Build conviction in well-researched contrarian views`,
    topics: [
      { name: 'Network States', weight: 90, sentiment: 0.8, description: "Development of new governance models", relatedTweets: [0, 1, 4] },
      { name: 'Cryptocurrency', weight: 85, sentiment: 0.7, description: "Analysis of crypto ecosystems and future", relatedTweets: [2, 5, 8] },
      { name: 'Biotech', weight: 80, sentiment: 0.9, description: "Health optimization and biotechnology", relatedTweets: [3, 6, 9] },
      { name: 'Future', weight: 75, sentiment: 0.6, description: "Predictions and trend analysis", relatedTweets: [7, 10, 12] },
      { name: 'Education', weight: 70, sentiment: 0.7, description: "Remote education and credentialing", relatedTweets: [11, 13, 15] },
      { name: 'Governance', weight: 65, sentiment: 0.8, description: "Decentralized governance systems", relatedTweets: [14, 16, 17] }
    ],
    temporal: {
      patterns: [
        { period: '2024-01-01', activity: 40, sentiment: 0.7, dominantTopics: ['Network States'], contextualEvents: "Governance Analysis" },
        { period: '2024-01-02', activity: 65, sentiment: 0.8, dominantTopics: ['Cryptocurrency'], contextualEvents: "Crypto Trends" },
        { period: '2024-01-03', activity: 80, sentiment: 0.9, dominantTopics: ['Biotech'], contextualEvents: "Health Innovation" }
      ]
    },
    overview: {
      metrics: {
        futuristicVsPresent: {
          score: 95,
          explanation: "Strong focus on future scenarios and preparations",
          evidence: "Regular detailed threads about future societal structures",
          impact: "Influences long-term thinking and preparation strategies"
        },
        technicalVsSocietal: {
          score: 85,
          explanation: "Balances technical innovation with societal implications",
          evidence: "Combines crypto expertise with governance frameworks",
          impact: "Bridges technical and social aspects of innovation"
        },
        decentralizedVsCentralized: {
          score: 90,
          explanation: "Strong emphasis on decentralized systems and governance",
          evidence: "Consistent advocacy for network states and crypto",
          impact: "Shapes discourse around future governance models"
        }
      }
    }
  },
  '@sama': {
    content: `### Overview
🎯 Sam Altman demonstrates exceptional leadership in AI development while maintaining a balanced perspective on technological progress and its implications.

### Mindset
🧠 Strategic thinker focused on long-term impact. Combines optimism about technological progress with careful consideration of risks.

### Communication
📢 Clear, measured communication style. Effectively balances technical depth with accessible explanations of complex topics.

### Behaviour
🔄 Maintains consistent, thoughtful public presence. Engages constructively with both supporters and critics of AI development.

### Network
🌐 Deeply connected in AI research community and tech industry. Strong relationships with key AI researchers and tech leaders.

### Influence
💫 Shapes global conversation around AI development and safety. Significant impact on AI policy and industry direction.

### Predictions
🔮 Will likely increase focus on AI safety frameworks. May expand involvement in AI governance and policy.

### Insights
💡 Uniquely positioned to understand both technical and societal implications of AI development.

### Needs
🎯 Seeks balanced approaches to AI development. Values frameworks for responsible innovation.

### Pains
😤 Navigates complex challenges of AI safety and development. Faces pressure from various stakeholders with competing interests.

### Opportunities
🚀 Potential to shape global AI governance frameworks. Could expand influence in AI education and accessibility.

### What you can learn from them
• Balance optimism with responsible development
• Navigate complex stakeholder relationships
• Maintain clear communication during rapid change`,
    topics: [
      { name: 'Artificial Intelligence', weight: 95, sentiment: 0.8, description: "AI development and implications", relatedTweets: [0, 1, 4] },
      { name: 'AI Safety', weight: 85, sentiment: 0.7, description: "Responsible AI development practices", relatedTweets: [2, 5, 8] },
      { name: 'Technology', weight: 80, sentiment: 0.9, description: "Broader tech industry trends", relatedTweets: [3, 6, 9] },
      { name: 'Innovation', weight: 75, sentiment: 0.8, description: "Startup and innovation insights", relatedTweets: [7, 10, 12] },
      { name: 'Policy', weight: 70, sentiment: 0.6, description: "Tech policy and governance", relatedTweets: [11, 13, 15] },
      { name: 'Future', weight: 65, sentiment: 0.7, description: "Long-term technological impact", relatedTweets: [14, 16, 17] }
    ],
    temporal: {
      patterns: [
        { period: '2024-01-01', activity: 25, sentiment: 0.7, dominantTopics: ['AI Safety'], contextualEvents: "Safety Framework" },
        { period: '2024-01-02', activity: 35, sentiment: 0.8, dominantTopics: ['Innovation'], contextualEvents: "Tech Development" },
        { period: '2024-01-03', activity: 80, sentiment: 0.9, dominantTopics: ['Policy'], contextualEvents: "AI Governance" }
      ]
    },
    overview: {
      metrics: {
        progressVsSafety: {
          score: 85,
          explanation: "Balances rapid progress with safety considerations",
          evidence: "Regular updates on AI capabilities alongside safety measures",
          impact: "Sets industry standard for responsible AI development"
        },
        technicalVsPolicy: {
          score: 80,
          explanation: "Combines technical expertise with policy awareness",
          evidence: "Engagement with both technical and policy stakeholders",
          impact: "Bridges gap between technical and policy communities"
        },
        openVsControlled: {
          score: 75,
          explanation: "Balances transparency with responsible development",
          evidence: "Selective sharing of capabilities and progress",
          impact: "Influences industry approach to AI development"
        }
      }
    }
  },
  '@pmarca': {
    content: `### Overview
🎯 Marc Andreessen demonstrates exceptional insight into technology markets and innovation, combining deep industry knowledge with contrarian thinking.

### Mindset
🧠 Bold, contrarian thinker with strong conviction. Values technological optimism and market-driven solutions.

### Communication
📢 Direct, often provocative communication style. Uses historical analogies and detailed arguments to support positions.

### Behaviour
🔄 High-volume engagement during key market events. Consistently challenges conventional wisdom with detailed analysis.

### Network
🌐 Deeply connected in venture capital and tech industry. Strong relationships with founders and industry leaders.

### Influence
💫 Shapes venture capital and tech industry direction. Significant impact on startup ecosystem and innovation trends.

### Predictions
🔮 Will likely increase focus on AI and software transformation. May expand analysis of regulatory environment.

### Insights
💡 Uniquely combines historical knowledge with future vision to identify technological trends.

### Needs
🎯 Seeks platforms for sharing detailed analysis. Values tools for tracking technological trends.

### Pains
😤 Faces resistance to contrarian viewpoints. Navigates complex regulatory and market challenges.

### Opportunities
🚀 Potential to shape AI investment frameworks. Could expand influence in tech policy discussions.

### What you can learn from them
• Develop strong conviction through deep analysis
• Master contrarian thinking methodology
• Build comprehensive market understanding`,
    topics: [
      { name: 'Technology', weight: 90, sentiment: 0.8, description: "Analysis of tech industry trends", relatedTweets: [0, 1, 4] },
      { name: 'Venture Capital', weight: 85, sentiment: 0.7, description: "Investment strategies and insights", relatedTweets: [2, 5, 8] },
      { name: 'Software', weight: 80, sentiment: 0.9, description: "Software industry transformation", relatedTweets: [3, 6, 9] },
      { name: 'Innovation', weight: 75, sentiment: 0.8, description: "Technological innovation patterns", relatedTweets: [7, 10, 12] },
      { name: 'Markets', weight: 70, sentiment: 0.6, description: "Market analysis and trends", relatedTweets: [11, 13, 15] },
      { name: 'Policy', weight: 65, sentiment: 0.7, description: "Tech policy and regulation", relatedTweets: [14, 16, 17] }
    ],
    temporal: {
      patterns: [
        { period: '2024-01-01', activity: 30, sentiment: 0.7, dominantTopics: ['Technology'], contextualEvents: "Tech Analysis" },
        { period: '2024-01-02', activity: 45, sentiment: 0.8, dominantTopics: ['Venture Capital'], contextualEvents: "Investment Insights" },
        { period: '2024-01-03', activity: 85, sentiment: 0.9, dominantTopics: ['Innovation'], contextualEvents: "Market Trends" }
      ]
    },
    overview: {
      metrics: {
        disruptiveVsIncremental: {
          score: 95,
          explanation: "Strong preference for disruptive innovation",
          evidence: "Consistent focus on transformative technologies",
          impact: "Influences investment trends in venture capital"
        },
        marketVsRegulation: {
          score: 90,
          explanation: "Emphasizes market-driven solutions",
          evidence: "Regular criticism of regulatory constraints",
          impact: "Shapes discourse around tech regulation"
        },
        technicalVsFinancial: {
          score: 85,
          explanation: "Balances technical and financial analysis",
          evidence: "Comprehensive market and technology assessment",
          impact: "Influences both technical and investment decisions"
        }
      }
    }
  },
  '@AOC': {
    content: `### Overview
🎯 Alexandria Ocasio-Cortez represents a new generation of political leadership, combining grassroots activism with policy expertise and effective digital communication.

### Mindset
🧠 Progressive problem-solver focused on systemic change. Combines idealistic vision with practical policy solutions.

### Communication
📢 Masters modern digital communication. Effectively translates complex policy into accessible narratives using social media and direct engagement.

### Behaviour
🔄 Maintains high engagement with constituents and online community. Regularly shifts between local issues and national policy discussions.

### Network
🌐 Strong connections with progressive lawmakers, climate activists, and grassroots organizations. Builds coalitions across progressive causes.

### Influence
💫 Shapes progressive policy discourse and mobilizes young voters. Significant impact on climate and economic policy discussions.

### Predictions
🔮 Will likely expand focus on climate legislation and economic reform. May develop new approaches to digital constituent engagement.

### Insights
💡 Uniquely combines policy expertise with digital-native communication strategies.

### Needs
🎯 Seeks platforms for constituent engagement. Values tools for policy education and mobilization.

### Pains
😤 Faces intense scrutiny and opposition. Navigates challenges of maintaining progressive vision within institutional constraints.

### Opportunities
🚀 Potential to shape new models of digital democracy. Could expand influence in climate and economic policy.

### What you can learn from them
• Master modern political communication
• Build effective coalition strategies
• Balance idealism with practical policy`,
    topics: [
      { name: 'Climate Policy', weight: 90, sentiment: 0.8, description: "Green New Deal and environmental initiatives", relatedTweets: [0, 1, 4] },
      { name: 'Economic Justice', weight: 85, sentiment: 0.7, description: "Economic reform and workers' rights", relatedTweets: [2, 5, 8] },
      { name: 'Healthcare', weight: 80, sentiment: 0.9, description: "Healthcare access and reform", relatedTweets: [3, 6, 9] },
      { name: 'Housing', weight: 75, sentiment: 0.6, description: "Housing affordability and policy", relatedTweets: [7, 10, 12] },
      { name: 'Democracy', weight: 70, sentiment: 0.7, description: "Voting rights and democratic reform", relatedTweets: [11, 13, 15] },
      { name: 'Education', weight: 65, sentiment: 0.8, description: "Education access and student debt", relatedTweets: [14, 16, 17] }
    ],
    temporal: {
      patterns: [
        { period: '2024-01-01', activity: 40, sentiment: 0.7, dominantTopics: ['Climate Policy'], contextualEvents: "Climate Legislation" },
        { period: '2024-01-02', activity: 65, sentiment: 0.8, dominantTopics: ['Economic Justice'], contextualEvents: "Economic Policy" },
        { period: '2024-01-03', activity: 80, sentiment: 0.9, dominantTopics: ['Healthcare'], contextualEvents: "Healthcare Reform" }
      ]
    },
    overview: {
      metrics: {
        progressiveVsModerate: {
          score: 95,
          explanation: "Strong progressive policy positions",
          evidence: "Consistent advocacy for systemic change",
          impact: "Shapes progressive policy agenda"
        },
        digitalVsTraditional: {
          score: 90,
          explanation: "Masters modern digital communication",
          evidence: "Effective use of social media platforms",
          impact: "Sets new standards for political communication"
        },
        localVsNational: {
          score: 85,
          explanation: "Balances local and national focus",
          evidence: "Regular engagement with both constituent and national issues",
          impact: "Bridges local and national politics"
        }
      }
    }
  },
  '@GretaThunberg': {
    content: `### Overview
🎯 Greta Thunberg leads global youth climate activism, combining moral clarity with scientific advocacy and effective movement building.

### Mindset
🧠 Focused on urgent climate action. Demonstrates unwavering commitment to environmental justice and scientific truth.

### Communication
📢 Direct, uncompromising communication style. Effectively combines emotional appeal with scientific facts.

### Behaviour
🔄 Consistently challenges world leaders and institutions. Maintains focus on systemic change over incremental solutions.

### Network
🌐 Connected to global climate scientists, activists, and youth movements. Strong ties to environmental organizations.

### Influence
💫 Mobilizes global youth climate movement. Significant impact on public climate discourse.

### Predictions
🔮 Will likely expand focus on climate justice. May develop new forms of digital activism.

### Insights
💡 Uniquely combines youth perspective with global influence in climate activism.

### Needs
🎯 Seeks platforms for movement coordination. Values tools for climate education and activism.

### Pains
😤 Faces climate denial and personal attacks. Navigates challenges of maintaining momentum in long-term activism.

### Opportunities
🚀 Potential to shape new models of climate activism. Could expand influence in policy circles.

### What you can learn from them
• Master movement building strategies
• Maintain focus on core message
• Balance urgency with sustained action`,
    topics: [
      { name: 'Climate Crisis', weight: 95, sentiment: 0.7, description: "Urgent climate action and policy", relatedTweets: [0, 1, 4] },
      { name: 'Environmental Justice', weight: 85, sentiment: 0.8, description: "Climate equity and justice", relatedTweets: [2, 5, 8] },
      { name: 'Youth Movement', weight: 80, sentiment: 0.9, description: "Youth activism and engagement", relatedTweets: [3, 6, 9] },
      { name: 'Policy Change', weight: 75, sentiment: 0.6, description: "Climate policy advocacy", relatedTweets: [7, 10, 12] },
      { name: 'Science', weight: 70, sentiment: 0.7, description: "Climate science communication", relatedTweets: [11, 13, 15] },
      { name: 'Direct Action', weight: 65, sentiment: 0.8, description: "Protest and civil disobedience", relatedTweets: [14, 16, 17] }
    ],
    temporal: {
      patterns: [
        { period: '2024-01-01', activity: 40, sentiment: 0.7, dominantTopics: ['Climate Crisis'], contextualEvents: "Climate Legislation" },
        { period: '2024-01-02', activity: 65, sentiment: 0.8, dominantTopics: ['Environmental Justice'], contextualEvents: "Environmental Policy" },
        { period: '2024-01-03', activity: 80, sentiment: 0.9, dominantTopics: ['Youth Movement'], contextualEvents: "Youth Activism" }
      ]
    },
    overview: {
      metrics: {
        youthVsGlobal: {
          score: 95,
          explanation: "Strong focus on youth-led climate activism",
          evidence: "Regular engagement with global climate scientists and activists",
          impact: "Sets new standards for youth-led activism"
        },
        scienceVsPolicy: {
          score: 90,
          explanation: "Combines scientific facts with policy advocacy",
          evidence: "Regular use of scientific evidence in policy advocacy",
          impact: "Sets new standards for climate science communication"
        },
        activismVsPolicy: {
          score: 85,
          explanation: "Balances emotional appeal with policy advocacy",
          evidence: "Regular use of emotional appeals in policy advocacy",
          impact: "Sets new standards for climate activism"
        }
      }
    }
  },
  '@KingJames': {
    content: `### Overview
🎯 LeBron James transcends basketball, combining athletic excellence with social activism and business acumen.

### Mindset
🧠 Championship mentality with focus on legacy. Combines competitive drive with social consciousness.

### Communication
📢 Strategic, measured communication style. Effectively balances athletic, business, and social justice messaging.

### Behaviour
🔄 Maintains consistent high performance while expanding influence off-court. Regular engagement in social issues.

### Network
🌐 Connected to athletes, entertainers, and social activists. Strong relationships across sports, business, and entertainment.

### Influence
💫 Shapes conversations around athletics, social justice, and education. Significant impact on athlete activism.

### Predictions
🔮 Will likely expand focus on education and social justice initiatives. May develop new approaches to athlete empowerment.

### Insights
💡 Uniquely combines athletic excellence with social impact and business success.

### Needs
🎯 Seeks platforms for social impact. Values tools for community engagement and education.

### Pains
😤 Navigates intense public scrutiny. Balances athletic demands with broader social goals.

### Opportunities
🚀 Potential to reshape athlete activism model. Could expand influence in education and social justice.

### What you can learn from them
• Build multi-dimensional influence
• Maintain consistent excellence
• Balance competition with social impact`,
    topics: [
      { name: 'Basketball', weight: 90, sentiment: 0.8, description: "NBA performance and analysis", relatedTweets: [0, 1, 4] },
      { name: 'Social Justice', weight: 85, sentiment: 0.7, description: "Activism and advocacy", relatedTweets: [2, 5, 8] },
      { name: 'Education', weight: 80, sentiment: 0.9, description: "Educational initiatives", relatedTweets: [3, 6, 9] },
      { name: 'Business', weight: 75, sentiment: 0.8, description: "Business ventures and partnerships", relatedTweets: [7, 10, 12] },
      { name: 'Community', weight: 70, sentiment: 0.7, description: "Community development", relatedTweets: [11, 13, 15] },
      { name: 'Leadership', weight: 65, sentiment: 0.8, description: "Team and community leadership", relatedTweets: [14, 16, 17] }
    ],
    temporal: {
      patterns: [
        { period: '2024-01-01', activity: 40, sentiment: 0.7, dominantTopics: ['Basketball'], contextualEvents: "NBA Performance" },
        { period: '2024-01-02', activity: 65, sentiment: 0.8, dominantTopics: ['Social Justice'], contextualEvents: "Social Activism" },
        { period: '2024-01-03', activity: 80, sentiment: 0.9, dominantTopics: ['Education'], contextualEvents: "Educational Initiatives" }
      ]
    },
    overview: {
      metrics: {
        athleticVsBusiness: {
          score: 95,
          explanation: "Combines athletic excellence with business acumen",
          evidence: "Regular engagement in both sports and business",
          impact: "Sets new standards for athlete activism"
        },
        socialVsTechnical: {
          score: 90,
          explanation: "Balances social activism with technical expertise",
          evidence: "Regular use of social media platforms",
          impact: "Sets new standards for athlete activism"
        },
        localVsGlobal: {
          score: 85,
          explanation: "Balances local and global impact",
          evidence: "Regular engagement with both sports and social issues",
          impact: "Sets new standards for athlete activism"
        }
      }
    }
  }
};

function ExampleProfiles() {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const exampleProfiles = [
    {
      handle: '@naval',
      displayName: 'Naval',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1256841238298292232/ycqwaMI2_400x400.jpg',
      tweets: Array(150).fill({}),
      insights: Array(25).fill({}),
      level: 3,
      progress: 85,
      description: 'Angel Investor & Philosopher'
    },
    {
      handle: '@paulg',
      displayName: 'Paul Graham',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1824002576/pg-railsconf_400x400.jpg',
      tweets: Array(120).fill({}),
      insights: Array(18).fill({}),
      level: 2,
      progress: 95,
      description: 'YC Founder & Essayist'
    },
    {
      handle: '@balajis',
      displayName: 'Balaji',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1406974882919813128/LOUb2m4R_400x400.jpg',
      tweets: Array(200).fill({}),
      insights: Array(35).fill({}),
      level: 3,
      progress: 75,
      description: 'Tech Founder & Author'
    },
    {
      handle: '@sama',
      displayName: 'Sam Altman',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/804990434455887872/BG0Xh7Oa_400x400.jpg',
      tweets: Array(100).fill({}),
      insights: Array(15).fill({}),
      level: 2,
      progress: 65,
      description: 'OpenAI CEO'
    },
    {
      handle: '@pmarca',
      displayName: 'Marc Andreessen',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1820716712234303489/9GpKDZjq_400x400.jpg',
      tweets: Array(180).fill({}),
      insights: Array(30).fill({}),
      level: 3,
      progress: 90,
      description: 'a16z Founder'
    },
    {
      handle: '@AOC',
      displayName: 'Alexandria Ocasio-Cortez',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/923274881197895680/AbHcStkl_400x400.jpg',
      tweets: Array(150).fill({}),
      insights: Array(25).fill({}),
      level: 3,
      progress: 85,
      description: 'US Representative'
    },
    {
      handle: '@GretaThunberg',
      displayName: 'Greta Thunberg',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1459213153301053442/rL5hhpAI_400x400.jpg',
      tweets: Array(180).fill({}),
      insights: Array(30).fill({}),
      level: 3,
      progress: 90,
      description: 'Climate Activist'
    },
    {
      handle: '@KingJames',
      displayName: 'LeBron James',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1841648257719808001/hze5hlp__400x400.jpg',
      tweets: Array(200).fill({}),
      insights: Array(35).fill({}),
      level: 3,
      progress: 95,
      description: 'NBA Star & Social Activist'
    },
    {
      handle: '@kaitlancollins',
      displayName: 'Kaitlan Collins',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1240082910826377219/gE5NNsPH_400x400.jpg',
      tweets: Array(120).fill({}),
      insights: Array(20).fill({}),
      level: 2,
      progress: 75,
      description: 'CNN Anchor & Journalist'
    },
    {
      handle: '@KamalaHarris',
      displayName: 'Vice President Harris',
      profileImageUrl: 'https://pbs.twimg.com/profile_images/1592241313700782081/T2pTYU8d_400x400.jpg',
      tweets: Array(140).fill({}),
      insights: Array(22).fill({}),
      level: 2,
      progress: 80,
      description: 'Vice President'
    }
  ];

  const DialogOrSheet = isMobile ? Sheet : Dialog;
  const DialogOrSheetContent = isMobile ? SheetContent : DialogContent;

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentIndex < Math.ceil(exampleProfiles.length / 2) - 1) {
        setCurrentIndex(prev => prev + 1);
      }
    },
    onSwipedRight: () => {
      if (currentIndex > 0) {
        setCurrentIndex(prev => prev - 1);
      }
    },
    trackMouse: false,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    delta: 10,
    swipeDuration: 500,
  });

  const renderProfile = (profile) => (
    <div 
      key={profile.handle}
      className="transform transition-all duration-200 hover:z-10"
    >
      <Card 
        className={`w-48 h-32 cursor-pointer rounded-xl 
          bg-gradient-to-br from-background to-background/80 
          dark:from-[hsl(20_12%_8%)] dark:to-[hsl(20_12%_6%)]
          hover:scale-[1.02] hover:shadow-lg transition-all duration-200`}
        onClick={() => setSelectedProfile({
          ...profile,
          analysis: exampleAnalyses[profile.handle]
        })}
      >
        <CardContent className="p-0 flex flex-col h-full">
          <div className="p-2.5 flex-1">
            <div className="flex items-center mb-1.5">
              <img
                src={profile.profileImageUrl}
                alt={`${profile.displayName}'s profile`}
                className="w-5 h-5 rounded-full flex-shrink-0 object-cover mr-1.5"
              />
              <div className="flex flex-col">
                <span className="text-xs font-medium truncate max-w-[120px]">
                  {profile.displayName}
                </span>
                <span className="text-[10px] text-muted-foreground truncate">
                  {profile.handle}
                </span>
              </div>
            </div>

            <div className="mt-0.5 mb-2">
              <span className="text-[10px] text-muted-foreground">
                {profile.description}
              </span>
            </div>

            <div className="flex items-center space-x-3">
              <div className="flex items-center text-xs text-muted-foreground">
                <Database className="w-3.5 h-3.5 mr-1" />
                {formatNumber(profile.tweets.length)}
              </div>
              <div className="flex items-center text-xs text-muted-foreground">
                <Lightbulb className="w-3.5 h-3.5 mr-1" />
                {formatNumber(profile.insights.length)}
              </div>
            </div>
          </div>

          <Progress 
            value={profile.progress} 
            className="w-full h-1 
              bg-gradient-to-r from-primary/20 to-primary/30
              dark:from-[hsl(20_89%_48%)]/30 dark:to-[hsl(20_89%_48%)]/40
              rounded-none border-0
              [&>*]:rounded-none [&>*]:border-0"
          />
        </CardContent>
      </Card>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <div className="w-full max-w-[calc(100vw-2rem)]">
          <div className="relative">
            <div className="overflow-hidden" {...handlers}>
              <div 
                className="flex flex-nowrap transition-transform duration-300 ease-in-out touch-pan-y"
                style={{
                  transform: `translateX(-${currentIndex * 100}%)`,
                }}
              >
                {Array.from({ length: Math.ceil(exampleProfiles.length / 2) }).map((_, pageIndex) => (
                  <div 
                    key={pageIndex}
                    className="w-full flex-none grid grid-cols-2 gap-3 -ml-0 auto-rows-max"
                  >
                    {exampleProfiles
                      .slice(pageIndex * 2, (pageIndex * 2) + 2)
                      .map(profile => renderProfile(profile))}
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-center space-x-1 mt-4">
              {Array.from({ length: Math.ceil(exampleProfiles.length / 2) }).map((_, idx) => (
                <button
                  key={idx}
                  className={cn(
                    "w-1.5 h-1.5 rounded-full transition-colors",
                    idx === currentIndex ? "bg-primary" : "bg-primary/20"
                  )}
                  onClick={() => setCurrentIndex(idx)}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3 mx-16 -ml-0 auto-rows-max">
          {exampleProfiles.map(renderProfile)}
        </div>
      )}

      <DialogOrSheet 
        open={!!selectedProfile} 
        onOpenChange={(open) => !open && setSelectedProfile(null)}
      >
        <DialogOrSheetContent 
          side={isMobile ? "bottom" : undefined}
          className={cn(
            "overflow-y-auto",
            isMobile ? "h-[85vh] mt-auto" : "sm:max-w-[800px] max-h-[80vh]"
          )}
        >
          {selectedProfile && (
            <ProfileDetails
              profile={selectedProfile}
              profileAnalysis={selectedProfile.analysis}
              isLoading={false}
              isDarkMode={false}
              credits={0}
              handleReloadProfile={() => {}}
              handleDeleteProfile={() => {}}
              showDeleteConfirmation={false}
              setShowDeleteConfirmation={() => {}}
              currentTrackedProfiles={[]}
              onAddProfile={() => {}}
            />
          )}
        </DialogOrSheetContent>
      </DialogOrSheet>
    </>
  );
}

export default ExampleProfiles;
