import React from 'react';
import { Button } from "./ui/button";
import { LogOut, Sun, Moon, BarChart, X, Bell } from 'lucide-react';

const MobileTopBar = ({ 
  isDarkMode, 
  toggleDarkMode, 
  authState, 
  safeLogin, 
  safeLogout, 
  showSubtext, 
  toggleSubtext,
  isAdmin,
  toggleAdminDashboard,
  isPushEnabled,
  handleEnablePushNotifications
}) => {
  return (
    <div className="lg:hidden flex flex-col p-2 sm:p-4 bg-background border-b border-border">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <img src="/truffle-logo.png" alt="Truffle Logo" className="w-8 h-8 mr-2" />
          <h1 className="text-2xl font-normal" style={{ 
            fontFamily: 'DepartureMono, monospace',
            color: '#B58E73'
          }}>Truffle</h1>
        </div>
        <div className="flex items-center space-x-2">
          {authState === 'authenticated' && isAdmin && (
            <Button
              onClick={toggleAdminDashboard}
              variant="outline"
              size="sm"
              className="h-10"
            >
              <BarChart className="h-4 w-4 mr-2" />
              A
            </Button>
          )}
          {authState === 'authenticated' ? (
            <Button 
              onClick={safeLogout} 
              variant="secondaryToggle" 
              size="icon"
              className="h-12 w-12 sm:h-10 sm:w-10"
              title="Log Out"
            >
              <LogOut className="h-6 w-6 sm:h-5 sm:w-5" />
            </Button>
          ) : (
            <Button 
              onClick={safeLogin} 
              variant="secondaryToggle"
              size="sm"
              className="h-12 sm:h-10 px-4 sm:px-3"
            >
              Log In
            </Button>
          )}
          <Button 
            onClick={toggleDarkMode} 
            variant="secondaryToggle" 
            size="icon"
            className="h-12 w-12 sm:h-10 sm:w-10"
            title={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
          >
            {isDarkMode ? <Sun className="h-6 w-6 sm:h-5 sm:w-5" /> : <Moon className="h-6 w-6 sm:h-5 sm:w-5" />}
          </Button>
        </div>
      </div>

      {authState === 'authenticated' && !isPushEnabled && (
        <div className="mt-2 mb-4">
          <Button 
            onClick={handleEnablePushNotifications}
            variant="default"
            size="lg"
            className="w-full bg-darkGreen-start/10 hover:bg-darkGreen-start/20 text-darkGreen-end dark:text-darkGreen-start border border-darkGreen-start/20"
          >
            <Bell className="h-5 w-5 mr-2" />
            Enable Push Notifications
          </Button>
        </div>
      )}

      {authState !== 'authenticated' && (
        <div className="mt-2">
          {showSubtext ? (
            <div className="bg-muted/50 rounded-lg p-4 relative">
              <Button
                onClick={toggleSubtext}
                variant="ghost"
                size="sm"
                className="absolute top-2 right-2 h-6 w-6 p-0"
              >
                <X className="h-4 w-4" />
              </Button>
              <h3 className="text-lg font-semibold mb-2 text-primary">About Truffle</h3>
              <p className="text-sm text-muted-foreground">
                Truffle is a Twitter / X Social Listening app that analyses posts with AI, helping you find hidden patterns and insights. Enter up to 3 profiles at the same time, what you're looking for, and AI tracks them for you. Always double check, AI can make mistakes.
              </p>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default MobileTopBar;
