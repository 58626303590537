import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "./ui/dialog";
import { Button } from "./ui/button";

const PushDialog = ({ show, onClose, onEnablePush }) => {
  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Enable Push Notifications</DialogTitle>
          <DialogDescription>
            Get notified instantly when we find a truffle for you! 🍄‍🟫
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <p>
            Stay updated with real-time alerts for your trackers. Never miss an important update!
          </p>
        </div>
        <DialogFooter>
          <Button
            onClick={onClose}
            variant="outline"
            className="w-full sm:w-auto"
          >
            Maybe Later
          </Button>
          <Button
            onClick={onEnablePush}
            className="w-full sm:w-auto bg-dark-green-gradient from-darkGreen-start to-darkGreen-end text-white"
          >
            Enable Push Notifications
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PushDialog;
