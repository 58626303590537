import React, { useCallback, useMemo } from 'react';
import { Button } from "./ui/button";
import { Plus, X, ExternalLink } from 'lucide-react';
import { useToast } from "./ui/use-toast";
import { cn } from "../lib/utils";

function TwitterHandle({ 
  handle, 
  displayName, 
  profileImageUrl, 
  showRemove = true, 
  onRemove, 
  isOnCard = false,
  onAdd,
  currentTrackedProfiles = [], 
  maxProfiles = 3,
  isClickable = true // New prop
}) {
  const { toast } = useToast();

  // Normalize handle by removing @ if present and converting to lowercase
  const normalizedHandle = useMemo(() => {
    const normalized = handle?.replace(/^@/, '').toLowerCase();
    return normalized;
  }, [handle]);

  // Check if this profile is already being tracked
  const isAlreadyTracked = useMemo(() => {
    const isTracked = currentTrackedProfiles.some(profile => {
      const profileHandle = profile.handle?.replace(/^@/, '').toLowerCase();
      const result = profileHandle === normalizedHandle;
      return result;
    });
    return isTracked;
  }, [currentTrackedProfiles, normalizedHandle]);

  // Check if we've reached the maximum number of profiles
  const hasReachedLimit = useMemo(() => {
    const result = currentTrackedProfiles.length >= maxProfiles;
    return result;
  }, [currentTrackedProfiles, maxProfiles]);

  const handleAdd = useCallback(() => {
    if (isAlreadyTracked) {
      toast({
        title: "Already Tracking",
        description: `${displayName || handle} is already in your tracking list.`,
        variant: "destructive",
      });
      return;
    }

    if (hasReachedLimit) {
      toast({
        title: "Maximum Profiles Reached",
        description: `You can track up to ${maxProfiles} profiles at once.`,
        variant: "destructive",
      });
      return;
    }

    // Only call onAdd if we pass all validation
    onAdd?.({
      handle: handle,
      displayName,
      profileImageUrl
    });
  }, [onAdd, isAlreadyTracked, hasReachedLimit, normalizedHandle, displayName, profileImageUrl, maxProfiles, toast, handle]);

  const handleProfileClick = useCallback((e) => {
    if (!isClickable) return;
    e.stopPropagation(); // Prevent card click when clicking profile link
    const normalizedHandle = handle.replace(/^@/, '');
    window.open(`https://x.com/${normalizedHandle}`, '_blank', 'noopener,noreferrer');
  }, [handle, isClickable]);

  return (
    <div className="flex items-center px-3 py-1.5 max-w-fit">
      <div className="relative">
        {profileImageUrl && (
          <div 
            className="relative cursor-pointer group"
            onClick={!isAlreadyTracked && !hasReachedLimit ? handleAdd : undefined}
          >
            <img
              src={profileImageUrl}
              alt={`${displayName || handle}'s profile`}
              className={cn(
                "w-8 h-8 rounded-full",
                (!isAlreadyTracked && !hasReachedLimit) && "group-hover:ring-2 ring-primary/50 transition-all"
              )}
            />
            {onAdd && !isAlreadyTracked && !hasReachedLimit && (
              <div className="absolute -bottom-1 -right-1 bg-primary rounded-full w-4 h-4 flex items-center justify-center shadow-sm group-hover:scale-110 transition-transform">
                <Plus className="h-3 w-3 text-white" />
              </div>
            )}
          </div>
        )}
      </div>
      <div 
        className={`flex flex-col ml-2 ${isClickable ? 'cursor-pointer group' : ''}`}
        onClick={handleProfileClick}
      >
        <div className="flex items-center">
          <span className="text-sm font-medium">{displayName || handle}</span>
          {isClickable && (
            <ExternalLink className="h-3 w-3 ml-1 opacity-0 group-hover:opacity-100 transition-opacity" />
          )}
        </div>
        <span className="text-xs text-gray-500">{handle}</span>
      </div>
      {showRemove && (
        <Button
          onClick={onRemove}
          variant="ghost"
          size="sm"
          className="ml-2 h-5 w-5 p-0 hover:bg-transparent"
        >
          <X className="h-3 w-3" />
        </Button>
      )}
    </div>
  );
}

export default React.memo(TwitterHandle, (prevProps, nextProps) => {
  // Safely handle potentially undefined props
  const prevProfiles = prevProps.currentTrackedProfiles || [];
  const nextProfiles = nextProps.currentTrackedProfiles || [];
  
  // Basic equality checks
  if (prevProps.handle !== nextProps.handle) return false;
  if (prevProfiles.length !== nextProfiles.length) return false;
  
  // Deep comparison of profiles array
  try {
    return JSON.stringify(prevProfiles) === JSON.stringify(nextProfiles);
  } catch (e) {
    console.error('Error comparing TwitterHandle props:', e);
    return false; // Force re-render on error
  }
});
