import React, { memo, useState, useCallback } from 'react';
import ProfileCard from './ProfileCard';
import ExampleProfiles from './ExampleProfiles';
import { useProfile } from '../contexts/ProfileContext';
import { Users } from 'lucide-react';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { cn } from '../lib/utils';
import { useSwipeable } from 'react-swipeable';
import PersonasInfo from './ui/personas-info';
import { EmptyState } from './ui/empty-state';

function ProfileCarousel({ 
  authenticated, 
  onAddProfile, 
  setReminders, 
  userId, 
  credits, 
  deductCredits, 
  hasEnoughCredits, 
  isDarkMode, 
  selectedProfiles
}) {
  const { profileData } = useProfile();
  const isMobile = useMediaQuery('(max-width: 640px)');
  const [currentPage, setCurrentPage] = useState(0);

  // Simple filtering of valid profiles
  const validProfiles = Object.entries(profileData || {}).filter(([key, profile]) => {
    return key !== '_lastUpdate' && profile && !profile.deletedAt;
  });

  const itemsPerPage = 4
  const totalPages = Math.ceil(validProfiles.length / itemsPerPage)

  const canGoNext = currentPage < totalPages - 1;
  const canGoPrev = currentPage > 0;

  const handleNext = useCallback(() => {
    if (canGoNext) setCurrentPage(prev => prev + 1);
  }, [canGoNext]);

  const handlePrev = useCallback(() => {
    if (canGoPrev) setCurrentPage(prev => prev - 1);
  }, [canGoPrev]);

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    trackMouse: false,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    delta: 10,
    swipeDuration: 500,
  });

  if (!authenticated) {
    return <ExampleProfiles />;
  }

  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <Users className="h-5 w-5 text-muted-foreground mr-2" />
          <div className="flex items-center gap-2 text-lg text-muted-foreground font-normal">
            {validProfiles.length} Profile{validProfiles.length !== 1 ? 's' : ''}
            <div className="[&_button]:bg-transparent [&_button]:border-0 [&_button]:hover:bg-transparent [&_button]:p-0 flex items-center">
              <PersonasInfo />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full max-w-[calc(100vw-2rem)] lg:max-w-[calc(100vw-24rem-2rem)]">
        {validProfiles.length === 0 ? (
          <EmptyState
            type="profile"
            title="Add your first profile"
            description={
              <>
                Start by adding Twitter profiles you want to track. 
                Enter a Twitter handle in the search box above to begin monitoring their tweets.
              </>
            }
          />
        ) : isMobile ? (
          <div className="relative">
            <div className="overflow-hidden" {...handlers}>
              <div 
                className="flex flex-nowrap transition-transform duration-300 ease-in-out touch-pan-y"
                style={{ transform: `translateX(-${currentPage * 100}%)` }}
              >
                {Array.from({ length: totalPages }).map((_, pageIndex) => (
                  <div 
                    key={pageIndex}
                    className="w-full flex-none grid grid-cols-2 grid-rows-2 gap-3 -ml-0 auto-rows-max"
                  >
                    {validProfiles
                      .slice(pageIndex * itemsPerPage, (pageIndex * itemsPerPage) + itemsPerPage)
                      .map(([profileId, profile]) => (
                        <div key={profileId} className="transform transition-all duration-200 hover:z-10">
                          <ProfileCard
                            profileId={profileId}
                            onAddProfile={onAddProfile}
                            setReminders={setReminders}
                            credits={credits}
                            deductCredits={deductCredits}
                            hasEnoughCredits={hasEnoughCredits}
                            userId={userId}
                            isDarkMode={isDarkMode}
                            currentTrackedProfiles={selectedProfiles}
                            isMobile={isMobile}
                          />
                        </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>

            {totalPages > 1 && (
              <div className="flex justify-center space-x-1 mt-4">
                {Array.from({ length: totalPages }).map((_, idx) => (
                  <button
                    key={idx}
                    className={cn(
                      "w-1.5 h-1.5 rounded-full transition-colors",
                      idx === currentPage ? "bg-primary" : "bg-primary/20"
                    )}
                    onClick={() => setCurrentPage(idx)}
                  />
                ))}
              </div>
            )}
          </div>
        ) : (
          // Desktop view - show all profiles in a grid
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mx-16 -ml-0 auto-rows-max">
            {validProfiles.map(([profileId, profile]) => (
              <div key={profileId} className="transform transition-all duration-200 hover:z-10">
                <ProfileCard
                  profileId={profileId}
                  onAddProfile={onAddProfile}
                  setReminders={setReminders}
                  credits={credits}
                  deductCredits={deductCredits}
                  hasEnoughCredits={hasEnoughCredits}
                  userId={userId}
                  isDarkMode={isDarkMode}
                  currentTrackedProfiles={selectedProfiles}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(ProfileCarousel);
