import React, { useState, useEffect, useCallback, useMemo, memo, lazy, Suspense, useRef, useLayoutEffect } from 'react';
import { Card, CardContent } from "./ui/card";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "./ui/dialog";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetDescription, SheetClose } from "./ui/sheet";
import { useMediaQuery } from '../hooks/useMediaQuery';
import { formatDistanceToNow, format, isValid, addMinutes, parseISO } from 'date-fns';
import { Progress } from "./ui/progress";
import { Sparkles, Trash2, X, Plus, RefreshCw, MessageSquare, Loader2, Database, Brain, Lightbulb } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import TwitterHandle from './TwitterHandle';
import { Button } from "./ui/button";
import { useProfile } from '../contexts/ProfileContext';
import { useToast } from "./ui/use-toast";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table";
import SkeletonLoader from './ui/SkeletonLoader';
import { cn } from '../lib/utils';  // Changed from '../utils/cn'
import ProfileAnalysisSkeleton from './ProfileAnalysisSkeleton';
import { CREDIT_COSTS } from '../constants/credits';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip"

const formatNumber = (num) => new Intl.NumberFormat().format(num);

const calculateLevel = (tweets, insights) => {
  const totalItems = tweets.length + insights.length;
  if (totalItems >= 200) return 3;
  if (totalItems >= 100) return 2;
  return 1;
};

const LazyProfileDetails = lazy(() => import('./ProfileDetails'));

// Add this CSS at the top of your ProfileCard.js file or in your global CSS
const styles = `
  @keyframes profileCardEnter {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .profile-card-new {
    animation: profileCardEnter 0.6s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  }
`;

const ProfileDialog = memo(({ 
  profileId, 
  open, 
  onOpenChange, 
  children 
}) => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const DialogOrSheet = isMobile ? Sheet : Dialog;
  const DialogOrSheetContent = isMobile ? SheetContent : DialogContent;

  useEffect(() => {
    if (open) {
      console.log('Opening dialog for profile:', {
        profileId,
        hasChildren: !!children
      });
    }
  }, [open, profileId, children]);

  return (
    <DialogOrSheet 
      open={open} 
      onOpenChange={onOpenChange}
    >
      <DialogOrSheetContent 
        side={isMobile ? "bottom" : undefined} 
        className={cn(
          "overflow-y-auto",
          isMobile ? "h-[85vh] mt-auto" : "sm:max-w-[800px] max-h-[80vh]"
        )}
      >
        {children}
      </DialogOrSheetContent>
    </DialogOrSheet>
  );
});

const ProfileAnalysisContent = memo(({ 
  profileId,
  analysis,
  loadingState,
  children 
}) => {
  // Use layout effect to prevent flashing
  useLayoutEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .analysis-content {
        transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
      }
      .analysis-content[data-loading="true"] {
        opacity: 0;
        transform: translateY(10px);
        pointer-events: none;
        position: absolute;
      }
      .analysis-content[data-loading="false"] {
        opacity: 1;
        transform: translateY(0);
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  return (
    <div className="relative">
      <div 
        className="analysis-content"
        data-loading={loadingState.isGenerating || loadingState.isRegenerating}
      >
        {children}
      </div>
      <div 
        className="analysis-content"
        data-loading={!(loadingState.isGenerating || loadingState.isRegenerating)}
      >
        <ProfileAnalysisSkeleton />
      </div>
    </div>
  );
}, (prev, next) => {
  return prev.profileId === next.profileId &&
         prev.loadingState === next.loadingState &&
         prev.analysis === next.analysis;
});

const ProfileCard = memo(({ 
  profileId,
  onAddProfile,
  setReminders,
  userId,
  credits,
  deductCredits,
  hasEnoughCredits,
  isDarkMode,
  currentTrackedProfiles,
  tweetCount,
  forceUpdate
}) => {
  const { 
    profileData, 
    profileAnalyses,
    analysisLoadingStates,
    generateAnalysis,
    regenerateAnalysis,
    removeProfile,
    openDialog,
    closeDialog,
    isDialogOpen
  } = useProfile();
  
  const { toast } = useToast();
  const isMobile = useMediaQuery('(max-width: 640px)');
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  // Memoize profile data to prevent unnecessary rerenders
  const profile = useMemo(() => profileData[profileId], [profileData, profileId]);
  const analysis = useMemo(() => profileAnalyses[profileId], [profileAnalyses, profileId]);
  const loadingState = useMemo(() => analysisLoadingStates[profileId] || {}, [analysisLoadingStates, profileId]);

  // Calculate stats
  const stats = useMemo(() => ({
    tweetCount: tweetCount || profile?.tweets?.length || 0,
    insightCount: profile?.insights?.length || 0,
    lastActive: profile?.tweets?.[0]?.createdAt || null
  }), [profile?.tweets?.length, profile?.insights?.length, tweetCount, profile?.tweets]);

  // Calculate level
  const level = useMemo(() => calculateLevel(
    profile?.tweets || [], 
    profile?.insights || []
  ), [profile?.tweets, profile?.insights]);

  // Calculate progress
  const progress = useMemo(() => {
    if (!level) return 0;

    const totalItems = stats.tweetCount + stats.insightCount;
    const levelThresholds = [0, 100, 200];
    const currentLevelThreshold = levelThresholds[level - 1] || 0;
    const nextLevelThreshold = levelThresholds[level] || levelThresholds[levelThresholds.length - 1];
    
    const progressInCurrentLevel = totalItems - currentLevelThreshold;
    const levelRange = nextLevelThreshold - currentLevelThreshold;
    
    return Math.min((progressInCurrentLevel / levelRange) * 100, 100);
  }, [stats.tweetCount, stats.insightCount, level]);

  const dialogOpen = isDialogOpen(profileId);

  const handleQuickStartPersona = async () => {
    const operation = async () => {
      try {
        const data = await generateAnalysis(profileId);
        return data;
      } catch (error) {
        console.error('Error in quick start:', error);
        toast({
          title: "Error",
          description: "Failed to generate persona. Please try again.",
          variant: "destructive"
        });
        throw error;
      }
    };

    try {
      await deductCredits(CREDIT_COSTS.GENERATE_PERSONA, { name: 'GENERATE_PERSONA' });
    } catch (error) {
      openDialog(profileId);
    }
  };

  const handleReloadProfile = async () => {
    const operation = async () => {
      try {
        const data = await regenerateAnalysis(profileId);
        return data;
      } catch (error) {
        console.error('Error in reload:', error);
        toast({
          title: "Error",
          description: "Failed to regenerate persona. Please try again.",
          variant: "destructive"
        });
        throw error;
      }
    };

    try {
      await deductCredits(CREDIT_COSTS.REGENERATE_PERSONA, { name: 'REGENERATE_PERSONA' });
    } catch (error) {
      openDialog(profileId);
    }
  };

  const handleDialogClose = useCallback(() => {
    closeDialog(profileId);
  }, [closeDialog, profileId]);

  const handleCardClick = useCallback(() => {
    if (!profile) {
      toast({
        title: "Example Persona",
        description: "Sign in to view and manage real Personas!",
        duration: 3000,
      });
      return;
    }
    openDialog(profileId);
  }, [profile, openDialog, profileId]);

  const handleDeleteProfile = async () => {
    setIsDeleting(true);
    handleDialogClose();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/profile/${profileId}/${userId}`, 
        { 
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.details || `HTTP error! status: ${response.status}`);
      }
      
      // Remove the profile from the context
      removeProfile(profileId);

      // Remove all reminders associated with this profile
      setReminders(prevReminders => prevReminders.filter(reminder => 
        !reminder.profiles.some(p => p.id === profileId)
      ));

      toast({
        title: "Persona Deleted",
        description: "The Persona and all associated reminders have been deleted.",
      });
    } catch (error) {
      console.error('Error deleting profile:', error);
      toast({
        title: "Error",
        description: "Failed to delete Persona. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsDeleting(false);
      setShowDeleteConfirmation(false); // Reset confirmation state
    }
  };

  const handleDeleteHistoricalTracker = async (reminderId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reminders/${reminderId}/permanent`, {
        method: 'DELETE',
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      
      // Remove the deleted reminder from the local state
      setReminders(prevReminders => prevReminders.filter(r => r.id !== reminderId));
      
      toast({
        title: "Historical Tracker Deleted",
        description: "The tracker has been permanently removed from the profile's data source.",
      });
    } catch (error) {
      console.error('Error deleting historical tracker:', error);
      toast({
        title: "Error",
        description: "Failed to delete historical tracker. Please try again.",
        variant: "destructive",
      });
    }
  };

  const renderPromptTable = () => {
    if (!profile?.reminders || profile.reminders.length === 0) return null;

    return (
      <>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Prompt</TableHead>
              <TableHead>Truffles</TableHead>
              <TableHead>Insights</TableHead>
              <TableHead>Duration</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {profile.reminders.map((reminder, index) => {
              const createdAt = reminder.createdAt ? parseISO(reminder.createdAt) : null;
              const deletedAt = reminder.deletedAt ? parseISO(reminder.deletedAt) : null;
              
              return (
                <TableRow key={index}>
                  <TableCell>{reminder.reformulatedPrompt || reminder.description}</TableCell>
                  <TableCell>{reminder.triggerEvents?.length || 0}</TableCell>
                  <TableCell>{reminder.insights?.length || 0}</TableCell>
                  <TableCell>
                    {createdAt && isValid(createdAt) 
                      ? `${format(createdAt, 'MMM d, yyyy')} - ${deletedAt && isValid(deletedAt) 
                          ? format(deletedAt, 'MMM d, yyyy')
                          : 'Present'}`
                      : 'Invalid date'}
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleDeleteHistoricalTracker(reminder.id)}
                      size="sm"
                      variant="delete"
                      disabled={isDeleting}
                      className="text-xs whitespace-nowrap"
                    >
                      <Trash2 className="h-4 w-4 mr-1" />
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </>
    );
  };

  const cardContent = useMemo(() => {
    // Check if profile has a generated analysis
    const hasGeneratedPersona = profile?.analysis || profileAnalyses[profileId];

    return (
      <div className="relative">
        <TooltipProvider delayDuration={100}>
          <Card 
            className={`w-48 h-24 transition-all duration-200 select-none cursor-pointer
              hover:scale-[1.02] hover:shadow-lg active:scale-[0.98] rounded-xl bg-gradient-to-br 
              from-background to-background dark:from-[hsl(20_12%_8%)] dark:to-[hsl(20_12%_6%)]
              overflow-hidden ${profile?.isNew ? 'profile-card-new' : ''}`}
            onClick={handleCardClick}
          >
            <div className="flex flex-col h-full">
              {/* Content Section - Reduced padding */}
              <div className="p-2.5 flex-1">
                {/* Profile Info - Tightened spacing */}
                <div className="flex items-center mb-1.5">
                  {profile?.profileImageUrl && (
                    <img
                      src={profile.profileImageUrl}
                      alt={`${profile.displayName || profile.handle}'s profile`}
                      className="w-5 h-5 rounded-full flex-shrink-0 object-cover mr-1.5"
                    />
                  )}
                  <div className="flex flex-col">
                    <span className="text-xs font-medium truncate max-w-[120px]">
                      {profile?.displayName || profile?.handle}
                    </span>
                    <span className="text-[10px] text-muted-foreground truncate">
                      {profile?.handle}
                    </span>
                  </div>
                </div>

                {hasGeneratedPersona ? (
                  // Stats for profiles with generated persona
                  <div className="flex items-center space-x-3 mt-3">
                    <div className="flex items-center text-xs text-muted-foreground select-none">
                      <Database className="w-3.5 h-3.5 mr-1" />
                      {formatNumber(stats.tweetCount)}
                    </div>
                    <div className="flex items-center text-xs text-muted-foreground select-none">
                      <Lightbulb className="w-3.5 h-3.5 mr-1" />
                      {formatNumber(stats.insightCount)}
                    </div>
                  </div>
                ) : (
                  // "Generate Persona" prompt for profiles without analysis
                  <div className="flex items-left justify-lef mt-3">
                    <span className="text-xs text-muted-foreground flex items-left">
                      <Sparkles className="w-3.5 h-3.5 mr-1" />
                      Generate
                    </span>
                  </div>
                )}
              </div>

              {/* Progress bar only for profiles with generated persona */}
              {hasGeneratedPersona && (
                <Progress 
                  value={progress} 
                  className="w-full h-1 
                    bg-gradient-to-r from-primary/20 to-primary/30
                    dark:from-[hsl(20_89%_48%)]/30 dark:to-[hsl(20_89%_48%)]/40
                    rounded-none border-0
                    [&>*]:rounded-none [&>*]:border-0"
                />
              )}
            </div>
          </Card>
        </TooltipProvider>
      </div>
    );
  }, [
    profile?.isNew,
    profile?.profileImageUrl,
    profile?.displayName,
    profile?.handle,
    handleCardClick,
    level,
    progress,
    stats.tweetCount,
    stats.insightCount,
    profileId,
    profileAnalyses
  ]);

  if (!profile) return null;

  return (
    <>
      {cardContent}

      {!profile.isExample && (
        <ProfileDialog
          profileId={profileId}
          open={dialogOpen}
          onOpenChange={handleDialogClose}
        >
          {/* Delete Confirmation Modal */}
          {showDeleteConfirmation && (
            <div className="absolute inset-0 bg-background/80 backdrop-blur-sm z-50 flex items-center justify-center">
              <div className="bg-card p-6 rounded-lg shadow-lg max-w-md mx-4">
                <h3 className="text-lg font-semibold mb-2">Delete Persona?</h3>
                <p className="text-muted-foreground mb-4">
                  This will permanently delete this persona and all associated data. This action cannot be undone.
                </p>
                <div className="flex justify-end space-x-2">
                  <Button
                    variant="outline"
                    onClick={() => setShowDeleteConfirmation(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="delete"
                    onClick={handleDeleteProfile}
                    size="sm"
                    disabled={isDeleting}
                    className="text-xs whitespace-nowrap"
                  >
                    {isDeleting ? (
                      <>
                        <Loader2 className="h-4 w-4 mr-1 animate-spin" />
                        Deleting...
                      </>
                    ) : (
                      <>
                        <Trash2 className="h-4 w-4 mr-1" />
                        Confirm Delete
                      </>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          )}

          <Suspense fallback={<SkeletonLoader isDarkMode={isDarkMode} />}>
            <ProfileAnalysisContent
              profileId={profileId}
              analysis={analysis}
              loadingState={loadingState}
            >
              <LazyProfileDetails
                profile={profile}
                profileId={profileId}
                handleDeleteProfile={() => setShowDeleteConfirmation(true)} // Changed to show confirmation
                showDeleteConfirmation={showDeleteConfirmation}
                setShowDeleteConfirmation={setShowDeleteConfirmation}
                renderPromptTable={renderPromptTable}
                credits={credits}
                deductCredits={deductCredits}
                hasEnoughCredits={hasEnoughCredits}
                isDarkMode={isDarkMode}
                onAddProfile={onAddProfile}
                currentTrackedProfiles={currentTrackedProfiles}
              />
            </ProfileAnalysisContent>
          </Suspense>
        </ProfileDialog>
      )}
    </>
  );
}, (prevProps, nextProps) => {
  return prevProps.profileId === nextProps.profileId &&
         prevProps.forceUpdate === nextProps.forceUpdate &&
         prevProps.tweetCount === nextProps.tweetCount &&
         JSON.stringify(prevProps.currentTrackedProfiles) === JSON.stringify(nextProps.currentTrackedProfiles);
});

// Update the wrapper to prevent unnecessary rerenders
const ProfileCardWrapper = (props) => {
  const { profileData, profileAnalyses } = useProfile();
  const profile = profileData[props.profileId];
  const analysis = profileAnalyses[props.profileId];
  
  // Only include necessary data in the key
  const key = useMemo(() => {
    const tweetCount = profile?.tweets?.length || 0;
    const insightCount = profile?.insights?.length || 0;
    const hasAnalysis = !!analysis;
    
    return `${props.profileId}-${tweetCount}-${insightCount}-${hasAnalysis}`;
  }, [props.profileId, profile?.tweets?.length, profile?.insights?.length, analysis]);
  
  return <ProfileCard {...props} key={key} />;
};

export default ProfileCardWrapper;
