import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button } from './ui/button';
import { useToast } from "./ui/use-toast";
import { Loader2 } from 'lucide-react';

const StripeCheckoutForm = ({ amount, onSuccess, userId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { toast } = useToast();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error('Stripe not initialized');
      return;
    }

    setIsProcessing(true);
    setErrorMessage('');

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-success`,
          payment_method_data: {
            billing_details: {
              // Add any additional billing details here
            }
          }
        },
        redirect: 'if_required'
      });

      if (error) {
        console.error('Payment failed:', error);
        setErrorMessage(error.message || 'An error occurred');
        toast({
          title: "Payment Failed",
          description: error.message || 'An error occurred',
          variant: "destructive"
        });
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        onSuccess();
        toast({
          title: "Payment Successful",
          description: "Your credits will be added shortly",
        });
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      setErrorMessage('An unexpected error occurred');
      toast({
        title: "Error",
        description: "An unexpected error occurred. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsProcessing(false);
    }
  };

  if (!stripe || !elements) {
    return <div>Loading payment system...</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <PaymentElement 
        options={{
          layout: 'tabs',
          paymentMethodOrder: ['card'],
          defaultValues: {
            billingDetails: {
              // Add any default billing details here
            }
          },
          cookies: {
            secure: true,
            sameSite: 'None',
            partitioned: true
          }
        }}
      />
      
      {errorMessage && (
        <div className="text-sm text-red-500">
          {errorMessage}
        </div>
      )}

      <Button
        type="submit"
        disabled={!stripe || isProcessing}
        className="w-full"
      >
        {isProcessing ? (
          <>
            <Loader2 className="h-4 w-4 mr-2 animate-spin" />
            Processing...
          </>
        ) : (
          `Pay $${amount}`
        )}
      </Button>

      <p className="text-xs text-muted-foreground text-center">
        Secure payment powered by Stripe
      </p>
    </form>
  );
};

export default StripeCheckoutForm;