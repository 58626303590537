import React from 'react';
import { Button } from "./ui/button";
import { Plus } from 'lucide-react';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "./ui/sheet";
import { SidebarContent } from './Sidebar';

const FloatingButton = ({
  isDrawerOpen,
  setIsDrawerOpen,
  ...props
}) => {
  return (
    <>
      <Button
        data-add-reminder-button
        onClick={() => setIsDrawerOpen(true)}
        className="lg:hidden fixed bottom-6 right-4 h-14 w-14 rounded-full shadow-lg bg-darkGreen-start hover:bg-darkGreen-end text-white flex items-center justify-center"
      >
        <Plus className="h-6 w-6" />
      </Button>

      <Sheet open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
        <SheetContent 
          side="bottom" 
          className="h-[90vh] px-5 py-2 bg-white dark:bg-muted/50"
        >
          <SheetHeader className="mb-4">
            <SheetTitle>Create Tracker</SheetTitle>
          </SheetHeader>
          <div className="flex-1 overflow-y-auto px-1">
            <SidebarContent {...props} />
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default FloatingButton;
