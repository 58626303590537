import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Trash2, Plus, Minus } from 'lucide-react';
import { useToast } from "./ui/use-toast";
import LYXManagement from './LYXManagement';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const ITEMS_PER_PAGE = 5;

const AdminDashboard = ({ user }) => {
  const { toast } = useToast();
  const [analytics, setAnalytics] = useState(null);
  const [users, setUsers] = useState([]);  // Initialize as an empty array
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [remindersPage, setRemindersPage] = useState(1);
  const [usersPage, setUsersPage] = useState(1);

  useEffect(() => {
    if (!user) {
      console.log('No user provided to AdminDashboard');
      return;
    }

    const userId = user.id;
    console.log('AdminDashboard: Attempting admin check with:', {
      userId,
      fullUserId: user.id,
      apiUrl: process.env.REACT_APP_API_URL
    });
    
    fetch(`${process.env.REACT_APP_API_URL}/api/admin/check-admin?userId=${encodeURIComponent(userId)}`)
      .then(async response => {
        const text = await response.text();
        console.log('Raw response:', text);
        
        try {
          const data = JSON.parse(text);
          console.log('Admin check response:', {
            status: response.status,
            data
          });
          
          if (data.isAdmin) {
            console.log('User confirmed as admin, fetching data...');
            fetchAnalytics();
            fetchUsers();
          } else {
            console.log('User is not an admin');
            setError('You do not have admin access');
          }
        } catch (e) {
          console.error('Error parsing admin check response:', e);
          setError('Error parsing admin response');
        }
      })
      .catch(error => {
        console.error('Admin check network error:', error);
        setError('Network error during admin check');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user]);

  const fetchAnalytics = async () => {
    setIsLoading(true);
    setError(null);
    try {
      console.log('Fetching analytics with userId:', user.id);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/analytics?userId=${encodeURIComponent(user.id)}`);
      
      console.log('Analytics response status:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Analytics error response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Analytics data received:', data);
      
      if (data) {
        data.userGrowth = data.userGrowth ? accumulateData(data.userGrowth, 'users') : [];
        data.trackerGrowth = data.trackerGrowth ? accumulateData(data.trackerGrowth, 'trackers') : [];
        data.trufflesFound = data.trufflesFound ? accumulateData(data.trufflesFound, 'truffles') : [];
      }
      
      setAnalytics(data);
    } catch (error) {
      console.error('Error in fetchAnalytics:', error);
      console.error('Error details:', {
        message: error.message,
        stack: error.stack
      });
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users?userId=${encodeURIComponent(user.id)}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setUsers(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]);
    }
  };

  const adjustCredits = async (targetUserId, amount) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/admin/adjust-credits`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          adminId: user.id,
          targetUserId,
          amount 
        }),
      });
      fetchUsers();
    } catch (error) {
      console.error('Error adjusting credits:', error);
    }
  };

  const deleteUser = async (targetUserId) => {
    if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/delete-user`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            adminId: user.id,
            targetUserId 
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to delete user');
        }

        await fetchUsers(); // Refresh the users list
        toast({
          title: "Success",
          description: "User deleted successfully",
        });
      } catch (error) {
        console.error('Error deleting user:', error);
        toast({
          title: "Error",
          description: error.message || "Failed to delete user",
          variant: "destructive",
        });
      }
    }
  };

  const paginateData = (data, page) => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return data?.slice(startIndex, endIndex) || [];
  };

  const getTotalPages = (data) => Math.ceil((data?.length || 0) / ITEMS_PER_PAGE);

  const PaginationControls = ({ currentPage, totalPages, onPageChange }) => (
    <div className="flex items-center justify-between px-4 py-2 border-t">
      <div className="text-sm text-muted-foreground">
        Page {currentPage} of {totalPages}
      </div>
      <div className="flex gap-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => onPageChange(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
          disabled={currentPage === totalPages}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );

  const RetentionCard = ({ retention }) => (
    <Card className="col-span-2">
      <CardHeader>
        <CardTitle>User Retention</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h3 className="text-sm font-medium">7-Day Retention</h3>
            <p className="text-2xl font-bold">{retention?.sevenDayRetention}%</p>
            <p className="text-sm text-gray-500">
              of {retention?.totalUsersSevenDay} users
            </p>
          </div>
          <div>
            <h3 className="text-sm font-medium">30-Day Retention</h3>
            <p className="text-2xl font-bold">{retention?.thirtyDayRetention}%</p>
            <p className="text-sm text-gray-500">
              of {retention?.totalUsersThirtyDay} users
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  )

  const RemindersMetricsCard = ({ metrics }) => (
    <Card className="col-span-2">
      <CardHeader>
        <CardTitle>Reminders Metrics</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h3 className="text-sm font-medium">Average Reminders/User</h3>
            <p className="text-2xl font-bold">{metrics?.averageRemindersPerUser}</p>
          </div>
          <div>
            <h3 className="text-sm font-medium">Active Users with Reminders</h3>
            <p className="text-2xl font-bold">{metrics?.activeUsers}</p>
          </div>
        </div>
      </CardContent>
    </Card>
  )

  const currentReminders = paginateData(analytics?.recentReminders, remindersPage);
  const remindersTotalPages = getTotalPages(analytics?.recentReminders);

  const filteredUsers = users.filter(user => 
    user.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  const currentUsers = paginateData(filteredUsers, usersPage);
  const usersTotalPages = getTotalPages(filteredUsers);

  // Helper function to accumulate data over time
  const accumulateData = (data, key) => {
    let accumulator = 0;
    return data.map(item => {
      accumulator += item[key];
      return { ...item, [key]: accumulator };
    });
  };

  return (
    <div className="space-y-2 p-2">
      {isLoading && (
        <div className="text-center py-8">
          <p>Loading admin dashboard...</p>
        </div>
      )}
      
      {error && (
        <div className="text-center py-8 text-red-500">
          <p>Error: {error}</p>
        </div>
      )}
      
      {!isLoading && !error && !analytics && (
        <div className="text-center py-8">
          <p>No data available. You may not have admin access.</p>
        </div>
      )}
      
      {analytics && (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 mb-2">
            <StatCard title="Total Users" value={analytics.totalUsers} />
            <StatCard title="Daily Active Users" value={analytics.dailyActiveUsers} />
            <StatCard title="Total Trackers" value={analytics.totalTrackers} />
            <StatCard title="Total Credits Purchased" value={analytics.totalCreditsPurchased} />
          </div>
          
          <div className="mb-4">
            <LYXManagement user={user} />
          </div>
          
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
            <RetentionCard 
              retention={{
                sevenDayRetention: analytics.sevenDayRetention,
                thirtyDayRetention: analytics.thirtyDayRetention,
                totalUsersSevenDay: analytics.totalUsersSevenDay,
                totalUsersThirtyDay: analytics.totalUsersThirtyDay
              }} 
            />
            <RemindersMetricsCard 
              metrics={{
                averageRemindersPerUser: analytics.averageRemindersPerUser,
                activeUsers: analytics.activeUsers
              }} 
            />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <ChartCard title="User Growth Over Time" data={analytics?.userGrowth} dataKey="users" />
            <ChartCard title="Tracker Growth Over Time" data={analytics?.trackerGrowth} dataKey="trackers" />
            <ChartCard title="Cumulative Truffles Found" data={analytics?.trufflesFound} dataKey="truffles" />
          </div>

          {/* Updated Recent Reminders Card */}
          <Card>
            <CardHeader>
              <CardTitle>Recent Reminders</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="overflow-x-auto border rounded-lg">
                <div className="h-[300px] overflow-auto">
                  <Table className="text-sm">
                    <TableHeader className="bg-background sticky top-0">
                      <TableRow className="hover:bg-transparent">
                        <TableHead className="h-8 px-2">Created</TableHead>
                        <TableHead className="h-8 px-2">User</TableHead>
                        <TableHead className="h-8 px-2">Profiles</TableHead>
                        <TableHead className="h-8 px-2">Original Prompt</TableHead>
                        <TableHead className="h-8 px-2">Reformulated Prompt</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {currentReminders.map(reminder => (
                        <TableRow key={reminder.id} className="hover:bg-transparent">
                          <TableCell className="p-1">{new Date(reminder.createdAt).toLocaleDateString()}</TableCell>
                          <TableCell className="p-1">{reminder.user}</TableCell>
                          <TableCell className="p-1">{reminder.profiles}</TableCell>
                          <TableCell className="p-1">
                            <div className="whitespace-pre-wrap">
                              {reminder.description}
                            </div>
                          </TableCell>
                          <TableCell className="p-1">
                            <div className="whitespace-pre-wrap">
                              {reminder.reformulatedPrompt}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <PaginationControls
                  currentPage={remindersPage}
                  totalPages={remindersTotalPages}
                  onPageChange={setRemindersPage}
                />
              </div>
            </CardContent>
          </Card>

          {/* User Management Card */}
          <Card>
            <CardHeader>
              <CardTitle>User Management</CardTitle>
            </CardHeader>
            <CardContent>
              <Input
                placeholder="Search users..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="mb-4"
              />
              <div className="overflow-x-auto">
                <div className="h-[300px] overflow-auto">
                  <Table className="text-sm">
                    <TableHeader>
                      <TableRow className="hover:bg-transparent">
                        <TableHead className="h-8 px-2">ID/Email</TableHead>
                        <TableHead className="h-8 px-2">Credits</TableHead>
                        <TableHead className="h-8 px-2">Trackers</TableHead>
                        <TableHead className="h-8 px-2">Truffles</TableHead>
                        <TableHead className="h-8 px-2">Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {currentUsers.map(user => (
                        <TableRow key={user.id} className="hover:bg-transparent">
                          <TableCell className="p-1">{user.email || user.id}
                            {user.walletAddresses && (
                              <div className="text-xs text-gray-500">
                                Wallet: {user.walletAddresses[0]}
                              </div>
                            )}
                          </TableCell>
                          <TableCell className="p-1">{user.credits}</TableCell>
                          <TableCell className="p-1">{user.totalTrackers}</TableCell>
                          <TableCell className="p-1">{user.totalTruffles}</TableCell>
                          <TableCell className="p-1">
                            <div className="flex space-x-1">
                              <Button onClick={() => adjustCredits(user.id, 100)} size="sm">
                                <Plus className="h-4 w-4" />
                              </Button>
                              <Button onClick={() => adjustCredits(user.id, -100)} variant="destructive" size="sm">
                                <Minus className="h-4 w-4" />
                              </Button>
                              <Button onClick={() => deleteUser(user.id)} variant="ghost" size="sm">
                                <Trash2 className="h-4 w-4" />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <PaginationControls
                  currentPage={usersPage}
                  totalPages={usersTotalPages}
                  onPageChange={setUsersPage}
                />
              </div>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
};

const StatCard = ({ title, value }) => (
  <Card>
    <CardHeader className="p-2">
      <CardTitle className="text-sm">{title}</CardTitle>
    </CardHeader>
    <CardContent className="p-2">
      <p className="text-xl font-medium">{value}</p>
    </CardContent>
  </Card>
);

const ChartCard = ({ title, data, dataKey }) => (
  <Card className="mb-2">
    <CardHeader className="p-2">
      <CardTitle className="text-sm">{title}</CardTitle>
    </CardHeader>
    <CardContent className="p-2">
      <div style={{ width: '100%', height: '200px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data || []} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="date" 
              tick={{ fontSize: 12 }}
              tickFormatter={(tick) => new Date(tick).toLocaleDateString()}
            />
            <YAxis tick={{ fontSize: 12 }} />
            <Tooltip 
              labelFormatter={(label) => new Date(label).toLocaleDateString()}
              formatter={(value) => [value, dataKey]}
            />
            <Line 
              type="monotone" 
              dataKey={dataKey} 
              stroke="#8884d8" 
              strokeWidth={2}
              dot={false}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </CardContent>
  </Card>
);

export default AdminDashboard;
